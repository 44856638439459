<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="row">
      <!-- <div class="col-12">
        <h5>Modify PO Number</h5>
      </div> -->
      <input
        name="orderId"
        id="orderId"
        [ngModel]="order.orderId"
        type="text"
        hidden
      />
      <div class="col-12 form-group">
        <label for="poNumber">PO #</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="Enter PO #"
          id="poNumber"
          name="poNumber"
          [ngModel]="order.poNumber"
          #poNumber="ngModel"
        />
        <div class="form-alert" *ngIf="poNumber.touched && !poNumber.valid">
          <div *ngIf="poNumber.errors.required">PO # Required.</div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitChanges(form.value)"
        [disabled]="!form.valid"
      >
        Update
      </button>
    </div>
  </form>
</div>
