import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-print-order-modal",
  templateUrl: "./print-order-modal.component.html",
  styleUrls: ["./print-order-modal.component.css"],
})
export class PrintOrderModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal
  ) {}

  @Input() orderId: any;
  @Output() print = new EventEmitter<any>();

  public dto: any;
  public printOptions = ["Order Confirmation", "Invoice", "Packing Slip"];

  public getModalHeader() {
    return "Print Options";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public printOrder(data) {
    this.activeModal.close();
    this.print.emit(data.printOption);
  }
}
