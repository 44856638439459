import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

let env = environment.production ? "production" : "development";
import * as config from "../../../config.json";
import { environment } from "src/environments/environment";
import { NotFoundError } from "../errors/not-found-error.js";
import { BadInput } from "../errors/bad-input.js";
import { AppError } from "../errors/app-error.js";

@Injectable()
export class EmailService {
  private _headers;
  private _confiramtionURL;
  private _invoiceURL;

  constructor(private _http: HttpClient) {
    this._confiramtionURL =
      config[env].apiUrl + "orders/send-confirmation-email";
    this._invoiceURL = config[env].apiUrl + "orders/send-invoice-email";
    this._headers = new HttpHeaders({ "Content-Type": "application/json" });
  }

  public sendOrderConfirmation(dto) {
    return this._http
      .post(this._confiramtionURL, JSON.stringify(dto), {
        headers: this._headers
      })
      .pipe(catchError(this.handleError));
  }

  public sendInvoice(dto) {
    return this._http
      .post(this._invoiceURL, JSON.stringify(dto), { headers: this._headers })
      .pipe(catchError(this.handleError));
  }

  public handleError(error: Response) {
    if (error.status === 404) return throwError(new NotFoundError(error));

    if (error.status === 400) return throwError(new BadInput(error));

    return throwError(new AppError(error));
  }
}
