import { Component, OnInit } from "@angular/core";
import { IAddress } from "../shared/interfaces/address.interface";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddressService } from "../shared/services/address.service";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { AddressModalComponent } from "../modals/address-modal/address-modal.component";

@Component({
  selector: "app-addresses",
  templateUrl: "./addresses.component.html",
  styleUrls: ["./addresses.component.css"],
})
export class AddressesComponent implements OnInit {
  constructor(
    private _addressService: AddressService,
    private _modalService: NgbModal
  ) {}

  public addresses: Array<IAddress>;
  public matchingAddresses: Array<IAddress>;
  public addressesShowing: Array<IAddress>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 15;
  public maxSize: number = 15;
  public totalItems: number;

  //Filter Vars
  public filters = {
    type: "",
    company: "",
    companyName: "", //this is the ship-to name
    street: "",
    poBox: "",
    city: "",
    state: "",
    zip: "",
    shippingRate: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._addressService.getAll().subscribe(
      (addresses: Array<IAddress>) => {
        this.addresses = addresses;
        this.sortAddresses();
        this.matchingAddresses = this.addresses;
        this.addressesShowing = this.addresses.slice(0, this.itemsPerPage);
        this.totalItems = addresses.length;
      },
      (error) => {
        console.log(error);
        console.log("There was an error getting addresses.");
      }
    );
  }

  public clearToastAlert() {
    this.alert = null;
  }

  private sortAddresses() {
    this.addresses.sort((a, b) => {
      if (a.state < b.state) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  public pageChanged(event: any): void {
    this.addressesShowing = this.matchingAddresses.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startUpdate(address) {
    const modalRef = this._modalService.open(AddressModalComponent);
    modalRef.componentInstance.address = address;
    modalRef.componentInstance.deleteAddress.subscribe((event) => {
      this.onDeleteAddress(event);
    });
    modalRef.componentInstance.updateAddress.subscribe((event) => {
      this.onUpdateAddress(event);
    });
  }

  public onDeleteAddress(e) {
    if (e.type === "success") {
      let index = this.addresses.findIndex(
        (address) => address.id == e.address.id
      );
      this.addresses.splice(index, 1);
      this.sortAddresses();
      this.addressesShowing = this.addresses.slice(0, this.itemsPerPage);
      this.totalItems = this.addresses.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateAddress(e) {
    if (e.type === "error") {
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingAddresses = this.addresses.filter(
      (address) =>
        address.type.toUpperCase().includes(this.filters.type.toUpperCase()) &&
        (!this.filters.companyName
          ? true
          : address.companyName
          ? address.companyName
              .toUpperCase()
              .includes(this.filters.companyName.toUpperCase())
          : false) &&
        (address.Company
          ? address.Company.name
              .toUpperCase()
              .includes(this.filters.company.toUpperCase())
          : true) &&
        (!this.filters.street
          ? true
          : (address.street1 + " " + address.street2)
              .toUpperCase()
              .includes(this.filters.street.toUpperCase())) &&
        (address.poBox
          ? address.poBox
              .toUpperCase()
              .includes(this.filters.poBox.toUpperCase())
          : this.filters.poBox
          ? false
          : true) &&
        address.city.toUpperCase().includes(this.filters.city.toUpperCase()) &&
        address.state.includes(this.filters.state.toUpperCase()) &&
        address.zip.toString().includes(this.filters.zip)
    );

    this.addressesShowing = this.matchingAddresses.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingAddresses.length;
  }

  public clearFilters() {
    (this.filters.type = ""),
      (this.filters.company = ""),
      (this.filters.companyName = "");
    (this.filters.street = ""),
      (this.filters.poBox = ""),
      (this.filters.city = ""),
      (this.filters.state = ""),
      (this.filters.zip = "");
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingAddresses.length > 0) {
      let headers =
        "Id,OwningCompanyId,Type,OwningCompany,ShiptoName,Street1,Street2,POBox,City,State,Zip,ShippingRate,ContactName,ContactEmail,ContactPhone,CreatedAt,UpdatedAt";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingAddresses.length; i++) {
        csv += '"' + this.matchingAddresses[i].id + '",';
        csv += '"' + this.matchingAddresses[i].companyId + '",';
        csv += '"' + this.matchingAddresses[i].type + '",';
        csv +=
          '"' +
          (this.matchingAddresses[i].Company
            ? this.matchingAddresses[i].Company.name
            : "") +
          '",';
        csv +=
          '"' +
          (this.matchingAddresses[i].companyName
            ? this.matchingAddresses[i].companyName
            : "") +
          '",';
        csv += '"' + this.matchingAddresses[i].street1 + '",';
        csv += '"' + (this.matchingAddresses[i].street2 || "") + '",';
        csv += '"' + (this.matchingAddresses[i].poBox || "") + '",';
        csv += '"' + this.matchingAddresses[i].city + '",';
        csv += '"' + this.matchingAddresses[i].state + '",';
        csv += '"' + this.matchingAddresses[i].zip + '",';
        csv += '"' + (this.matchingAddresses[i].shippingRate || "") + '",';
        csv += '"' + (this.matchingAddresses[i].contactName || "") + '",';
        csv += '"' + (this.matchingAddresses[i].contactEmail || "") + '",';
        csv += '"' + (this.matchingAddresses[i].contactPhone || "") + '",';
        csv += '"' + (this.matchingAddresses[i].createdAt || "") + '",';
        csv += '"' + (this.matchingAddresses[i].updatedAt || "") + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "addresses.csv";

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
