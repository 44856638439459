<div *ngIf="cart.productTotal > 0">
  <ul class="order-requirements-list">
    <li>
      <span *ngIf="cart.productTotal < this.minOrderAmt">
        <fa-icon class="x-icon" [icon]="xIcon"></fa-icon>
        <strong>{{
          this.minOrderAmt - cart.productTotal
            | currency : "USD" : "symbol" : "1.2-2"
        }}</strong>
        more to meet order value minimum
      </span>
      <span *ngIf="cart.productTotal >= this.minOrderAmt">
        <fa-icon class="check-icon" [icon]="checkIcon"></fa-icon>
        <span>Order Value Minimum Met</span>
      </span>
    </li>
    <li *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt > 0">
      <span
        *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt >= this.minNumBoxes"
      >
        <fa-icon class="check-icon" [icon]="checkIcon"></fa-icon>
        <span>Box Minimum Met</span>
      </span>

      <span
        *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt < this.minNumBoxes"
      >
        <fa-icon class="x-icon" [icon]="xIcon"></fa-icon>
        <strong>{{
          this.minNumBoxes - cart.totalNumOfBoxesFromRegularAndPrebuilt
        }}</strong>
        more boxes needed to meet minimum
      </span>
    </li>
    <li *ngIf="cart.rackQtyTotal > 0">
      <span *ngIf="cart.rackQtyTotal % 1 <= 0">
        <fa-icon class="check-icon" [icon]="checkIcon"></fa-icon>
        <span *ngIf="cart.rackQtyTotal == 1">Rack is Full</span>
        <span *ngIf="cart.rackQtyTotal > 1"
          >{{ cart.rackQtyTotal }} Racks are Full</span
        >
      </span>
      <span *ngIf="cart.rackQtyTotal % 1 > 0">
        <fa-icon class="x-icon" [icon]="xIcon"></fa-icon>
        <strong>{{
          this.halfShelvesPerRack -
            ((cart.rackQtyTotal * this.halfShelvesPerRack) %
              this.halfShelvesPerRack)
        }}</strong>
        more half shelves needed to fill rack
      </span>
    </li>
  </ul>
</div>
