import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http'
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductService extends DataService {

  constructor(http: HttpClient) {
    super('products', http)
  }

  public getSellable() {
    return super.getHttp().get(super.getApiUrl() + '/sellable')
      .pipe(catchError(super.handleError));
  }

}
