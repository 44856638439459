import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";

export class PasswordValidators{
    public static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if((control.value as string).indexOf(' ')>=0)
            return { cannotContainSpace: true }
        return null
    }

    public static containsANumber(control: AbstractControl): ValidationErrors | null {
        return (control.value as string).match(/[0-9]/) ? null : {containsANumber: true}
    }

    public static containsAnUppercaseLetter(control: AbstractControl): ValidationErrors | null {
        return (control.value as string).match(/[A-Z]/) ? null : {containsAnUppercaseLetter: true}
    }

    public static containsASpecialCharacter(control: AbstractControl): ValidationErrors | null {
        return (control.value as string).match(/[`~!@#%&_={};':"<>,\-\$\^\*\(\)\+\[\]\\\|\.\?\/]/) ? null : {containsASpecialCharacter: true}
    }





    public static passwordsMatch(g: FormGroup): ValidationErrors | null {
        return (g.get('password').value === g.get('passwordConfirm').value) ? null : {passwordsMatch: true, message: 'Password and password confirm do not match'};
    }
}