import { CategoryService } from "../shared/services/category.service";
import { ProductService } from "../shared/services/product.service";
import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../shared/services/config.service";
import { CartService } from "../shared/services/cart.service";
import { Observable } from "rxjs";
import { ICategory } from "../shared/interfaces/category.interface";
import { IRack } from "../shared/interfaces/rack.interface";
import { IProduct } from "../shared/interfaces/product.interface";
import { RackService } from "../shared/services/rack.service";
import { HalfShelfService } from "./../shared/services/halfshelf.service";
import { IHalfShelf } from "../shared/interfaces/halfshelf.interface";
import { IBoxSkid } from "../shared/interfaces/boxskid.interface";
import { BoxSkidService } from "../shared/services/boxskid.service";

@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.css"],
})
export class StoreComponent implements OnInit {
  constructor(
    private _productService: ProductService,
    private _rackService: RackService,
    private _halfShelfService: HalfShelfService,
    private _preubiltBoxService: BoxSkidService,
    private _categoryService: CategoryService,
    private _cartService: CartService,
    private _configService: ConfigService
  ) {}

  public products: Array<IProduct>;
  public racks: Array<IRack>;
  public halfShelves: Array<IHalfShelf>;
  public boxSkids: Array<IBoxSkid>;
  public categoryOptions: Array<ICategory>;
  public fileServerUrl;
  public orders: Array<any>;
  public cart: any;
  public alert;

  public boxesOrdered = 0;
  public halfShelvesOrdered = 0;
  public boxSkidsOrdered = 0;

  public orderSummaryChangeCounter: number = 0;

  //Searching
  public matchingProducts: Array<IProduct>;
  public matchingRacks: Array<IRack>;
  public matchingHalfShelves: Array<IHalfShelf>;
  public matchingBoxSkids: Array<IBoxSkid>;
  public searchTerm = "";

  ngOnInit() {
    this.fileServerUrl = this._configService.getStaticFileServerUrl();

    this._categoryService.getAll().subscribe(
      (res: Array<ICategory>) => {
        this.categoryOptions = res;
        this.categoryOptions.push({
          id: "prebuild-rack-id",
          name: "Prebuilt Racks",
          sortOrder: -1,
        });
        this.categoryOptions.push({
          id: "prebuild-halfshelf-id",
          name: "Prebuilt Half Shelves",
          sortOrder: 0,
        });
        this.categoryOptions.push({
          id: "prebuild-boxskid-id",
          name: "Prebuilt Skid of Boxes",
          sortOrder: 0,
        });
      },
      (error) => {
        console.log("Error Getting Categories");
      }
    );

    Observable.combineLatest(
      this._productService.getSellable(),
      this._rackService.getSellable(),
      this._halfShelfService.getSellable(),
      this._preubiltBoxService.getSellable(),
      this._cartService.getUsersCartProducts()
    ).subscribe(
      (res: any) => {
        this.products = res[0];
        this.matchingProducts = this.products;

        this.racks = res[1];
        this.matchingRacks = this.racks;

        this.halfShelves = res[2];
        this.matchingHalfShelves = this.halfShelves;

        this.boxSkids = res[3];
        this.matchingBoxSkids = this.boxSkids;

        if (res[4]) {
          this.cart = res[4];
          this.syncCart();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private syncCart() {
    if (this.cart.Products) {
      this.cart.Products.forEach((cartProduct) => {
        let productToUpdate = this.products.find(
          (product) => product.id == cartProduct.id
        );
        productToUpdate.boxesOrdered = cartProduct.CartProduct.boxQty;
        productToUpdate.halfShelvesOrdered =
          cartProduct.CartProduct.halfShelfQty;
        productToUpdate.comments = cartProduct.CartProduct.comments;
      });
    } else {
      this.cart.Products = [];
    }

    if (this.cart.Racks) {
      this.cart.Racks.forEach((rack) => {
        let rackToUpdate = this.racks.find((r) => r.id === rack.id);
        rackToUpdate.racksOrdered = rack.CartRack.rackQty;
      });
    } else {
      this.cart.Racks = [];
    }

    if (this.cart.HalfShelves) {
      this.cart.HalfShelves.forEach((halfShelf) => {
        let halfShelfToUpdate = this.halfShelves.find(
          (hs) => hs.id === halfShelf.id
        );
        halfShelfToUpdate.halfShelvesOrdered =
          halfShelf.CartHalfShelf.halfShelfQty;
      });
    } else {
      this.cart.HalfShelves = [];
    }

    if (this.cart.BoxSkids) {
      this.cart.BoxSkids.forEach((boxSkid) => {
        let boxSkidToUpdate = this.boxSkids.find(
          (box) => box.id === boxSkid.id
        );
        boxSkidToUpdate.boxSkidsOrdered = boxSkid.CartBoxSkid.boxSkidQty;
      });
    } else {
      this.cart.BoxSkids = [];
    }
  }

  public onAddItemToCart(e) {
    if (e.type === "success") {
      this.cart.Products.push(e.product);
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateItemInCart(e) {
    if (e.type === "success") {
      // Get index of product we just updated from cart
      let index = this.cart.Products.findIndex(
        (product) => product.id == e.product.id
      );
      this.cart.Products[index] = e.product;
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onRemoveItemFromCart(e) {
    if (e.type === "success") {
      // Get index of product we just removed from cart
      let cartProductIndex = this.cart.Products.findIndex(
        (product) => product.id == e.productId
      );

      if (cartProductIndex >= 0) {
        //Remove from Cart
        this.cart.Products.splice(cartProductIndex, 1);
      }

      //Set Boxes Ordered and HalfShelves Ordered from Products array to 0
      let productToRemove = this.products.find(
        (product) => product.id == e.productId
      );
      productToRemove.boxesOrdered = 0;
      productToRemove.halfShelvesOrdered = 0;

      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onAddRackToCart(e) {
    if (e.type === "success") {
      this.cart.Racks.push(e.rack);
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateRackInCart(e) {
    if (e.type === "success") {
      // Get index of product we just updated from cart
      let index = this.cart.Racks.findIndex((rack) => rack.id == e.rack.id);
      this.cart.Racks[index] = e.rack;
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onRemoveRackFromCart(e) {
    if (e.type === "success") {
      // Get index of product we just removed from cart
      let cartRackIndex = this.cart.Racks.findIndex(
        (rack) => rack.id == e.rackId
      );

      if (cartRackIndex >= 0) {
        //Remove from Cart
        this.cart.Racks.splice(cartRackIndex, 1);
      }

      //Set Racks Ordered and from Racks array to 0
      let rackToRemove = this.racks.find((r) => r.id === e.rackId);
      rackToRemove.racksOrdered = 0;

      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onAddHalfShelfToCart(e) {
    if (e.type === "success") {
      this.cart.HalfShelves.push(e.halfShelf);
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateHalfShelfInCart(e) {
    if (e.type === "success") {
      // Get index of product we just updated from cart
      let index = this.cart.HalfShelves.findIndex(
        (halfShelf) => halfShelf.id === e.halfShelf.id
      );
      this.cart.HalfShelves[index] = e.halfShelf;
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onRemoveHalfShelfFromCart(e) {
    if (e.type === "success") {
      // Get index of product we just removed from cart
      let cartHalfShelfIndex = this.cart.HalfShelves.findIndex(
        (halfShelf) => halfShelf.id === e.halfShelfId
      );

      if (cartHalfShelfIndex >= 0) {
        //Remove from Cart
        this.cart.HalfShelves.splice(cartHalfShelfIndex, 1);
      }

      let halfShelfToRemove = this.halfShelves.find(
        (hs) => hs.id === e.halfShelfId
      );
      halfShelfToRemove.halfShelvesOrdered = 0;
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onAddBoxSkidToCart(e) {
    if (e.type === "success") {
      this.cart.BoxSkids.push(e.boxSkid);
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateBoxSkidInCart(e) {
    if (e.type === "success") {
      // Get index of product we just updated from cart
      let index = this.cart.BoxSkids.findIndex(
        (boxSkid) => boxSkid.id === e.boxSkid.id
      );
      this.cart.BoxSkids[index] = e.boxSkid;
      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  public onRemoveBoxSkidFromCart(e) {
    if (e.type === "success") {
      // Get index of product we just removed from cart
      let cartBoxSkidIndex = this.cart.BoxSkids.findIndex(
        (boxSkid) => boxSkid.id === e.boxSkidId
      );

      if (cartBoxSkidIndex >= 0) {
        //Remove from Cart
        this.cart.BoxSkids.splice(cartBoxSkidIndex, 1);
      }

      let boxSkidToRemove = this.boxSkids.find((box) => box.id === e.boxSkidId);
      boxSkidToRemove.boxSkidsOrdered = 0;

      this.syncCart();
      this.orderSummaryChangeCounter++;
    } else {
      this.alert = e.alert;
    }
  }

  //Searching Functionality
  public onSearchTermChange(value) {
    this.searchTerm = value.toLowerCase();
    this.updateMatchingProducts();
    this.updateMatchingRacks();
    this.updateMatchingHalfShelves();
    this.updateMatchingBoxSkids();
  }

  public onCategoryChange(category) {
    category.selected = !category.selected;
    this.updateMatchingProducts();
    this.updateMatchingRacks();
    this.updateMatchingHalfShelves();
    this.updateMatchingBoxSkids();
  }

  public clearToastAlert() {
    this.alert = null;
  }

  private updateMatchingProducts() {
    const slectedCategories = this.categoryOptions
      .filter((category) => category.selected)
      .map((category) => category.id)
      .join("|");
    this.matchingProducts = this.products.filter(
      (product) =>
        (!this.searchTerm
          ? true
          : (product.name
              ? product.name.toLowerCase().includes(this.searchTerm)
              : false) ||
            (product.description
              ? product.description.toLowerCase().includes(this.searchTerm)
              : false) ||
            (product.itemNo
              ? product.itemNo.toLowerCase().includes(this.searchTerm)
              : false) ||
            (product.Category
              ? product.Category.name.toLowerCase().includes(this.searchTerm)
              : false)) &&
        (slectedCategories.length > 0
          ? slectedCategories.includes(product.Category.id)
          : true)
    );
  }

  private updateMatchingRacks() {
    const slectedCategories = this.categoryOptions
      .filter((category) => category.selected)
      .map((category) => category.id)
      .join("|");

    this.matchingRacks = this.racks.filter(
      (rack) =>
        (!this.searchTerm
          ? true
          : (rack.name
              ? rack.name.toLowerCase().includes(this.searchTerm)
              : false) ||
            (rack.itemNo
              ? rack.itemNo.toLowerCase().includes(this.searchTerm)
              : false) ||
            (rack.description
              ? rack.description.toLowerCase().includes(this.searchTerm)
              : false)) &&
        (slectedCategories.length > 0
          ? slectedCategories.includes("prebuild-rack-id")
          : true)
    );
  }

  private updateMatchingHalfShelves() {
    const slectedCategories = this.categoryOptions
      .filter((category) => category.selected)
      .map((category) => category.id)
      .join("|");

    this.matchingHalfShelves = this.halfShelves.filter(
      (halfShelf) =>
        (!this.searchTerm
          ? true
          : (halfShelf.name
              ? halfShelf.name.toLowerCase().includes(this.searchTerm)
              : false) ||
            (halfShelf.itemNo
              ? halfShelf.itemNo.toLowerCase().includes(this.searchTerm)
              : false) ||
            (halfShelf.description
              ? halfShelf.description.toLowerCase().includes(this.searchTerm)
              : false)) &&
        (slectedCategories.length > 0
          ? slectedCategories.includes("prebuild-halfshelf-id")
          : true)
    );
  }

  private updateMatchingBoxSkids() {
    const slectedCategories = this.categoryOptions
      .filter((category) => category.selected)
      .map((category) => category.id)
      .join("|");

    this.matchingBoxSkids = this.boxSkids.filter(
      (boxSkid) =>
        (!this.searchTerm
          ? true
          : (boxSkid.name
              ? boxSkid.name.toLowerCase().includes(this.searchTerm)
              : false) ||
            (boxSkid.itemNo
              ? boxSkid.itemNo.toLowerCase().includes(this.searchTerm)
              : false) ||
            (boxSkid.description
              ? boxSkid.description.toLowerCase().includes(this.searchTerm)
              : false)) &&
        (slectedCategories.length > 0
          ? slectedCategories.includes("prebuild-boxskid-id")
          : true)
    );
  }
}
