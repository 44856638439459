import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-pick-rpt-date-range-modal",
  templateUrl: "./pick-rpt-date-range-modal.component.html",
  styleUrls: ["./pick-rpt-date-range-modal.component.css"],
})
export class PickRptDateRangeModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public calendar: NgbCalendar
  ) {}

  @Input() reportName: string;
  @Output() submitted = new EventEmitter<any>();

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", 6);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public getModalHeader() {
    return "Select Ship Date Range";
  }

  submit() {
    const fromDate =
      this.fromDate.year +
      "-" +
      (this.fromDate.month < 10
        ? "0" + this.fromDate.month
        : this.fromDate.month) +
      "-" +
      (this.fromDate.day < 10 ? "0" + this.fromDate.day : this.fromDate.day);

    //We need the toDate to be one day ahead so that when we look for data less than it,
    //we will get the data for the day that the user wanted.
    this.toDate = this.toDate || this.fromDate;

    const toDateForReportTitle =
      this.toDate.year +
      "-" +
      (this.toDate.month < 10 ? "0" + this.toDate.month : this.toDate.month) +
      "-" +
      (this.toDate.day < 10 ? "0" + this.toDate.day : this.toDate.day);

    let toDateAsDate = new Date(
      this.toDate.year,
      this.toDate.month - 1,
      this.toDate.day
    );
    toDateAsDate.setDate(toDateAsDate.getDate() + 1);

    let toMonth =
      toDateAsDate.getMonth() < 9
        ? "0" + (toDateAsDate.getMonth() + 1)
        : toDateAsDate.getMonth() + 1;
    let toDay =
      toDateAsDate.getDate() < 10
        ? "0" + toDateAsDate.getDate()
        : toDateAsDate.getDate();

    const toDate = toDateAsDate.getFullYear() + "-" + toMonth + "-" + toDay;

    const res = {
      reportName: this.reportName,
      fromDate: fromDate,
      toDate: toDate,
      toDateForReportTitle: toDateForReportTitle,
    };
    this.submitted.emit(res);
    this.closeModal();
  }

  public closeModal() {
    this.activeModal.close();
  }
}
