import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-request-account-modal',
  templateUrl: './request-account-modal.component.html',
  styleUrls: ['./request-account-modal.component.css']
})
export class RequestAccountModalComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    public activeModal: NgbActiveModal, 
    private _modalService: NgbModal
  ) { }

  public attempted = false;
  public emailSent = false;
  
  ngOnInit() {
  }

  public closeModal(){
    this.activeModal.close();
  }

  submit(f: any){
    this._authService.sendAccountRequestEmail(f.value).subscribe(
      res => {
        console.log("SUCCESSFUL SUBMIT");
        this.attempted = true;
        this.emailSent = true;
        console.log(res);
      }, error => {
        console.log("ERROR")
        this.attempted = true;
        this.emailSent = false;
        console.log(error);
    });
  }
}
