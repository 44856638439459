<div class="row">
  <div class="col-sm-3"></div>
  <div id="login-form-container" class="col-sm-6">
    <div class="col-sm-10">
      <form [formGroup]="form" (ngSubmit)="changePassword(form.value)">
        <h1>{{ incommingPath == 'activate' ? 'Create Password' : 'Reset Password'}}</h1>
        <h4>{{title}}</h4>
        <div class="alert alert-info">
          <strong>Passwords must meet the following criteria:</strong>
          <ul>
            <li>A minimum of 7 characters</li>
            <li>At least one capital letter</li>
            <li>At least one number</li>
            <li>At least one special character</li>
          </ul>
        </div>
        <div class="alert alert-danger" *ngIf="errorMessage">
          <p>{{errorMessage}}</p>
        </div>
        <div class="form-group" action="">
          <label for="password">New Password</label>
          <input
            type="password"
            formControlName="password"
            id="password"  
            class="form-control" 
            placeholder="Enter New Password">
          <div class="alert alert-danger" *ngIf="password.touched && !password.valid">
            <div *ngIf="password.errors.required">
              Password is required.
            </div>
            <div *ngIf="password.errors.minlength">
              Password needs to have at least {{password.errors.minlength.requiredLength}} chars. 
              You've entered {{password.errors.minlength.actualLength}}.
              Please add {{password.errors.minlength.requiredLength - password.errors.minlength.actualLength }} more characters.
            </div>
            <div *ngIf="password.errors.containsANumber">
              Password needs to contain at least one number.
            </div>
            <div *ngIf="password.errors.containsAnUppercaseLetter">
              Password needs to contain at least one uppercase letter.
            </div>
            <div *ngIf="password.errors.containsASpecialCharacter">
              Password needs to contain at least one special character.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="passwordConfirm">Confirm Password</label>
          <input
            type="password"
            formControlName="passwordConfirm"
            id="passwordConfirm" 
            class="form-control" 
            placeholder="Confirm Password">
        </div>
        <div class="alert alert-danger" *ngIf="passwordConfirm.touched && form?.errors?.passwordsMatch">
          Password and password confirm fields must match.
        </div>
        <input
          formControlName="passcode"
          id="passcode" 
          type="hidden">
        <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit</button>
      </form>
    </div>
  </div>
  <div class="col-sm-3"></div>
</div>