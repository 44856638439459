import { Component } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  constructor(private _authService: AuthService) { }

  public isNavbarCollapsed=true;

  public loggedIn(){
    return this._authService.loggedIn();
  }

  public isAdmin(){
    return this._authService.isAdmin();
  }

  public isCustomer(){
    return this._authService.isCustomer();
  }

  public getLoggedInUserEmail(){
    return this._authService.getLoggedInUser().email;
  }

}
