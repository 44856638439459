<div class="login-container">
  <div class="row">
    <div class="col-sm-2"></div>
    <div id="login-form-container" class="col-sm-8">
      <div class="col-sm-12">
        <form
          #f="ngForm"
          (ngSubmit)="onLogin(f.value)"
          *ngIf="!authService.loggedIn()"
        >
          <h1>Pope's Plant Farm</h1>
          <h4>{{ title }}</h4>
          <div class="alert alert-danger" *ngIf="errorMessage">
            <p>{{ errorMessage }}</p>
          </div>
          <div
            class="alert alert-success"
            *ngIf="welcomeMessage && !errorMessage"
          >
            <p>{{ welcomeMessage }}</p>
          </div>
          <h4>Login</h4>
          <div class="form-group" action="">
            <label for="email">Email</label>
            <input
              autocomplete="username"
              [(ngModel)]="email"
              id="email"
              name="email"
              type="email"
              class="form-control"
              placeholder="Enter Email"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              autocomplete="current-password"
              [(ngModel)]="password"
              id="password"
              name="password"
              type="password"
              class="form-control"
              placeholder="Password"
            />
          </div>
          <button class="btn btn-primary" type="submit">Submit</button>
        </form>
        <div id="linkContainer" class="small">
          <a routerLink="/reset-request">Forgot your password?</a>
          <a id="requestLink" (click)="openRequestAccountModal()"
            >Don't have an account. Request One Now</a
          >
        </div>
      </div>
    </div>
    <div class="col-sm-2">v:23.2</div>
  </div>
</div>
