import { UserService } from "../shared/services/user.service";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserModalComponent } from "../modals/user-modal/user-modal.component";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { IUser } from "../shared/interfaces/user.interface";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"]
})
export class UsersComponent implements OnInit {
  constructor(
    private _userService: UserService,
    private _modalService: NgbModal
  ) {}

  public users: Array<IUser>;
  public matchingUsers: Array<IUser>;
  public usersShowing: Array<IUser>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 15;
  public maxSize: number = 15;
  public totalItems: number;

  //Filter Vars
  public filters = {
    email: "",
    role: "",
    company: ""
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._userService.getAll().subscribe(
      (users: Array<IUser>) => {
        this.users = users;
        this.sortUsers();
        this.matchingUsers = this.users;
        this.usersShowing = this.users.slice(0, this.itemsPerPage);
        this.totalItems = users.length;
      },
      error => {
        console.log("There was an error getting users.");
      }
    );
  }

  public clearToastAlert() {
    this.alert = null;
  }

  private sortUsers() {
    this.users.sort((a, b) => {
      if (a.Roles && b.Roles) {
        if (a.Roles[0].name > b.Roles[0].name) return 1;
        if (a.Roles[0].name < b.Roles[0].name) return -1;
      } else {
        return 0;
      }

      if (a.Company && b.Company) {
        if (a.Company.name < b.Company.name) return -1;
        else return 1;
      } else {
        return 0;
      }
    });
  }

  public pageChanged(event: any): void {
    this.usersShowing = this.matchingUsers.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(UserModalComponent);
    modalRef.componentInstance.createUser.subscribe(event => {
      this.onCreateUser(event);
    });
  }

  public startUpdate(user) {
    const modalRef = this._modalService.open(UserModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.deleteUser.subscribe(event => {
      this.onDeleteUser(event);
    });
    modalRef.componentInstance.updateUser.subscribe(event => {
      this.onUpdateUser(event);
    });
  }

  public onCreateUser(e) {
    if (e.type === "success") {
      this.clearFilters();
      this.users.push(e.user);
      this.sortUsers();
      this.alert = e.alert;
      this.usersShowing = this.users.slice(0, this.itemsPerPage);
      this.totalItems = this.users.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteUser(e) {
    if (e.type === "success") {
      this.clearFilters();
      let index = this.users.findIndex(user => user.id == e.user.id);
      this.users.splice(index, 1);
      this.sortUsers();
      this.usersShowing = this.users.slice(0, this.itemsPerPage);
      this.totalItems = this.users.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateUser(e) {
    if (e.type === "error") {
      this.alert = e.alert;
    } else {
      let updatedUserIndex = this.users.findIndex(user => user.id == e.user.id);
      this.users[updatedUserIndex] = e.user;
      const pageWeWereOn = this.currentPage;
      this.changeFilter();
      this.pageChanged(pageWeWereOn);
      this.alert = e.alert;
    }
  }

  public getRoles(user: IUser): string {
    let roles = "";

    for (let i = 0; i < user.Roles.length; i++) {
      if (i == user.Roles.length - 1) {
        roles += user.Roles[i].name;
      } else {
        roles += user.Roles[i].name + " | ";
      }
    }

    return roles;
  }

  public changeFilter() {
    this.matchingUsers = this.users.filter(
      user =>
        user.email.toUpperCase().includes(this.filters.email.toUpperCase()) &&
        this.getRoles(user)
          .toUpperCase()
          .includes(this.filters.role.toUpperCase()) &&
        (this.filters.company
          ? user.Company
            ? user.Company.name
                .toUpperCase()
                .includes(this.filters.company.toUpperCase())
            : false
          : true)
    );

    this.usersShowing = this.matchingUsers.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingUsers.length;
  }

  public clearFilters() {
    this.filters.email = "";
    this.filters.role = "";
    this.filters.company = "";
    this.matchingUsers = this.users;
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingUsers.length > 0) {
      let headers = "Email,SystemAdmin,Customer,Company";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingUsers.length; i++) {
        csv += '"' + this.matchingUsers[i].email + '",';
        csv +=
          '"' +
          (this.matchingUsers[i].Roles.filter(role => role.name == "Admin")
            .length > 0
            ? "Yes"
            : "No") +
          '",';
        csv +=
          '"' +
          (this.matchingUsers[i].Roles.filter(role => role.name == "Customer")
            .length > 0
            ? "Yes"
            : "No") +
          '",';
        csv +=
          '"' +
          (this.matchingUsers[i].Company
            ? this.matchingUsers[i].Company.name
            : "") +
          '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "users.csv";

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
