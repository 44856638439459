import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IRack } from "../shared/interfaces/rack.interface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CartService } from "../shared/services/cart.service";
import { NotFoundError } from "../shared/errors/not-found-error";

@Component({
  selector: "app-rack-card",
  templateUrl: "./rack-card.component.html",
  styleUrls: ["./rack-card.component.css"],
})
export class RackCardComponent implements OnInit {
  constructor(private _cartService: CartService) {}

  @Input() rack: IRack;
  @Input() racksOrdered: number;
  @Input() comments: string;
  @Input() fileServerUrl: string;
  @Output() addRackToCart = new EventEmitter<any>();
  @Output() updateRackInCart = new EventEmitter<any>();
  @Output() removeRackFromCart = new EventEmitter<any>();

  public inlineOrderForm;
  public inCart: boolean;
  public tmpRacksOrdered;
  public tmpHalfShelvesOrdered;

  ngOnInit() {
    this.tmpRacksOrdered = this.racksOrdered;

    this.inlineOrderForm = new FormGroup({
      rackId: new FormControl(this.rack.id, [Validators.required]),
      racksOrdered: new FormControl(this.racksOrdered, [Validators.min(0)]),
      comments: new FormControl(this.comments),
    });

    if (this.racksOrdered > 0) {
      this.inCart = true;
    } else {
      this.inCart = false;
    }
  }

  public addRack(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.addRackToCart(data).subscribe(
      (res: any) => {
        this.racksOrdered = res.CartRack.rackQty;
        this.comments = res.CartRack.comments;

        let addRackResponse = {
          type: "success",
          rack: {
            CartRack: res.CartRack,
            id: this.rack.id,
            eachCost: this.rack.eachCost,
          },
        };
        this.inCart = true;
        this.addRackToCart.emit(addRackResponse);
      },
      (error) => {
        this.racksOrdered = 0;
        this.comments = "";

        let addRackResponse = {
          type: "error",
          rack: this.rack,
          alert: {
            type: "danger",
            title: "Rack not added to cart.",
            msg: error.originalError.errors[0].description,
          },
        };
        this.inCart = false;
        this.addRackToCart.emit(addRackResponse);
      }
    );
  }

  public updateRack(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.updateRackInCart(data).subscribe(
      (res: any) => {
        this.racksOrdered = res.CartRack.rackQty;
        this.comments = res.CartRack.comments;

        let updateRackResponse = {
          type: "success",
          rack: {
            CartRack: res.CartRack,
            id: this.rack.id,
            eachCost: this.rack.eachCost,
          },
        };
        this.inCart = true;
        this.updateRackInCart.emit(updateRackResponse);
      },
      (error) => {
        let updateRackResponse = {
          type: "error",
          rack: this.rack,
          alert: {
            type: "danger",
            title: "Cart could not be updated.",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.updateRackInCart.emit(updateRackResponse);
      }
    );
  }

  public removeRack(rackId, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.removeRackFromCart(rackId).subscribe(
      (res: any) => {
        this.racksOrdered = 0;
        let removeRackResponse = {
          type: "success",
          rackId: this.rack.id,
          alert: {
            type: "success",
            title: "Rack Removed From Cart.",
            msg: "",
          },
        };
        this.inCart = false;
        this.inlineOrderForm.controls["racksOrdered"].setValue(0);
        this.removeRackFromCart.emit(removeRackResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let removeRackResponse = {
            type: "success",
            rackId: this.rack.id,
            alert: {
              type: "success",
              title: "Rack Removed From Cart.",
              msg: "",
            },
          };
          this.removeRackFromCart.emit(removeRackResponse);
        } else {
          let removeItemResponse = {
            type: "error",
            rackId: this.rack.id,
            alert: {
              type: "danger",
              title: "Rack Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.inCart = true;
          this.removeRackFromCart.emit(removeItemResponse);
        }
      }
    );
  }

  public getRackTotal() {
    return this.rack.eachCost * this.tmpRacksOrdered;
  }

  public getRackIndividualUnits() {
    return this.tmpRacksOrdered * this.rack.itemQty;
  }

  public updateRacksOrderedCalcs() {
    let enteredValue = this.inlineOrderForm.get("racksOrdered").value;

    if (enteredValue >= 0) {
      this.tmpRacksOrdered = enteredValue;
    }
  }

  public resetValues() {
    this.inlineOrderForm.controls["racksOrdered"].setValue(this.racksOrdered);
    this.inlineOrderForm.controls["comments"].setValue(this.comments);
    this.tmpRacksOrdered = this.racksOrdered;
  }
}
