import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/shared/services/order.service';
import { AddressService } from 'src/app/shared/services/address.service';

@Component({
  selector: 'app-update-shipping-rate-modal',
  templateUrl: './update-shipping-rate-modal.component.html',
  styleUrls: ['./update-shipping-rate-modal.component.css']
})
export class UpdateShippingRateModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal, 
    private _modalService: NgbModal,
    private _orderService: OrderService,
    private _addressService: AddressService
  ) {}

  @Input() dto: any;
  @Output() updateShippingRate = new EventEmitter<any>();

  public modalAction: ModalActions;

  ngOnInit(){

    // We only allow in this modal
    this.modalAction = ModalActions.Edit;

  }

  public getModalHeader(){
    return 'Set Shipping Rate';
  }

  public closeModal(){
    this.activeModal.close();
  }

  public update(f){

    let dto = {
      orderId: this.dto.orderId,
      addressId: this.dto.addressId,
      shippingRate: f.shippingRate
    }

    this._orderService.updateShippingRate(dto).subscribe(
      (res: any) =>{
        let shippingRateUpdateResponse = {
          type: 'success',
          address: res.address,
          alert: {
            type: 'success',
            title: "Shipping Rate Updated",
            msg: res.message
          }
        }
        this.activeModal.close();
        this.updateShippingRate.emit(shippingRateUpdateResponse);
      },
      error=>{
        let shippingRateUpdateResponse = {
          type: 'error',
          address: this.dto,
          alert: {
            type: "danger",
            title: "Shipping Rate Not Updated",
            msg: error.originalError.error.errors[0].description
          }
        }
        this.activeModal.close();
        this.updateShippingRate.emit(shippingRateUpdateResponse);
      });
  }

}

enum ModalActions {
  Edit="Edit"
}
