<div class="row">
  <div class="col-sm-2"></div>
  <div id="login-form-container" class="col-sm-8">
    <div class="col-sm-10">
      <form #f="ngForm" (ngSubmit)="requestResetEmail(f.value)">
        <h1>Request Password Reset Link</h1>
        <h4>{{title}}</h4>
        <div class="alert alert-info">
          <p>Enter your email below and we'll send you a link to reset your password.</p>
        </div>
        <div class="alert alert-success" *ngIf="message">
          <p>{{message}}</p>
        </div>
        <div class="form-group">
          <label for="username">Email</label>
            <input 
            required
            ngModel
            #username="ngModel"
            pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            id="username" 
            name="username" 
            type="email" 
            class="form-control" 
            placeholder="Enter email">
        </div>
        <div class="alert alert-danger" *ngIf="username.touched && !username.valid">
          <div *ngIf="username.errors.pattern">
            Please enter a valid email address.
          </div>
        </div>
        <button class="btn btn-primary" type="submit" [disabled]="!f.valid">Submit</button>
      </form>
      <div id="navActionContainer" class="small pull-right">
        <a routerLink="/login" >Know your password. Login</a>
      </div>
    </div>
  </div>
  <div class="col-sm-2"></div>
</div>