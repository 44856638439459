import { Injectable } from "@angular/core";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { SHELVES_PER_RACK } from "../data/constants";
import { ILineItem } from "../interfaces/line-item.interface";
import { NumberUtils } from "../../utils/number-utils";

@Injectable()
export class PrintPDFService {
  constructor() {}

  public print(type: string, order) {
    let halfShelvesPerRack = SHELVES_PER_RACK * 2;

    //create doc
    let doc = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [8.5, 11],
    });

    //Add Logo
    doc.addImage("../../assets/img/logo.png", "PNG", 0.5, 0.3, 1.25, 0.68125);

    //Add Address
    doc.setFontType("bold");
    doc.setFontSize("9");
    doc.text("P.O. Box 187", 6, 0.5);
    doc.text("Greenback, TN 37742", 6, 0.7);
    doc.text("(865) 977-6942", 6, 0.9);
    doc.text("succulents@popesplantfarm.com", 6, 1.1);

    //Add Title
    doc.setFontType("normal");
    doc.setFontSize("16");
    doc.text(type + " " + order.id.substring(0, 8), 0.5, 1.5, {
      maxWidth: 7.9,
    });

    //Header
    let headerStartY = 1.75;
    let lineYIncrement = 0.2;
    let line = 0;
    let column1X = 0.5;
    let column2X = 4;
    let column3X = 6.15;
    let maxY = 0;

    //Add Header: Order Info
    doc.setFontType("bold");
    doc.setFontSize("10");
    doc.text("Date Placed: ", column1X, headerStartY + lineYIncrement * line);
    line++;
    if (order.shipDate) {
      doc.text(
        "Requested Ship Date: ",
        column1X,
        headerStartY + lineYIncrement * line
      );
    }
    line++;
    if (order.poNumber) {
      doc.text("PO #: ", column1X, headerStartY + lineYIncrement * line);
      line++;
    }

    if (order.note) {
      doc.text("Note: ", column1X, headerStartY + lineYIncrement * line);
      line++;
    }

    doc.setFontType("normal");
    doc.setFontSize("10");
    line = 0;
    let placedDate = new Date(order.placedDate);
    doc.text(
      placedDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
      }),
      1.4,
      headerStartY + lineYIncrement * line
    );
    line++;
    if (order.shipDate) {
      let shipDate = new Date(order.shipDate);
      doc.text(
        shipDate.toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
        }),
        2,
        headerStartY + lineYIncrement * line
      );
    }
    line++;

    if (order.poNumber) {
      doc.text(order.poNumber, 0.9, headerStartY + lineYIncrement * line);
      line++;
    }

    if (order.note) {
      const charsPerLine = 38;
      const words = order.note.split(" ");
      const lines = [];

      words.forEach((word, wordIndex) => {
        if (wordIndex === 0) {
          lines.push(word + " ");
        } else {
          if (lines[lines.length - 1].length + word.length <= charsPerLine) {
            //can fit on line.
            lines[lines.length - 1] += word + " ";
          } else {
            //cannot fit on line, need new one.
            lines.push(word + " ");
          }
        }
      });

      lines.forEach((lineText) => {
        doc.text(lineText, 0.9, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    maxY = headerStartY + lineYIncrement * line;

    //Add Header: Bill To
    doc.setFontType("bold");
    line = 0;
    doc.text("Bill To", column2X, headerStartY + lineYIncrement * line);
    line++;

    doc.setFontType("normal");
    let textSplit = doc.splitTextToSize(
      order.companyName,
      column3X - column2X - 0.05
    );
    textSplit.forEach((nameLine) => {
      doc.text(nameLine, column2X, headerStartY + lineYIncrement * line);
      line++;
    });

    if (order.billingStreetAddress1) {
      textSplit = doc.splitTextToSize(
        order.billingStreetAddress1,
        column3X - column2X - 0.1
      );

      textSplit.forEach((entry) => {
        doc.text(entry, column2X, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    if (order.billingStreetAddress2) {
      textSplit = doc.splitTextToSize(
        order.billingStreetAddress2,
        column3X - column2X - 0.1
      );

      textSplit.forEach((entry) => {
        doc.text(entry, column2X, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    if (order.billingPOBox) {
      doc.text(
        "PO Box " + order.billingPOBox,
        column2X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (order.billingCity) {
      textSplit = doc.splitTextToSize(
        order.billingCity + ", " + order.billingState + " " + order.billingZip,
        column3X - column2X - 0.1
      );

      textSplit.forEach((entry) => {
        doc.text(entry, column2X, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    if (
      order.billingContactName ||
      order.billingContactEmail ||
      order.billingContactPhone
    ) {
      doc.setDrawColor(212, 212, 212);
      doc.setLineWidth(0.01);
      doc.line(
        column2X,
        headerStartY + lineYIncrement * (line - 0.7),
        column2X + 1.9,
        headerStartY + lineYIncrement * (line - 0.7)
      );
    }

    if (order.billingContactName) {
      textSplit = doc.splitTextToSize(
        order.billingContactName,
        column3X - column2X - 0.1
      );

      textSplit.forEach((entry) => {
        doc.text(entry, column2X, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    if (order.billingContactEmail) {
      textSplit = doc.splitTextToSize(
        order.billingContactEmail,
        column3X - column2X - 0.1
      );

      textSplit.forEach((entry) => {
        doc.text(entry, column2X, headerStartY + lineYIncrement * line);
        line++;
      });
    }

    if (order.billingContactPhone) {
      doc.text(
        order.billingContactPhone,
        column2X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (headerStartY + lineYIncrement * line > maxY) {
      maxY = headerStartY + lineYIncrement * line;
    }

    //Add Header: Ship To
    doc.setFontType("bold");
    line = 0;
    doc.text("Ship To", column3X, headerStartY + lineYIncrement * line);
    line++;

    doc.setFontType("normal");

    textSplit = doc.splitTextToSize(order.shippingCompanyName, 8.3 - column3X);

    textSplit.forEach((entry) => {
      doc.text(entry, column3X, headerStartY + lineYIncrement * line);
      line++;
    });

    if (order.shippingStreetAddress1) {
      doc.text(
        order.shippingStreetAddress1,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (order.shippingStreetAddress2) {
      doc.text(
        order.shippingStreetAddress2,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (order.shippingCity) {
      doc.text(
        order.shippingCity +
          ", " +
          order.shippingState +
          " " +
          order.shippingZip,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (
      order.shippingContactName ||
      order.shippingContactEmail ||
      order.shippingContactPhone
    ) {
      doc.setDrawColor(212, 212, 212);
      doc.setLineWidth(0.01);
      doc.line(
        column3X,
        headerStartY + lineYIncrement * (line - 0.7),
        column3X + 1.9,
        headerStartY + lineYIncrement * (line - 0.7)
      );
    }

    if (order.shippingContactName) {
      doc.text(
        order.shippingContactName,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (order.shippingContactEmail) {
      doc.text(
        order.shippingContactEmail,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (order.shippingContactPhone) {
      doc.text(
        order.shippingContactPhone,
        column3X,
        headerStartY + lineYIncrement * line
      );
      line++;
    }

    if (headerStartY + lineYIncrement * line > maxY) {
      maxY = headerStartY + lineYIncrement * line;
    }

    //Add Table
    let body = [];

    //have 7.9" to work with b/c of .3 margins
    let cellWidths;
    let head;
    if (type === "Packing Slip") {
      cellWidths = [0.45, 2.7, 1.2, 0.6, 0.75];

      head = [
        [
          {
            content: "Line",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "center",
              valign: "middle",
              cellWidth: cellWidths[0],
            },
          },
          {
            content: "Name",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[1],
            },
          },
          {
            content: "Item #",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[2],
            },
          },
          {
            content: "Pieces",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[3],
            },
          },
          {
            content: "Unit",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[4],
            },
          },
        ],
      ];
    } else {
      cellWidths = [0.45, 2.7, 1.2, 0.6, 0.75, 1.1, 1.1];
      head = [
        [
          {
            content: "Line",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "center",
              valign: "middle",
              cellWidth: cellWidths[0],
            },
          },
          {
            content: "Name",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[1],
            },
          },
          {
            content: "Item #",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[2],
            },
          },
          {
            content: "Pieces",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[3],
            },
          },
          {
            content: "Unit",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[4],
            },
          },
          {
            content: "Unit Price",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
              cellWidth: cellWidths[5],
            },
          },
          {
            content: "Extended Price",
            styles: {
              fillColor: "#E9ECEF",
              textColor: "#495057",
              fontStyle: "bold",
              halign: "right",
              valign: "middle",
              cellWidth: cellWidths[6],
            },
          },
        ],
      ];
    }

    order.OrderRacks.forEach((orderRack) => {
      //Shade every other row (even rows)
      let fillColor = null;

      if (orderRack.line % 2 === 0) {
        fillColor = "#E9ECEF";
      } else {
        fillColor = null;
      }

      //Rack Summary Line
      if (orderRack.orderedQty) {
        const row = [
          {
            content: orderRack.line,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: orderRack.name,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: orderRack.itemNo,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: orderRack.pieceTotal,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: orderRack.orderedQty + " rack",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              orderRack.eachCost
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/rack",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              orderRack.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
      }

      //Rack Summary Comments
      if (orderRack.comments) {
        body.push([
          {
            content: "",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
          {
            content: orderRack.comments,
            rowSpan: 1,
            colSpan: cellWidths.length - 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
        ]);
      }

      //Write Line Item for Order Rack Products
      orderRack.OrderRackProducts.forEach((orderRackProduct) => {
        //1. Order Rack product without Product Description
        const row = [
          {
            content: orderRackProduct.line + "." + orderRackProduct.subline,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderRackProduct.productName,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: "",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderRackProduct.orderedQty,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: "1 ea.",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              orderRackProduct.eachCost
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/ea.",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
          row.push({
            content:
              "$" +
              orderRackProduct.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
        }
        body.push(row);
      });
    });

    order.OrderHalfShelves.forEach((halfShelf) => {
      //Shade every other row (even rows)
      let fillColor = null;

      if (halfShelf.line % 2 === 0) {
        fillColor = "#E9ECEF";
      } else {
        fillColor = null;
      }

      //Half Shelf Summary Line
      if (halfShelf.orderedQty) {
        const row = [
          {
            content: halfShelf.line,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: halfShelf.name,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: halfShelf.itemNo,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: halfShelf.pieceTotal,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: halfShelf.orderedQty + " half shelf",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              halfShelf.eachCost
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/half shelf",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              halfShelf.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
      }

      //Half Shelf Summary Comments
      if (halfShelf.comments) {
        body.push([
          {
            content: "",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
          {
            content: halfShelf.comments,
            rowSpan: 1,
            colSpan: cellWidths.length - 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
        ]);
      }

      //Write Line Item for Order Half Shelf Products
      halfShelf.OrderHalfShelfProducts.forEach((orderHalfShelfProduct) => {
        //1. Order Half Shelf product without Product Description

        const row = [
          {
            content:
              orderHalfShelfProduct.line + "." + orderHalfShelfProduct.subline,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderHalfShelfProduct.productName,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: "",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderHalfShelfProduct.orderedQty,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: "1 ea.",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              orderHalfShelfProduct.eachCost
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/ea.",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
          row.push({
            content:
              "$" +
              orderHalfShelfProduct.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
        }
        body.push(row);
      });
    });

    order.OrderBoxSkids.forEach((boxSkid) => {
      //Shade every other row (even rows)
      let fillColor = null;

      if (boxSkid.line % 2 === 0) {
        fillColor = "#E9ECEF";
      } else {
        fillColor = null;
      }

      //Box Skid Summary Line
      if (boxSkid.orderedQty) {
        const row = [
          {
            content: boxSkid.line,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: boxSkid.name,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: boxSkid.itemNo,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: boxSkid.pieceTotal,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: boxSkid.orderedQty + " skid",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              boxSkid.eachCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/skid",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              boxSkid.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
      }

      //Box Skid Summary Comments
      if (boxSkid.comments) {
        body.push([
          {
            content: "",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
          {
            content: boxSkid.comments,
            rowSpan: 1,
            colSpan: cellWidths.length - 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
        ]);
      }

      //Write Line Item for Order Box Skid Products
      boxSkid.OrderBoxSkidProducts.forEach((orderBoxSkidProduct) => {
        //1. Order Box Skid product without Product Description

        const row = [
          {
            content:
              orderBoxSkidProduct.line + "." + orderBoxSkidProduct.subline,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderBoxSkidProduct.productName,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: "",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: orderBoxSkidProduct.pieceTotal,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
          {
            content: `${orderBoxSkidProduct.boxOrderedQty} box`,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              orderBoxSkidProduct.boxEachCost
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/box",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
          row.push({
            content:
              "$" +
              orderBoxSkidProduct.orderedTotal
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#778899",
              fillColor: null,
            },
          });
        }
        body.push(row);
      });
    });

    order.OrderProducts.forEach((lineItem: ILineItem) => {
      //Shade every other row (even rows)
      let fillColor = null;

      if (lineItem.line % 2 === 0) {
        fillColor = "#E9ECEF";
      } else {
        fillColor = null;
      }

      //Write Line Item
      if (
        lineItem.boxQty > 0 &&
        lineItem.halfShelfQty == 0 &&
        !lineItem.productDescription
      ) {
        //1. Box Only without Product Description
        const row = [
          {
            content: lineItem.line,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo + "-B",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerBox * lineItem.boxQty,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.boxQty + " box",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/box",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
      } else if (
        lineItem.boxQty > 0 &&
        lineItem.halfShelfQty == 0 &&
        lineItem.productDescription
      ) {
        //2. Box Only with Product Description
        const row = [
          {
            content: lineItem.line,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo + "-B",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerBox * lineItem.boxQty,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.boxQty + " box",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/box",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }

        body.push(row);
        body.push([
          {
            content: lineItem.productDescription,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#3ac476",
              fillColor: fillColor,
            },
          },
        ]);
      } else if (
        lineItem.halfShelfQty > 0 &&
        lineItem.boxQty == 0 &&
        !lineItem.productDescription
      ) {
        //3. HalfShelf Only without Product Description
        const row = [
          {
            content: lineItem.line,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo + "-HS",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerHalfShelf * lineItem.halfShelfQty,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.halfShelfQty + " half shelf",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerHalfShelf)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/half shelf",
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (
                lineItem.halfShelfQty *
                lineItem.eachCost *
                lineItem.qtyPerHalfShelf
              )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
      } else if (
        lineItem.halfShelfQty > 0 &&
        lineItem.boxQty == 0 &&
        lineItem.productDescription
      ) {
        //4. HalfShelf Only with Product Description
        const row = [
          {
            content: lineItem.line,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo + "-HS",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerHalfShelf * lineItem.halfShelfQty,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.halfShelfQty + " half shelf",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerHalfShelf)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/half shelf",
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (
                lineItem.halfShelfQty *
                lineItem.eachCost *
                lineItem.qtyPerHalfShelf
              )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);
        body.push([
          {
            content: lineItem.productDescription,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#3ac476",
              fillColor: fillColor,
            },
          },
        ]);
      } else if (
        lineItem.halfShelfQty > 0 &&
        lineItem.boxQty > 0 &&
        !lineItem.productDescription
      ) {
        //5. Box and HalfShelf without Product Description
        const row = [
          {
            content: lineItem.line,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo + "-HS",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerHalfShelf * lineItem.halfShelfQty,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.halfShelfQty + " half shelf",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerHalfShelf)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/half shelf",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (
                lineItem.halfShelfQty *
                lineItem.eachCost *
                lineItem.qtyPerHalfShelf
              )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);

        const row2 = [
          {
            content: lineItem.itemNo + "-B",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.qtyPerBox * lineItem.boxQty,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.boxQty + " box",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];

        if (type !== "Packing Slip") {
          row2.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/box",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row2.push({
            content:
              "$" +
              (lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row2);
      } else if (
        lineItem.halfShelfQty > 0 &&
        lineItem.boxQty > 0 &&
        lineItem.productDescription
      ) {
        //6. Box and HalfShelf with Product Description
        const row = [
          {
            content: lineItem.line,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "middle",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.productName,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.itemNo,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content:
              lineItem.qtyPerHalfShelf * lineItem.halfShelfQty +
              lineItem.qtyPerBox * lineItem.boxQty,
            rowSpan: 2,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.halfShelfQty + " half shelf",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];
        if (type !== "Packing Slip") {
          row.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerHalfShelf)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/half shelf",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row.push({
            content:
              "$" +
              (
                lineItem.halfShelfQty *
                lineItem.eachCost *
                lineItem.qtyPerHalfShelf
              )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row);

        const row2 = [
          {
            content: lineItem.productDescription,
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#3ac476",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.boxQty + " box",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          },
        ];
        if (type !== "Packing Slip") {
          row2.push({
            content:
              "$" +
              (lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              "/box",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
          row2.push({
            content:
              "$" +
              (lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "middle",
              textColor: "#000000",
              fillColor: fillColor,
            },
          });
        }
        body.push(row2);
      }

      //Write Comments
      if (lineItem.comments) {
        body.push([
          {
            content: "",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
          {
            content: lineItem.comments,
            rowSpan: 1,
            colSpan: cellWidths.length - 1,
            styles: {
              halign: "left",
              valign: "middle",
              textColor: "#007BFF",
              fillColor: fillColor,
            },
          },
        ]);
      }
    });

    //Add body totals
    let unitsString = "";

    if (
      order.halfShelvesOrdered > 0 ||
      order.prebuiltRacksOrdered > 0 ||
      order.prebuiltHalfShelvesOrdered > 0
    ) {
      let racks =
        (order.halfShelvesOrdered + order.prebuiltHalfShelvesOrdered) /
          halfShelvesPerRack +
        order.prebuiltRacksOrdered;
      unitsString = racks > 1 ? racks + " racks; " : racks + "rack; ";
    }

    if (order.boxSkidsOrdered + order.boxesOrdered > 0) {
      let totalBoxesOrdered = NumberUtils.getBoxesOrdered(order);

      unitsString =
        unitsString +
        (totalBoxesOrdered > 1
          ? totalBoxesOrdered + " boxes"
          : totalBoxesOrdered + " box");
    }

    doc.autoTable({
      startY: maxY,
      margin: 0.3,
      head: head,
      body: body,
    });

    //Add Line Break
    doc.line(
      0.3,
      doc.lastAutoTable.finalY + 0.1,
      8.3,
      doc.lastAutoTable.finalY + 0.1
    );

    //not displayed, but controls widths
    let headTotals = [
      [
        {
          content: "",
          styles: {
            cellWidth: cellWidths[0],
            fillColor: null,
          },
        },
        {
          content: "",
          styles: {
            cellWidth: cellWidths[1],
            fillColor: null,
          },
        },
        {
          content: "",
          styles: {
            cellWidth: cellWidths[2],
            fillColor: null,
          },
        },
        {
          content: "",
          styles: {
            cellWidth: cellWidths[3],
            fillColor: null,
          },
        },
        {
          content: "",
          styles: {
            cellWidth: cellWidths[4],
            fillColor: null,
          },
        },
      ],
    ];

    if (type !== "Packing Slip") {
      headTotals[0].push({
        content: "",
        styles: {
          cellWidth: cellWidths[5],
          fillColor: null,
        },
      });
      headTotals[0].push({
        content: "",
        styles: {
          cellWidth: cellWidths[6],
          fillColor: null,
        },
      });
    }

    let bodyTotals = [];

    //Product Total
    const row = [
      {
        content: unitsString,
        rowSpan: 1,
        colSpan:
          type !== "Packing Slip"
            ? headTotals[0].length - 2
            : headTotals[0].length,
        styles: {
          halign: "right",
          valign: "top",
          textColor: "#000000",
          fontStyle: "bold",
          fillColor: null,
        },
      },
    ];

    if (type !== "Packing Slip") {
      row.push({
        content: "Product Total",
        rowSpan: 1,
        colSpan: 1,
        styles: {
          halign: "right",
          valign: "top",
          textColor: "#000000",
          fontStyle: "bold",
          fillColor: null,
        },
      });
      row.push({
        content:
          "$" +
          order.productTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
        rowSpan: 1,
        colSpan: 1,
        styles: {
          halign: "right",
          valign: "top",
          textColor: "#000000",
          fontStyle: "bold",
          fillColor: null,
        },
      });
    }
    bodyTotals.push(row);

    if (type !== "Packing Slip") {
      //Shipping Total
      bodyTotals.push([
        {
          content:
            order.shippingRate === null
              ? "Shipping"
              : "Shipping (" + order.shippingRate.toFixed(2) + "%)",
          rowSpan: 1,
          colSpan: 6,
          styles: {
            halign: "right",
            valign: "top",
            textColor: "#000000",
            fontStyle: "bold",
            fillColor: null,
          },
        },
        {
          content:
            order.shippingRate === null
              ? "TBD"
              : "$" +
                order.shippingTotal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
          rowSpan: 1,
          colSpan: 1,
          styles: {
            halign: "right",
            valign: "top",
            textColor: "#000000",
            fontStyle: "bold",
            fillColor: null,
          },
        },
      ]);

      //Liftgate Charge
      if (order.liftGateCharge) {
        bodyTotals.push([
          {
            content: "Liftgate Charge",
            rowSpan: 1,
            colSpan: 6,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
          {
            content:
              "$" +
              order.liftGateCharge
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
        ]);
      }

      //Customer Discount
      if (order.customerDiscount) {
        bodyTotals.push([
          {
            content:
              "Customer Discount (" + order.customerDiscount.toFixed(2) + "%)",
            rowSpan: 1,
            colSpan: 6,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
          {
            content:
              "($" +
              order.customerDiscountAmt
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              ")",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
        ]);
      }

      //Order Discount
      if (order.orderDiscount) {
        bodyTotals.push([
          {
            content: "Order Discount (" + order.orderDiscount.toFixed(2) + "%)",
            rowSpan: 1,
            colSpan: 6,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
          {
            content:
              "($" +
              order.orderDiscountAmt
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              ")",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
        ]);
      }

      //Credit
      if (order.credit) {
        bodyTotals.push([
          {
            content: "Credit",
            rowSpan: 1,
            colSpan: 6,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
          {
            content:
              "($" +
              order.credit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
              ")",
            rowSpan: 1,
            colSpan: 1,
            styles: {
              halign: "right",
              valign: "top",
              textColor: "#000000",
              fontStyle: "bold",
              fillColor: null,
            },
          },
        ]);
      }
    }

    doc.autoTable({
      startY: doc.lastAutoTable.finalY - 0.05,
      margin: 0.3,
      head: headTotals,
      body: bodyTotals,
      didParseCell: (data) => {
        if (data.section == "head") {
          data.cell.styles.cellPadding = 0;
          data.row.height = 0.01;
        }
      },
    });

    //Add Final Total Row
    //Add Line Break
    doc.line(
      0.3,
      doc.lastAutoTable.finalY + 0.05,
      8.3,
      doc.lastAutoTable.finalY + 0.05
    );

    if (type !== "Packing Slip") {
      //Order Total
      let orderTotalAsString = "TBD";

      if (order.shippingRate != null) {
        orderTotalAsString =
          "$" +
          (
            order.productTotal +
            order.shippingTotal +
            order.liftGateCharge -
            order.customerDiscountAmt -
            order.orderDiscountAmt -
            order.credit
          )
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      let orderTotal = [];
      orderTotal.push([
        {
          content: "Order Total",
          rowSpan: 1,
          colSpan: 6,
          styles: {
            halign: "right",
            valign: "top",
            textColor: "#000000",
            fontStyle: "bold",
            fillColor: null,
          },
        },
        {
          content: orderTotalAsString,
          rowSpan: 1,
          colSpan: 1,
          styles: {
            halign: "right",
            valign: "top",
            textColor: "#000000",
            fontStyle: "bold",
            fillColor: null,
          },
        },
      ]);

      doc.autoTable({
        startY: doc.lastAutoTable.finalY - 0.05,
        margin: 0.3,
        head: headTotals,
        body: orderTotal,
        didParseCell: (data) => {
          if (data.section == "head") {
            data.cell.styles.cellPadding = 0;
            data.row.height = 0.01;
          }
        },
      });
    }

    //Add footer to all pages
    doc.setFontType("normal");

    let pageCount = doc.internal.getNumberOfPages();
    if (pageCount > 1) {
      for (let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
          type +
            " " +
            order.id.substring(0, 8) +
            " " +
            doc.internal.getCurrentPageInfo().pageNumber +
            "/" +
            pageCount,
          0.3,
          10.8
        );
      }
    }

    //Launch PDF with Print Menu
    doc.autoPrint({ variant: "non-conform" });
    doc.save(type + " " + order.id.substring(0, 8) + ".pdf");
  }
}
