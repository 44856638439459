import { STATES } from "../data/states";
import { ICompany } from "./company.interface";

export interface IAddress {
  id?: string;
  type: AddressTypes;
  street1: string;
  street2: string;
  poBox?: string;
  city: string;
  state: STATES;
  zip: string;
  shippingRate?: number;
  companyName?: string;
  companyId?: string;
  Company?: ICompany;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum AddressTypes {
  "shipping" = "shipping",
  "billing" = "billing",
}
