import { ProductService } from './../../shared/services/product.service';
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompany } from 'src/app/shared/interfaces/company.interface';
import { NotFoundError } from 'src/app/shared/errors/not-found-error';
import { OrderService } from 'src/app/shared/services/order.service';
import { IProduct } from 'src/app/shared/interfaces/product.interface';

@Component({
  selector: 'app-item-to-order-modal',
  templateUrl: './add-item-to-order-modal.component.html',
  styleUrls: ['./add-item-to-order-modal.component.css']
})
export class AddItemToOrderModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _orderService: OrderService,
    private _productService: ProductService
  ) { }

  @Input() orderId: any;
  @Output() addItem = new EventEmitter<any>();

  public dto: any;
  public productOptions: Array<IProduct>;

  ngOnInit() {
    this._productService.getSellable().subscribe(
      (res: Array<IProduct>) => {
        this.productOptions = res;
      },
      error => {
        console.log(error);
      })
  }

  public getModalHeader() {
    return 'Add Item to Order';
  }

  public closeModal() {
    this.activeModal.close();
  }

  public addItemToOrder(data) {

    this._orderService.addItemToOrder(data).subscribe(
      (res: any) => {
        let addItemResponse = {
          type: 'success',
          orderProduct: res.orderProduct,
          order: res.order,
          alert: {
            type: 'success',
            title: "Item Added",
            msg: "Item " + res.orderProduct.productName + " was added to the order."
          }
        }
        this.activeModal.close();
        this.addItem.emit(addItemResponse);
      }, error => {
        let addItemResponse = {
          type: 'error',
          alert: {
            type: 'danger',
            title: "Error Adding Item",
            msg: error.originalError.error.errors[0].description
          }
        }
        this.activeModal.close();
        this.addItem.emit(addItemResponse);
      });
  }

}