<div class="modal-header">
    <h4 class="modal-title pull-left">Request New Account</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="!attempted" class="modal-body row">
    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
      <h3>Contact Information</h3>
      <div class="row">
        <div class="form-group col-md-6">
            <label for="firstName">First Name</label>
            <input 
              required
              type="text" 
              class="form-control" 
              name='firstName'
              ngModel
              #firstName="ngModel">
              <div class="error alert alert-danger" *ngIf="firstName.touched && firstName.errors">
                  <p>First Name is Required</p>
              </div>
          </div>
          <div class="form-group col-md-6">
              <label for="lastName">Last Name</label>
              <input 
                type="text" 
                class="form-control" 
                name='lastName'
                ngModel
                #lastName="ngModel">
            </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input
              required
              pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
              type="text" 
              class="form-control" 
              name='email'
              ngModel
              #email="ngModel">
            <div class="error alert alert-danger" *ngIf="email.touched && email.errors">
                <p *ngIf="email.errors.required">
                    We need your email to get back in touch with you.
                </p>
                <p *ngIf="email.errors.pattern">
                    Please provide a valid email address, so we can get back in touch with you.
                </p>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="phone">Phone</label>
            <input
              type="text" 
              class="form-control" 
              name='phone'
              ngModel
              #phone="ngModel">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
            <label for="companyName">Company Name</label>
            <input 
              required
              type="text" 
              class="form-control" 
              name='companyName'
              ngModel
              #companyName="ngModel">
              <div class="error alert alert-danger" *ngIf="companyName.touched && companyName.errors">
                <p *ngIf="companyName.errors.required">
                    Please let us know the name of your company. 
                </p>
              </div>
        </div>
      </div>
        <h3>Anything You'd like to Share</h3>
        <div class="form-group">
            <label for="contactNotes" class="sr-only">Notes For Contact</label>
            <textarea
              class="form-control" 
              name="contactNotes" 
              placeholder="If there's anything you'd like us to know, you can put it here..." 
              rows="2" 
              cols="50"
              ngModel
              #contactNotes="ngModel"></textarea>
        </div>
        <div class="submitbtn-container">
            <button id="submitBtn" type="submit" [disabled]="!contactForm.valid">Submit</button>
        </div>
    </form>
</div>
<div *ngIf="attempted && emailSent" class="modal-body row">
    <p class="email-response col-md-12 alert alert-success">Thank you for expressing interest in our wholesale order site. We've 
        received your request and will review it shortly. You should hear back from us within 24 hours.</p>
</div>
<div *ngIf="attempted && !emailSent" class="modal-body row">
    <p class="email-response col-md-12 alert alert-danger">There was an error submitting your request. Please contact us at 865-977-6942.</p>
</div>

