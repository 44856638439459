import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppError } from "../errors/app-error";
import { NotFoundError } from "../errors/not-found-error";
import { BadInput } from "../errors/bad-input";
import { environment } from "../../../environments/environment";

import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

let env = environment.production ? "production" : "development";
import * as config from "../../../config.json";

export class DataService {
  private _headers;

  constructor(private _url: string, private _http: HttpClient) {
    this._url = config[env].apiUrl + _url;
    this._headers = new HttpHeaders({ "Content-Type": "application/json" });
  }

  public getApiUrl(): string {
    return this._url;
  }

  public getHeaders() {
    return this._headers;
  }

  public getHttp() {
    return this._http;
  }

  public getAll() {
    return this._http.get(this._url).pipe(catchError(this.handleError));
  }

  public getById(resource) {
    return this._http
      .get(this._url + "/" + resource.id)
      .pipe(catchError(this.handleError));
  }

  public create(resource) {
    return this._http
      .post(this._url, JSON.stringify(resource), { headers: this._headers })
      .pipe(catchError(this.handleError));
  }

  public update(resource) {
    return this._http
      .patch(this._url + "/" + resource.id, JSON.stringify(resource), {
        headers: this._headers,
      })
      .pipe(catchError(this.handleError));
  }

  public delete(id) {
    return this._http
      .delete(this._url + "/" + id, { headers: this._headers })
      .pipe(catchError(this.handleError));
  }

  public handleError(error: Response) {
    if (error.status === 404) return throwError(new NotFoundError(error));

    if (error.status === 400) return throwError(new BadInput(error));

    return throwError(new AppError(error));
  }
}
