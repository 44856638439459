import { ValidatorFn, AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, Input } from "@angular/core";

export function isBetweenValidator(rangeInput): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let values = rangeInput.split(":");
        let min = values[0];
        let max = values[1];
        const isBetween = control.value >= min && control.value <= max;
        return !isBetween ? { 'isBetween': { value: control.value, minAllowed: min, maxAllowed: max } } : null;
    };
}

@Directive({
    selector: '[isBetween]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IsBetweenValidatorDirective, multi: true }]
})
export class IsBetweenValidatorDirective implements Validator {
    @Input('isBetween') rangeInput: string;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return isBetweenValidator(this.rangeInput)(control);
    }
}
