import { ValidatorFn, AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive } from "@angular/core";

export function selectionRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isStillDefault = control.value === -1 || control.value === "-1";
        return isStillDefault ? { 'selectionRequired': { value: control.value } } : null;
    };
}

@Directive({
    selector: '[selectionRequired]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SelectionRequiredValidatorDirective, multi: true }]
})
export class SelectionRequiredValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } | null {
        return selectionRequiredValidator()(control);
    }
}
