import { CategoryService } from "../../shared/services/category.service";
import { ProductService } from "../../shared/services/product.service";
import { BoxService } from "../../shared/services/box.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundError } from "../../shared/errors/not-found-error";
import { ImageService, ImageTypes } from "../../shared/services/image.service";
import { IProduct } from "src/app/shared/interfaces/product.interface";
import { ICategory } from "src/app/shared/interfaces/category.interface";
import { IBox } from "src/app/shared/interfaces/box.interface";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "./../../auth/services/auth.service";
import { Observable } from "rxjs/Rx";

@Component({
  selector: "app-product-modal",
  templateUrl: "./product-modal.component.html",
  styleUrls: ["./product-modal.component.css"],
})
export class ProductModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _productService: ProductService,
    private _imageService: ImageService,
    private _categoryService: CategoryService,
    private _boxService: BoxService,
    private _fb: FormBuilder,
    private _authService: AuthService
  ) {}

  @Input() product: IProduct;
  @Output() createProduct = new EventEmitter<any>();
  @Output() deleteProduct = new EventEmitter<any>();
  @Output() updateProduct = new EventEmitter<any>();

  public modalAction: ModalActions;
  public categoryOptions: Array<ICategory>;
  public boxOptions: Array<IBox>;
  public productImagesToUpload: Array<File> = null;
  public imageChanged: boolean = false;
  public isLoading: boolean;

  //Forms
  public productForm = this._fb.group({
    name: ["", Validators.required],
    description: [""],
    categoryId: [, Validators.required],
    upc: ["", Validators.required],
    itemNo: ["", Validators.required],
    display: [true],
    adminOnly: [false],
    eachCost: [0, [Validators.min(0), Validators.required]],
    rackable: [false],
    qtyPerHalfShelf: [0, [Validators.required, Validators.min(1)]],
    boxable: [false],
    boxId: [],
    qtyPerBox: [0, Validators.min(0)],
  });

  ngOnInit() {
    this.isLoading = false;
    //Determine Purpose of Launching this Modal
    if (this.product) {
      this.modalAction = ModalActions.Edit;

      //Set Initial Values
      this.productForm.setValue({
        name: this.product.name,
        description: this.product.description,
        categoryId: this.product.categoryId,
        upc: this.product.upc,
        itemNo: this.product.itemNo,
        display: this.product.display,
        adminOnly: this.product.adminOnly,
        eachCost: this.product.eachCost,
        rackable: this.product.rackable,
        qtyPerHalfShelf: this.product.qtyPerHalfShelf,
        boxable: this.product.boxable,
        boxId: this.product.boxId || null,
        qtyPerBox: this.product.qtyPerBox,
      });

      if (!this.product.rackable) this.qtyPerHalfShelf.disable();
      if (!this.product.boxable) {
        this.qtyPerBox.disable();
        this.boxId.disable();
      }

      //Get image
      if (this.product.ProductImages) {
        if (this.product.ProductImages.length > 0) {
          this._imageService
            .getImage(
              ImageTypes.product,
              this.product.ProductImages[0].fileName
            )
            .subscribe(
              (url) => {
                this.product.ProductImages[0].imageUrl = url;
              },
              (error) => {
                console.log("error getting product image.");
              }
            );
        }
      }
    } else {
      this.modalAction = ModalActions.Create;
      //Disable defaults.
      this.qtyPerHalfShelf.disable();
      this.qtyPerBox.disable();
      this.boxId.disable();
      this.product = <IProduct>{};
      this.product.display = true;
    }

    this._categoryService.getAll().subscribe(
      (res: Array<ICategory>) => {
        this.categoryOptions = res;
      },
      (error) => {
        console.log("There was an error getting the categories.");
      }
    );

    this._boxService.getAll().subscribe(
      (res: Array<IBox>) => {
        this.boxOptions = res;
      },
      (error) => {
        console.log("There was an error getting the boxes.");
      }
    );
  }

  get name() {
    return this.productForm.get("name");
  }

  get description() {
    return this.productForm.get("description");
  }

  get categoryId() {
    return this.productForm.get("categoryId");
  }

  get upc() {
    return this.productForm.get("upc");
  }

  get itemNo() {
    return this.productForm.get("itemNo");
  }

  get display() {
    return this.productForm.get("display");
  }

  get adminOnly() {
    return this.productForm.get("adminOnly");
  }

  get eachCost() {
    return this.productForm.get("eachCost");
  }

  get rackable() {
    return this.productForm.get("rackable");
  }

  get qtyPerHalfShelf() {
    return this.productForm.get("qtyPerHalfShelf");
  }

  get boxable() {
    return this.productForm.get("boxable");
  }

  get boxId() {
    return this.productForm.get("boxId");
  }

  get qtyPerBox() {
    return this.productForm.get("qtyPerBox");
  }

  public toggleRackable(rackable) {
    if (rackable) {
      this.qtyPerHalfShelf.enable();
    } else {
      this.qtyPerHalfShelf.disable();
    }
  }

  public toggleBoxable(boxable) {
    if (boxable) {
      this.qtyPerBox.enable();
      this.boxId.enable();
    } else {
      this.qtyPerBox.disable();
      this.boxId.disable();
    }
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New Product";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit Product";
    } else {
      return "Product Form";
    }
  }

  public closeModal() {
    this.activeModal.close();
  }

  public updateProductImage(files: FileList) {
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      if (!this._imageService.isAcceptedFileType(files.item(i))) {
        hasError = true;
        break;
      }
    }

    if (hasError)
      return console.log(
        "The file type selected is not a valid file type. Only png, jpg or jpeg types allowed."
      );

    this.imageChanged = true;
    delete this.product.ProductImages;
    this.productImagesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.productImagesToUpload.push(files.item(i));
    }
  }

  public add() {
    this.isLoading = true;
    this._productService.create(this.productForm.value).subscribe(
      (createRes: any) => {
        if (this.imageChanged) {
          this._imageService
            .uploadImages(
              ImageTypes.product,
              createRes.product.id,
              this.productImagesToUpload
            )
            .subscribe(
              (uploadImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                uploadImageRes.filesData.forEach((fileData) => {
                  createRes.product.ProductImages.push({
                    productId: uploadImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let productCreateResponse = {
                  type: "success",
                  product: createRes.product,
                  alert: {
                    type: createRes.status,
                    title: "Product Created",
                    msg: createRes.message,
                  },
                };
                this.activeModal.close();
                this.createProduct.emit(productCreateResponse);
              },
              (error) => {
                this.isLoading = false;

                let productCreateResponse = {
                  type: "success",
                  product: createRes.product,
                  alert: {
                    type: createRes.status,
                    title: "Product Created",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.createProduct.emit(productCreateResponse);
              }
            );
        } else {
          this.isLoading = false;
          let productCreateResponse = {
            type: "success",
            product: createRes.product,
            alert: {
              type: createRes.status,
              title: "Product Added",
              msg: createRes.message,
            },
          };
          this.activeModal.close();
          this.createProduct.emit(productCreateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let productCreateResponse = {
          type: "error",
          product: this.product,
          alert: {
            type: "danger",
            title: "Product Not Created",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.createProduct.emit(productCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._productService.delete(id).subscribe(
      (res: any) => {
        let deleteProductResponse = {
          type: "success",
          product: this.product,
          alert: {
            type: "success",
            title: "Product deleted.",
            msg: res.message,
          },
        };
        this.deleteProduct.emit(deleteProductResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let deleteProductResponse = {
            type: "success",
            product: this.product,
            alert: {
              type: "success",
              title: "Product deleted.",
              msg: "Product " + this.product.description + " has been deleted.",
            },
          };
          this.deleteProduct.emit(deleteProductResponse);
        } else {
          let deleteProductResponse = {
            type: "error",
            product: this.product,
            alert: {
              type: "danger",
              title: "Product was not deleted.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.deleteProduct.emit(deleteProductResponse);
        }
      }
    );
  }

  public update() {
    this.isLoading = true;
    this.productForm.value.id = this.product.id;

    this._productService.update(this.productForm.value).subscribe(
      (updateProductRes: any) => {
        if (this.imageChanged) {
          updateProductRes.product.ProductImages = [];
          this._imageService
            .uploadImages(
              ImageTypes.product,
              this.product.id,
              this.productImagesToUpload,
              true
            )
            .subscribe(
              (updateImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                updateImageRes.filesData.forEach((fileData) => {
                  updateProductRes.product.ProductImages.push({
                    productId: updateImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let productUpdateResponse = {
                  type: "success",
                  product: updateProductRes.product,
                  alert: {
                    type: updateProductRes.status,
                    title: "Product Updated",
                    msg: updateProductRes.message,
                  },
                };
                this.activeModal.close();
                this.updateProduct.emit(productUpdateResponse);
              },
              (error) => {
                this.isLoading = false;

                let productUpdateResponse = {
                  type: "success",
                  product: updateProductRes.product,
                  alert: {
                    type: updateProductRes.status,
                    title: "Product Updated",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.updateProduct.emit(productUpdateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let productUpdateResponse = {
            type: "success",
            product: updateProductRes.product,
            alert: {
              type: updateProductRes.status,
              title: "Product Updated",
              msg: updateProductRes.message,
            },
          };
          this.activeModal.close();
          this.updateProduct.emit(productUpdateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let productUpdateResponse = {
          type: "error",
          product: this.product,
          alert: {
            type: "danger",
            title: "Product Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateProduct.emit(productUpdateResponse);
      }
    );
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create",
}
