import { AuthService } from "./../../services/auth.service";
import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { OnInit } from "@angular/core";
import { RequestAccountModalComponent } from "../request-account-modal/request-account-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "login-form",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  hasError: boolean;
  errorMessage: string;
  title: string = "Welcome to our wholesale ordering site.";
  welcomeMessage: string;

  constructor(
    public authService: AuthService,
    private _modalService: NgbModal,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authService.removeToken();

    this._route.queryParams.subscribe((params) => {
      if (params.f == "activate") {
        this.welcomeMessage =
          "Your password was successfully created! You're all set to log in now.";
      }
      if (params.f == "reset-password") {
        this.welcomeMessage =
          "Your password was successfully reset. You're all set to log in now.";
      }
    });
  }

  public onLogin() {
    this.authService
      .login({ username: this.email, password: this.password })
      .subscribe(
        (data) => {
          if (data.myToken) {
            localStorage.setItem("id_token", data.myToken);
            if (this.authService.isAdmin()) {
              this._router.navigate(["orders"]);
            } else if (this.authService.isCustomer()) {
              this._router.navigate(["store"]);
            } else {
              this._router.navigate(["unauthorized"]);
            }
          }
        },
        (error) => {
          this.hasError = true;
          this.errorMessage = error.originalError.error.errors[0].description;
        }
      );
  }

  public openRequestAccountModal() {
    const modalRef = this._modalService.open(RequestAccountModalComponent);
  }
}
