import { CompanyService } from "../../shared/services/company.service";
import { AuthService } from "./../../auth/services/auth.service";
import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { STATES } from "src/app/shared/data/states";
import { ICompany } from "src/app/shared/interfaces/company.interface";
import {
  IAddress,
  AddressTypes,
} from "src/app/shared/interfaces/address.interface";
import { IOrderDTO } from "src/app/shared/interfaces/order-dto.interface";
import { ICart } from "src/app/shared/interfaces/cart.interface";
import { OrderService } from "src/app/shared/services/order.service";
import { UserService } from "src/app/shared/services/user.service";
import { Router } from "@angular/router";
import { DateUtils } from "src/app/utils/date-utils";

@Component({
  selector: "app-place-order-modal",
  templateUrl: "./place-order-modal.component.html",
  styleUrls: ["./place-order-modal.component.css"],
})
export class PlaceOrderModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,

    private _authService: AuthService,
    private _companyService: CompanyService,
    private _orderService: OrderService,
    private _userService: UserService,
    private _router: Router
  ) {}

  @Input() cart: ICart;

  public liftGateCharge = 75;
  public pctComplete;
  public addressesReady: boolean;
  public isLoading = false;

  //Shipping Constraints
  public startDate;
  public minDate;
  public maxDate;

  public orderOptions = {
    billingSkipped: false,
    shippingSkipped: false,
    newCustomer: null,
    newBillingAddress: null,
    newShippingAddress: null,
    newShiptoWorkload: false, //Used to flag when customer creates accounts with a new shipto
  };

  // Drop Down Options
  public companyOptions: Array<ICompany> = [];
  public shippingAddressOptions: Array<IAddress> = [];
  public billingAddressOptions: Array<IAddress> = [];
  public stateOptions: Array<string> = [];

  public order: IOrderDTO = <IOrderDTO>{};

  public activeStep;
  public orderSteps = [];

  ngOnInit() {
    this.order.liftGateCharge = 0;
    this.order.orderDiscount = 0;
    let todayAsDate = new Date();
    let minDateAsDate: Date;

    if (todayAsDate.getDay() < 5) {
      minDateAsDate = DateUtils.addDays(todayAsDate, 15 - todayAsDate.getDay());
    } else if (todayAsDate.getDay() >= 5) {
      minDateAsDate = DateUtils.addDays(todayAsDate, 14);
    }

    let maxDateAsDate = DateUtils.addDays(todayAsDate, 730);
    this.minDate = {
      year: minDateAsDate.getUTCFullYear(),
      month: minDateAsDate.getUTCMonth() + 1,
      day: minDateAsDate.getUTCDate(),
    };
    this.maxDate = {
      year: maxDateAsDate.getUTCFullYear(),
      month: maxDateAsDate.getUTCMonth() + 1,
      day: maxDateAsDate.getUTCDate(),
    };

    //Handle Admins
    if (this.isAdmin()) {
      this.orderSteps = [
        "selectCustomerType",
        "selectCustomer",
        "selectBillingAddress",
        "enterBillingContact",
        "selectShippingAddress",
        "enterShippingContact",
        "selectShipDate",
        "needLiftGate",
        "orderDiscount",
        "orderNote",
        "reviewAndSubmit",
      ];

      //Set the starting point of the form for Admins
      this.activeStep = "selectCustomerType";

      this._companyService.getAll().subscribe(
        (res: Array<ICompany>) => {
          this.companyOptions = res;
        },
        (error) => {
          console.log("There was an error getting companies.");
        }
      );

      //Handle Customers
    } else {
      this.orderOptions.newCustomer = false;
      this.orderOptions.newBillingAddress = false;
      this.orderOptions.newShippingAddress = false;

      //Get Company Information
      this._userService.getLoggedInUsersCompany().subscribe(
        (company: any) => {
          if (company) {
            this.orderSteps = [
              "selectBillingAddress",
              "enterBillingContact",
              "selectShippingAddress",
              "enterShippingContact",
              "selectShipDate",
              "needLiftGate",
              "reviewAndSubmit",
            ];
            this.order.Company = company;
            this.onCompanyChange("init", company.id);
            this.activeStep = this.orderSteps[0];
          }
        },
        (error) => {
          console.log(
            "There was an error getting the logged in users companies"
          );
        }
      );

      //Set the starting point of the form for Customers
    }

    for (let key in STATES) {
      this.stateOptions.push(key);
    }
  }

  public getModalHeader() {
    return "Submit Order";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public updateCustomerType(customerType) {
    this.activeStep = "selectCustomer";

    if (customerType == "new") {
      this.addressesReady = true;
      this.orderOptions.newCustomer = true;
      this.orderOptions.newBillingAddress = true;
      this.orderOptions.newShippingAddress = true;
      this.billingAddressOptions = [];
      this.shippingAddressOptions = [];

      // New shipping address need to have shipping rates assigned
      // by popes employee when a customer places them.
      this.orderOptions.newShiptoWorkload = false;
    } else {
      this.orderOptions.newCustomer = false;
      this.orderOptions.newBillingAddress = false;
      this.orderOptions.newShippingAddress = false;
    }
  }

  public moveToNextStep(f) {
    //Set Appropritae Order Attributes
    for (let key in f.value) {
      // Handle Existing Company
      if (key == "companyId") {
        this.order.Company = this.companyOptions.find(
          (company) => company.id == f.value[key]
        );
      }
      // Handle Existing Billing Address
      else if (key == "billingAddressId") {
        this.order.BillingAddress = this.billingAddressOptions.find(
          (address) => address.id == f.value[key]
        );
      }
      // Handle Existing Shipping Address
      else if (key == "shippingAddressId") {
        this.order.ShippingAddress = this.shippingAddressOptions.find(
          (address) => address.id == f.value[key]
        );
      }
      // Handle All Other Cases
      else {
        this.order[key] = f.value[key];
      }
    }

    //Move To Next Step
    let currentActiveStepIndex = this.orderSteps.indexOf(this.activeStep);
    if (currentActiveStepIndex < this.orderSteps.length - 1) {
      this.activeStep = this.orderSteps[currentActiveStepIndex + 1];
    }

    //Set Pct Complete
    this.pctComplete =
      ((currentActiveStepIndex + 1) / this.orderSteps.length) * 100;
  }

  public skipStep(f) {
    //Note what was skipped
    if (this.activeStep == "selectBillingAddress") {
      this.orderOptions.newBillingAddress = false;
      this.orderOptions.billingSkipped = true;
    } else if (this.activeStep == "selectShippingAddress") {
      this.orderOptions.newShippingAddress = false;
      this.orderOptions.shippingSkipped = true;
    }

    //Move To Next Step
    let currentActiveStepIndex = this.orderSteps.indexOf(this.activeStep);
    if (currentActiveStepIndex < this.orderSteps.length - 1) {
      this.activeStep = this.orderSteps[currentActiveStepIndex + 2];
    }

    //Set Pct Complete
    this.pctComplete =
      ((currentActiveStepIndex + 1) / this.orderSteps.length) * 100;
  }

  public moveToPriorStep(f) {
    //Remove Order Attributes
    for (let key in f.value) {
      // Handle Existing Company
      if (key == "companyId") {
        delete this.order.Company;
      }
      // Handle Existing Billing Address
      else if (key == "billingAddressId") {
        delete this.order.BillingAddress;
      }
      // Handle Existing Shipping Address
      else if (key == "shippingAddressId") {
        delete this.order.ShippingAddress;
      }
      // Handle All Other Cases
      else {
        delete this.order[key];
      }
    }

    //Move to Prior Step
    let currentActiveStepIndex = this.orderSteps.indexOf(this.activeStep);

    if (currentActiveStepIndex > 0) {
      let currentActiveStep = this.orderSteps[currentActiveStepIndex];

      //If skipped billing reset and jump back to it.
      if (
        currentActiveStep == "selectShippingAddress" &&
        this.orderOptions.billingSkipped
      ) {
        this.orderOptions.billingSkipped = false;
        this.activeStep = this.orderSteps[currentActiveStepIndex - 2];
        //If skipped shipping reset and jump back to it.
      } else if (
        currentActiveStep == "selectShipDate" &&
        this.orderOptions.shippingSkipped
      ) {
        this.orderOptions.shippingSkipped = false;
        this.activeStep = this.orderSteps[currentActiveStepIndex - 2];
      } else {
        this.activeStep = this.orderSteps[currentActiveStepIndex - 1];
      }
    }

    // Null out new customer option until it's selected.
    if (currentActiveStepIndex == 1) {
      this.orderOptions.newCustomer = null;
    }
  }

  public onCompanyChange(calledFrom, companyId) {
    this.addressesReady = false;
    //Update the available billing and shipping address options
    this.billingAddressOptions = [];
    this.shippingAddressOptions = [];
    this._companyService.getAddressesByCompanyId(companyId).subscribe(
      (res: Array<IAddress>) => {
        this.shippingAddressOptions = res.filter(
          (address) => address.type == AddressTypes.shipping
        );
        this.billingAddressOptions = res.filter(
          (address) => address.type == AddressTypes.billing
        );

        if (this.shippingAddressOptions.length == 0) {
          this.orderOptions.newShippingAddress = true;
        } else {
          this.orderOptions.newShippingAddress = false;
        }

        if (this.billingAddressOptions.length == 0) {
          this.orderOptions.newBillingAddress = true;
        } else {
          this.orderOptions.newBillingAddress = false;
        }
        this.addressesReady = true;
      },
      (error) => {
        console.log(
          "There was an error getting the addresses for the selected company."
        );
      }
    );
  }

  public toggleNewBillingAddress() {
    //Clear Prior Info from Order DTO
    delete this.order.BillingAddress;

    //Toggle Value
    this.orderOptions.newBillingAddress = !this.orderOptions.newBillingAddress;
  }

  public toggleNewShippingAddress() {
    //Clear Prior Info from Order DTO
    delete this.order.ShippingAddress;

    //Toggle Value
    this.orderOptions.newShippingAddress =
      !this.orderOptions.newShippingAddress;
  }

  public onShipDateSelect(dateObj) {
    //Ship date raw is bound to the datepicker
    //and is an object with year, month and day values
    //shipDate is the date formatted version we send to the server.

    //this change handler keeps these items in sync.
    this.order.shipDate = new Date(
      dateObj.year,
      dateObj.month - 1,
      dateObj.day
    );

    this.order.shipDate.setUTCHours(12);
  }

  public placeOrder() {
    this.order.OrderOptions = this.orderOptions;
    this.order.Cart = this.cart;

    this.isLoading = true;

    this._orderService.create(this.order).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.pctComplete = 100;
        if (res.orderId) {
          this.activeStep = "success";
          this._router.navigate(["orders", res.orderId]);
        } else {
          this.activeStep = "error";
          this._router.navigate(["orders"]);
        }
      },
      (error) => {
        console.log("error", error);
        this.pctComplete = 100;
        this.activeStep = "error";
        this._router.navigate(["orders"]);
      }
    );
  }

  public isDisabledDate(date) {
    let dateAsDate = new Date(date.year, date.month - 1, date.day);
    return dateAsDate.getDay() !== 1;
  }

  public onLiftGateChange(e) {
    if (e.target.checked) {
      this.order.liftGateCharge = this.liftGateCharge;
    } else {
      this.order.liftGateCharge = 0;
    }
  }
}
