<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [hidden]="!isLoading" style="text-align: center">
    <img src="assets/img/loading.svg" />
  </div>
  <form [hidden]="isLoading" class="form" [formGroup]="productForm">
    <div class="form-group">
      <label for="name">Name</label>
      <input
        ngbAutofocus
        required
        type="text"
        id="name"
        name="name"
        formControlName="name"
        class="form-control"
      />
      <div class="form-alert" *ngIf="name.touched && !name.valid">
        <div *ngIf="name.errors.required">Product name is required.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 form-group">
        <label for="category">Select Category</label>
        <select
          class="form-control"
          required
          name="category"
          id="category"
          formControlName="categoryId"
        >
          <option
            *ngFor="let category of categoryOptions"
            [ngValue]="category.id"
          >
            {{ category.name }}
          </option>
        </select>
        <div class="form-alert" *ngIf="categoryId.touched && !categoryId.valid">
          <div *ngIf="categoryId.errors.required">Category is required.</div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="upc">UPC</label>
        <input
          type="text"
          id="upc"
          name="upc"
          formControlName="upc"
          class="form-control"
        />
      </div>
      <div class="col-6 form-group">
        <label for="itemNo">Item #</label>
        <input
          required
          type="text"
          id="itemNo"
          name="itemNo"
          formControlName="itemNo"
          class="form-control"
        />
        <div class="form-alert" *ngIf="itemNo.touched && !itemNo.valid">
          <div *ngIf="itemNo.errors.required">Item # is required.</div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-3 switch-group">
        <label class="switch-label">Display for Sale</label><br />
        <ui-switch name="display" formControlName="display"></ui-switch>
      </div>
      <div class="col-3 switch-group">
        <label class="switch-label">Admin Only</label><br />
        <ui-switch name="display" formControlName="adminOnly"></ui-switch>
      </div>
      <div class="col-6 form-group">
        <label for="eachCost">Cost (ea.)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            required
            type="number"
            id="eachCost"
            name="eachCost"
            formControlName="eachCost"
            class="form-control"
          />
        </div>
        <div class="form-alert" *ngIf="eachCost.touched && !eachCost.valid">
          <div *ngIf="eachCost.errors.required">Cost (ea.) is required.</div>
          <div *ngIf="eachCost.errors.minValue">
            Cost (ea.) must be greater than
            {{ eachCost.errors.minValue.minAllowed }}.
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-5 switch-group">
        <label class="switch-label">Product can be Racked</label><br />
        <ui-switch
          name="rackable"
          formControlName="rackable"
          (change)="toggleRackable($event)"
        ></ui-switch>
      </div>
      <div class="col-7 form-group">
        <label for="qtyPerHalfShelf">Qty / Half Shelf</label>
        <input
          required
          type="number"
          id="qtyPerHalfShelf"
          name="qtyPerHalfShelf"
          formControlName="qtyPerHalfShelf"
          class="form-control"
        />
        <div
          class="form-alert"
          *ngIf="
            qtyPerHalfShelf.touched &&
            !qtyPerHalfShelf.valid &&
            !qtyPerHalfShelf.disabled
          "
        >
          <div *ngIf="qtyPerHalfShelf.errors.required">
            Qty per half shelf is required.
          </div>
          <div *ngIf="qtyPerHalfShelf.errors.min">
            Qty per half shelf must be greater than or equal to
            {{ qtyPerHalfShelf.errors.min.min }}.
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-5 switch-group">
        <label class="switch-label">Product can be Boxed</label><br />
        <ui-switch
          name="boxable"
          formControlName="boxable"
          (change)="toggleBoxable($event)"
        ></ui-switch>
      </div>
      <div class="col-4 form-group">
        <label for="box">Select Box</label>
        <select
          class="form-control"
          required
          name="box"
          id="box"
          formControlName="boxId"
        >
          <option *ngFor="let box of boxOptions" [ngValue]="box.id">
            {{ box.size }}
          </option>
        </select>
        <div
          class="form-alert"
          *ngIf="boxId.touched && !boxId.valid && !boxId.disabled"
        >
          <div *ngIf="boxId.errors.required">Box is required.</div>
        </div>
      </div>
      <div class="col-3 form-group">
        <label for="qtyPerBox">Qty / Box</label>
        <input
          required
          type="number"
          id="qtyPerBox"
          name="qtyPerBox"
          formControlName="qtyPerBox"
          class="form-control"
        />
        <div
          class="form-alert"
          *ngIf="qtyPerBox.touched && !qtyPerBox.valid && !qtyPerBox.disabled"
        >
          <div *ngIf="qtyPerBox.errors.required">Qty per box is required.</div>
          <div *ngIf="qtyPerBox.errors.min">
            Qty per box must be greater than or equal to
            {{ qtyPerBox.errors.min.min }}.
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col form-group">
        <label for="description">Description</label>
        <textarea
          rows="10"
          id="description"
          name="description"
          formControlName="description"
          class="form-control"
        ></textarea>
      </div>

      <div class="col">
        <div class="form-group">
          <label for="productImage">Product Image</label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            multiple="true"
            class="form-control-file"
            id="productImage"
            (change)="updateProductImage($event.target.files)"
          />
        </div>
        <img
          class="product-image"
          *ngIf="
            product.ProductImages ? product.ProductImages.length > 0 : false
          "
          src="{{ product.ProductImages[0].imageUrl }}"
          alt="Product-Image"
        />
        <p *ngIf="imageChanged">
          After you save, the new image will be available.
        </p>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Create'"
        class="btn btn-primary"
        (click)="add()"
        [disabled]="!productForm.valid"
      >
        Create Product
      </button>
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update()"
        [disabled]="!productForm.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete Product
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete: {{ product.upc }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to delete product "{{ product.name }}".</p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>
    <form #deleteForm="ngForm">
      <div class="form-group">
        <input type="number" hidden [ngModel]="product.id" name="id" id="id" />
        <button class="btn btn-primary" (click)="delete(deleteForm.value.id)">
          Confirm Delete
        </button>
        <button class="btn btn-default float-right" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
