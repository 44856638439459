import { AddressService } from "src/app/shared/services/address.service";
import { IsBetweenValidatorDirective } from "./validators/isbetween.directive";
import { CartService } from "./services/cart.service";
import { ImageService } from "./services/image.service";
import { BoxService } from "./services/box.service";
import { CategoryService } from "./services/category.service";
import { ProductService } from "./services/product.service";
import { CompanyService } from "./services/company.service";
import { ConfigService } from "./services/config.service";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RoleService } from "./services/role.service";
import { UserService } from "./services/user.service";
import { SelectionRequiredValidatorDirective } from "./validators/selection-required.directive";
import { SummaryPipe } from "./pipes/summary.pipe";
import { StreetOrPOBoxRequiredValidatorDirective } from "./validators/street-or-pobox-required.validation";
import { OrderService } from "./services/order.service";
import { OrderRequirementsComponent } from "./order-requirements/order-requirements.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ShortIdPipe } from "./pipes/shortId.pipe";
import { MinValueValidatorDirective } from "./validators/minvalue.directive";
import { EmailService } from "./services/email.service";
import { PrintPDFService } from "./services/print-pdf.service";
import { RackService } from "./services/rack.service";
import { HalfShelfService } from "./services/halfshelf.service";
import { ReportService } from "./services/report.service";
import { BoxSkidService } from "./services/boxskid.service";

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [
    SelectionRequiredValidatorDirective,
    StreetOrPOBoxRequiredValidatorDirective,
    MinValueValidatorDirective,
    IsBetweenValidatorDirective,
    SummaryPipe,
    ShortIdPipe,
    OrderRequirementsComponent,
  ],
  providers: [
    UserService,
    RoleService,
    CompanyService,
    ProductService,
    RackService,
    HalfShelfService,
    BoxSkidService,
    ImageService,
    CategoryService,
    BoxService,
    CartService,
    OrderService,
    ConfigService,
    AddressService,
    EmailService,
    PrintPDFService,
    ReportService,
  ],
  exports: [
    SelectionRequiredValidatorDirective,
    StreetOrPOBoxRequiredValidatorDirective,
    MinValueValidatorDirective,
    IsBetweenValidatorDirective,
    SummaryPipe,
    ShortIdPipe,
    OrderRequirementsComponent,
  ],
})
export class SharedModule {}
