import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class CanActivateViaCustomerAuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private _router: Router) {

    }

    canActivate() {
        //Reroute people who are not logged in / token has expired.
        if (!this._authService.loggedIn()) {
            this._router.navigateByUrl('/login');
            return false;
        }

        //Reroute people whose account is disabled.
        if (this._authService.isDisabled()) {
            this._router.navigateByUrl('/disabled');
            return false;
        }

        if (this._authService.isCustomer()) {
            return true;
        } else {
            //Not authorized to view this page.
            this._router.navigateByUrl('/unauthorized');
            return false;
        }
    }
}
