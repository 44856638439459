import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";

@Injectable()
export class ReportService extends DataService {
  constructor(http: HttpClient) {
    super("reports", http);
  }

  public getOrdersNotPicked(fromDate, toDate) {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/not-picked-details/" + fromDate + "/" + toDate)
      .pipe(catchError(this.handleError));
  }

  public getOrdersNotPickedGrouped(fromDate, toDate) {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/not-picked-grouped/" + fromDate + "/" + toDate)
      .pipe(catchError(this.handleError));
  }

  public getOrdersNotInvoiced() {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/not-invoiced-details")
      .pipe(catchError(this.handleError));
  }
}
