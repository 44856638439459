import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import { AuthService } from './../../services/auth.service';

@Component({
    selector: 'reset-request-form',
    templateUrl: './reset-request.component.html',
    styleUrls: ['./reset-request.component.css']
})
 
export class ResetRequestComponent implements OnInit {
 
    message: string;
    title: string = "Popes Plant Farm Orders";

    constructor(private _authService:AuthService) {}
 
    ngOnInit(){
        
    }

    requestResetEmail(email: string){
        this._authService.requestResetEmail(email)
            .subscribe(()=> {this.message = "Password Reset Email Sent."})
    }

}