import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http'

import { catchError } from 'rxjs/operators';

@Injectable()
export class CompanyService extends DataService {

  constructor(http: HttpClient) {
    super('companies', http)
  }

  public getAddressesByCompanyId(companyId) {
    return this.getHttp().get(this.getApiUrl() + '/addresses/' + companyId)
      .pipe(catchError(super.handleError));
  }

}
