<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <p>
      <ngb-progressbar
        type="primary"
        [value]="pctComplete"
        [striped]="true"
        [animated]="true"
        ><i></i
      ></ngb-progressbar>
    </p>

    <!-- Customer Type -->
    <ng-container *ngIf="activeStep == 'selectCustomerType'">
      <div class="row">
        <div class="col-12 form-group">
          <h5>Is this order for a new or existing customer?</h5>
          <button
            type="button"
            class="btn btn-primary btn-new-customer"
            (click)="updateCustomerType('new')"
          >
            New
          </button>
          <button
            type="button"
            class="btn btn-primary btn-existing-customer"
            (click)="updateCustomerType('existing')"
          >
            Existing
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Customer Selection -->
    <ng-container *ngIf="activeStep == 'selectCustomer'">
      <!-- If Existing Customer -->
      <div class="row">
        <div class="col-12">
          <h5>Who's the Order For?</h5>
        </div>
      </div>
      <div class="row" *ngIf="!orderOptions.newCustomer">
        <div class="col-12 form-group">
          <label for="companyId" class="sr-only">Select Company</label>
          <select
            selectionRequired
            class="form-control"
            required
            name="companyId"
            id="companyId"
            [ngModel]="order?.Company?.id || -1"
            #companyId="ngModel"
            (change)="onCompanyChange('existing', form.value.companyId)"
          >
            <option value="-1">Select Company</option>
            <option
              *ngFor="let company of companyOptions"
              [ngValue]="company.id"
            >
              {{ company.name }}
            </option>
          </select>
          <div class="form-alert" *ngIf="companyId.touched && !companyId.valid">
            <div *ngIf="companyId.errors.selectionRequired">
              Please select a company.
            </div>
          </div>
        </div>
      </div>
      <!-- If New Customer -->
      <div
        class="row"
        *ngIf="orderOptions.newCustomer"
        ngModelGroup="Company"
        #Company="ngModelGroup"
      >
        <div class="col-12 form-group">
          <label for="name" class="sr-only">Enter Company Name</label>
          <input
            ngbAutofocus
            required
            minlength="3"
            type="text"
            class="form-control"
            placeholder="Enter Company Name"
            id="name"
            name="name"
            [ngModel]="order?.Company?.name"
            #name="ngModel"
          />
          <div class="form-alert" *ngIf="name.touched && !name.valid">
            <div *ngIf="name.errors.required">Company name is required.</div>
            <div *ngIf="name.errors.minlength">
              Company name has to contain at least 3 characters.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="customerDiscount">Customer Discount</label>
          <input
            type="number"
            class="form-control"
            placeholder="Customer Discount %"
            id="customerDiscount"
            name="customerDiscount"
            [ngModel]="order?.Company?.discount"
            #customerDiscount="ngModel"
          />
        </div>
        <div class="col-12 form-group">
          <label for="notes">Notes</label>
          <textarea
            rows="5"
            id="notes"
            name="notes"
            [ngModel]="order?.Company?.notes"
            #notes="ngModel"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </ng-container>

    <!-- Billing Address -->
    <ng-container
      *ngIf="activeStep == 'selectBillingAddress' && addressesReady"
    >
      <!-- Use an Existing Billing Address -->
      <div
        class="row"
        *ngIf="
          billingAddressOptions.length > 0 && !orderOptions.newBillingAddress
        "
      >
        <div class="col-12">
          <h5>Billing Address</h5>
        </div>
        <div class="col-12 form-group">
          <label for="billingAddressId" class="sr-only"
            >Select Billing Address</label
          >
          <select
            selectionRequired
            class="form-control"
            required
            name="billingAddressId"
            id="billingAddressId"
            [ngModel]="order?.BillingAddress?.id || -1"
            #billingAddressId="ngModel"
          >
            <option value="-1">Select Billing Address</option>
            <option
              *ngFor="let billingAddress of billingAddressOptions"
              [ngValue]="billingAddress.id"
            >
              {{ billingAddress.street1 }} {{ billingAddress.street2 }}
              {{ billingAddress.poBox }}, {{ billingAddress.city }},
              {{ billingAddress.state }} {{ billingAddress.zip }}
            </option>
          </select>
          <div
            class="form-alert"
            *ngIf="billingAddressId.touched && !billingAddressId.valid"
          >
            <div *ngIf="billingAddressId.errors">Selection is required.</div>
          </div>
          <div class="col-12 form-sub-action-link-container">
            <a (click)="toggleNewBillingAddress()">
              Create New Billing Address
            </a>
          </div>
        </div>
      </div>
      <!-- Create a New Address -->
      <div
        class="row"
        *ngIf="
          billingAddressOptions.length == 0 || orderOptions.newBillingAddress
        "
        ngModelGroup="BillingAddress"
        #BillingAddress="ngModelGroup"
        streetOrPOBoxRequired
      >
        <div class="col-12">
          <h5>Create Billing Address</h5>
        </div>
        <div class="col-12 form-group">
          <label for="street1">Enter Street Address</label>
          <input
            type="text"
            class="form-control"
            placeholder="Street Address"
            id="street1"
            name="street1"
            [ngModel]="order?.BillingAddress?.street1"
            #street1="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="street1.touched && poBox.touched && BillingAddress.errors"
          >
            <div *ngIf="BillingAddress.errors.streetOrPOBoxRequired">
              Either the Street Address or PO Box need to be supplied.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="street2" class="sr-only">Enter Street 2</label>
          <input
            type="text"
            class="form-control"
            placeholder="Steet Address 2"
            id="street2"
            name="street2"
            [ngModel]="order?.BillingAddress?.street2"
          />
        </div>
        <div class="col-12 form-group">
          <label for="poBox">PO Box</label>
          <input
            type="text"
            class="form-control"
            placeholder="PO Box"
            id="poBox"
            name="poBox"
            [ngModel]="order?.BillingAddress?.poBox"
            #poBox="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="street1.touched && poBox.touched && BillingAddress.errors"
          >
            <div *ngIf="BillingAddress.errors.streetOrPOBoxRequired">
              Either the Street Address or PO Box need to be supplied.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="city">City</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="City"
            id="city"
            name="city"
            [ngModel]="order?.BillingAddress?.city"
            #city="ngModel"
          />
          <div class="form-alert" *ngIf="city.touched && !city.valid">
            <div *ngIf="city.errors.required">City is required.</div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="state">State</label>
          <select
            selectionRequired
            class="form-control"
            required
            name="state"
            id="state"
            [ngModel]="order?.BillingAddress?.state || -1"
            #state="ngModel"
          >
            <option value="-1">Select State</option>
            <option *ngFor="let state of stateOptions" [ngValue]="state">
              {{ state }}
            </option>
          </select>
          <div class="form-alert" *ngIf="state.touched && !state.valid">
            <div *ngIf="state.errors.selectionRequired">State is required.</div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="zip">Zip Code</label>
          <input
            required
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            type="text"
            class="form-control"
            placeholder="Zip Code"
            id="zip"
            name="zip"
            [ngModel]="order?.BillingAddress?.zip"
            #zip="ngModel"
          />
          <div class="form-alert" *ngIf="zip.touched && !zip.valid">
            <div *ngIf="zip.errors.required">Zip Code is required.</div>
            <div *ngIf="zip.errors.pattern">
              Please enter a valid zip code in the 12345 or the 12345-6789
              format.
            </div>
          </div>
        </div>
        <div
          class="col-12 form-sub-action-link-container"
          *ngIf="billingAddressOptions.length > 0"
        >
          <a (click)="toggleNewBillingAddress()">
            Pick from Existing Billing Addresses
          </a>
        </div>
      </div>
    </ng-container>

    <!-- Billing Contact -->
    <ng-container *ngIf="activeStep == 'enterBillingContact'">
      <div class="row" ngModelGroup="BillingContact">
        <div class="col-12">
          <h5>Order Billing Contact</h5>
        </div>
        <div class="col-6 form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            id="name"
            name="name"
            [ngModel]="order?.BillingAddress?.contactName"
          />
        </div>
        <div class="col-6 form-group">
          <label for="phone">Phone</label>
          <input
            type="text"
            class="form-control"
            placeholder="Phone"
            id="phone"
            name="phone"
            [ngModel]="order?.BillingAddress?.contactPhone"
          />
        </div>
        <div class="col-12 form-group">
          <label for="email">Email</label>
          <input
            type="email"
            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            class="form-control"
            placeholder="Email"
            id="email"
            name="email"
            [ngModel]="order?.BillingAddress?.contactEmail"
            #billingContactEmail="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="billingContactEmail.touched && !billingContactEmail.valid"
          >
            <div *ngIf="billingContactEmail.errors.pattern">
              Please enter a valid email.
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Shipping Address -->
    <ng-container
      *ngIf="activeStep == 'selectShippingAddress' && addressesReady"
    >
      <!-- Use an Existing Address -->
      <div
        class="row"
        *ngIf="
          shippingAddressOptions.length > 0 && !orderOptions.newShippingAddress
        "
      >
        <div class="col-12">
          <h5>Shipping Address</h5>
        </div>
        <div class="col-12 form-group">
          <label for="shippingAddressId" class="sr-only"
            >Select Shipping Address</label
          >
          <select
            class="form-control"
            selectionRequired
            name="shippingAddressId"
            id="shippingAddressId"
            [ngModel]="order?.ShippingAddress?.id || -1"
          >
            <option value="-1">Select Shipping Address</option>
            <option
              *ngFor="let shippingAddress of shippingAddressOptions"
              [ngValue]="shippingAddress.id"
            >
              {{
                shippingAddress.companyName
                  ? "(" + shippingAddress.companyName + ") "
                  : ""
              }}{{ shippingAddress.street1 }} {{ shippingAddress.street2 }},
              {{ shippingAddress.city }}, {{ shippingAddress.state }}
              {{ shippingAddress.zip }}
            </option>
          </select>
          <div class="col-12 form-sub-action-link-container">
            <a (click)="toggleNewShippingAddress()">
              Create New Shipping Address
            </a>
          </div>
        </div>
      </div>
      <!-- Create a New Address -->
      <div
        class="row"
        *ngIf="
          shippingAddressOptions.length == 0 || orderOptions.newShippingAddress
        "
        ngModelGroup="ShippingAddress"
        #ShippingAddress="ngModelGroup"
      >
        <div class="col-12">
          <h5>Create Shipping Address</h5>
        </div>
        <div class="col-12 form-group">
          <label for="companyName">Company Name</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="Enter Company Name"
            id="companyName"
            name="companyName"
            [ngModel]="order?.ShippingAddress?.companyName"
            #companyName="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="companyName.touched && !companyName.valid"
          >
            <div *ngIf="companyName.errors.required">
              Company Name Required.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="street1">Enter Street Adress</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="Street Address"
            id="street1"
            name="street1"
            [ngModel]="order?.ShippingAddress?.street1"
            #street1="ngModel"
          />
          <div class="form-alert" *ngIf="street1.touched && !street1.valid">
            <div *ngIf="street1.errors.required">Street Address Required.</div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="street2" class="sr-only">Enter Street 2</label>
          <input
            type="text"
            class="form-control"
            placeholder="Steet Address 2"
            id="street2"
            name="street2"
            [ngModel]="order?.ShippingAddress?.street2"
          />
        </div>
        <div class="col-12 form-group">
          <label for="city">City</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="City"
            id="city"
            name="city"
            [ngModel]="order?.ShippingAddress?.city"
            #city="ngModel"
          />
          <div class="form-alert" *ngIf="city.touched && !city.valid">
            <div *ngIf="city.errors.required">City is required.</div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="state">State</label>
          <select
            class="form-control"
            selectionRequired
            name="state"
            id="state"
            [ngModel]="order?.ShippingAddress?.state || -1"
            #state="ngModel"
          >
            <option value="-1">Select State</option>
            <option *ngFor="let state of stateOptions" [ngValue]="state">
              {{ state }}
            </option>
          </select>
          <div class="form-alert" *ngIf="state.touched && !state.valid">
            <div *ngIf="state.errors.selectionRequired">State is required.</div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="zip">Zip Code</label>
          <input
            required
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            type="text"
            class="form-control"
            placeholder="Zip Code"
            id="zip"
            name="zip"
            [ngModel]="order?.ShippingAddress?.zip"
            #zip="ngModel"
          />
          <div class="form-alert" *ngIf="zip.touched && !zip.valid">
            <div *ngIf="zip.errors.required">Zip Code is required.</div>
            <div *ngIf="zip.errors.pattern">
              Please enter a valid zip code in the 12345 or the 12345-6789
              format.
            </div>
          </div>
        </div>
        <div *ngIf="isAdmin()" class="col-6 form-group">
          <label for="shippingRate">Shipping Rate (%)</label>
          <input
            required
            isBetween="0:100"
            type="number"
            class="form-control"
            placeholder="Shipping Rate %"
            id="shippingRate"
            name="shippingRate"
            [ngModel]="order?.ShippingAddress?.shippingRate"
            #shippingRate="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="shippingRate.touched && !shippingRate.valid"
          >
            <div *ngIf="shippingRate.errors.required">
              Shipping rate is required.
            </div>
            <div *ngIf="shippingRate.errors.isBetween">
              Shipping rate should be a number between
              {{ shippingRate.errors.isBetween.minAllowed }} and
              {{ shippingRate.errors.isBetween.maxAllowed }}.
            </div>
          </div>
        </div>
        <div
          class="col-12 form-sub-action-link-container"
          *ngIf="shippingAddressOptions.length > 0"
        >
          <a (click)="toggleNewShippingAddress()">
            Pick from Existing Shipping Addresses
          </a>
        </div>
      </div>
    </ng-container>

    <!-- Shipping Contact -->
    <ng-container *ngIf="activeStep == 'enterShippingContact'">
      <div class="row" ngModelGroup="ShippingContact">
        <div class="col-12">
          <h5>Order Shipping Contact</h5>
        </div>
        <div class="col-6 form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            id="name"
            name="name"
            [ngModel]="order?.ShippingAddress?.contactName"
          />
        </div>
        <div class="col-6 form-group">
          <label for="phone">Phone</label>
          <input
            type="text"
            class="form-control"
            placeholder="Phone"
            id="phone"
            name="phone"
            [ngModel]="order?.ShippingAddress?.contactPhone"
          />
        </div>
        <div class="col-12 form-group">
          <label for="email">Email</label>
          <input
            type="email"
            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            class="form-control"
            placeholder="Email"
            id="email"
            name="email"
            [ngModel]="order?.ShippingAddress?.contactEmail"
            #shippingContactEmail="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="shippingContactEmail.touched && !shippingContactEmail.valid"
          >
            <div *ngIf="shippingContactEmail.errors.pattern">
              Please enter a valid email.
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Ship Date -->
    <ng-container *ngIf="activeStep == 'selectShipDate'">
      <div class="row">
        <div class="col-12">
          <h5>When would you like this order to be shipped?</h5>
        </div>
        <div class="col-6 form-group">
          <ngb-datepicker
            required
            #d
            id="shipDate"
            name="shipDateRaw"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [markDisabled]="isDisabledDate"
            [startDate]="minDate"
            [ngModel]="order?.shipDateRaw"
            (select)="onShipDateSelect($event)"
          ></ngb-datepicker>
        </div>
        <div class="col-6">
          <p class="alert alert-info shipping-note">
            <strong>Note</strong>: Orders are shipped out once a week, typically
            on Monday. Orders placed before Friday can be included in the
            following week's shipments.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeStep == 'needLiftGate'">
      <div class="row">
        <div class="col-12">
          <h5>Will this Order Need Liftgate Service?</h5>
        </div>
        <div class="form-group col-12">
          <div class="col-12 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="liftGateRequired"
              name="liftGateRequired"
              [checked]="order?.liftGateCharge > 0"
              (change)="onLiftGateChange($event)"
            />
            <label class="form-check-label" for="liftGateRequired"
              >Liftgate Required</label
            >
          </div>
        </div>
        <div class="col-12">
          <p class="alert alert-info shipping-note">
            <strong>Note</strong>: Liftgate services are required if you do not
            have a forklift on site.
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isAdmin() && activeStep == 'orderDiscount'">
      <div class="row">
        <div class="col-12">
          <h5>
            Does the order have a special Discount in addition to the Customer's
            discount?
          </h5>
        </div>

        <div class="col-12 form-group">
          <label for="orderDiscount">Order Discount Rate (%)</label>
          <input
            required
            isBetween="0:100"
            type="number"
            class="form-control"
            placeholder="%"
            id="orderDiscount"
            name="orderDiscount"
            [ngModel]="order?.orderDiscount"
            #orderDiscount="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="orderDiscount.touched && !orderDiscount.valid"
          >
            <div *ngIf="orderDiscount.errors.required">
              Order Discount rate is required. Enter 0, if an order discount
              should not be applied.
            </div>
            <div *ngIf="shippingRate.errors.isBetween">
              Order Discount rate should be a number between
              {{ orderDiscount.errors.isBetween.minAllowed }} and
              {{ orderDiscount.errors.isBetween.maxAllowed }}.
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isAdmin() && activeStep == 'orderNote'">
      <div class="col-12 form-group">
        <label for="notes">Order Note (this is public)</label>
        <textarea
          rows="5"
          id="note"
          name="note"
          [ngModel]="order?.note"
          #note="ngModel"
          class="form-control"
        ></textarea>
      </div>
    </ng-container>

    <!-- Review Order Totals -->
    <ng-container *ngIf="activeStep == 'reviewAndSubmit'">
      <div class="row">
        <div class="col-12">
          <h5>Review & Submit</h5>
        </div>
        <div class="col-6">
          <div class="address-info-container">
            <span><strong>Bill-To</strong></span>
            <span *ngIf="!order.BillingAddress">TBD</span>
            <ng-container *ngIf="order.BillingAddress">
              <span>{{ order.Company.name }}</span>
              <span *ngIf="order.BillingAddress.street1">{{
                order.BillingAddress.street1
              }}</span>
              <span *ngIf="order.BillingAddress.street2">{{
                order.BillingAddress.street2
              }}</span>
              <span *ngIf="order.BillingAddress.poBox"
                >PO Box {{ order.BillingAddress.poBox }}</span
              >
              <span
                >{{ order.BillingAddress.city }},
                {{ order.BillingAddress.state }}
                {{ order.BillingAddress.zip }}</span
              >
            </ng-container>
          </div>
        </div>
        <div class="col-6">
          <div class="address-info-container">
            <span><strong>Ship-To</strong></span>
            <span *ngIf="!order.ShippingAddress">TBD</span>
            <ng-container *ngIf="order.ShippingAddress">
              <span>{{ order.Company.name }}</span>
              <span>{{ order.ShippingAddress.street1 }}</span>
              <span *ngIf="order.ShippingAddress.street2">{{
                order.ShippingAddress.street2
              }}</span>
              <span
                >{{ order.ShippingAddress.city }},
                {{ order.ShippingAddress.state }}
                {{ order.ShippingAddress.zip }}</span
              >
            </ng-container>
          </div>
        </div>
        <div class="col-12">
          <hr />
          <table class="table table-borderless table-sm">
            <tr>
              <th>Order Summary</th>
              <td>
                <span *ngIf="cart.rackQtyTotal === 1">1 Rack </span>
                <span *ngIf="cart.rackQtyTotal > 1"
                  >{{ cart.rackQtyTotal }} Racks
                </span>
                <span *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt == 1"
                  >{{ cart.totalNumOfBoxesFromRegularAndPrebuilt }} Box
                </span>
                <span *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt > 1"
                  >{{ cart.totalNumOfBoxesFromRegularAndPrebuilt }} Boxes
                </span>
              </td>
            </tr>
            <tr>
              <th>Product Total</th>
              <td>
                {{ cart.productTotal | currency : "USD" : "symbol" : "1.2-2" }}
              </td>
            </tr>
            <tr>
              <th>Shipping Total</th>
              <td
                *ngIf="
                  order.ShippingAddress
                    ? order.ShippingAddress.shippingRate != null
                    : false
                "
              >
                {{
                  (cart.productTotal * order.ShippingAddress.shippingRate) / 100
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td
                *ngIf="
                  order.ShippingAddress
                    ? order.ShippingAddress.shippingRate == null
                    : true
                "
              >
                Shipping could not be calculated at this time. Once you place
                your order, we'll review it and reach out to you with a revised
                order summary.
              </td>
            </tr>
            <tr *ngIf="order.liftGateCharge > 0">
              <th>Liftgate Service</th>
              <td>
                {{
                  order.liftGateCharge | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="order.Company.discount > 0">
              <th>Customer Discount</th>
              <td>
                ({{
                  (cart.productTotal * order.Company.discount) / 100
                    | currency : "USD" : "symbol" : "1.2-2"
                }})
              </td>
            </tr>
            <tr *ngIf="order.orderDiscount > 0">
              <th>Order Discount</th>
              <td>
                ({{
                  (cart.productTotal * order.orderDiscount) / 100
                    | currency : "USD" : "symbol" : "1.2-2"
                }})
              </td>
            </tr>
            <tr class="last-row">
              <th>Order Total</th>
              <th
                *ngIf="
                  order.ShippingAddress
                    ? order.ShippingAddress.shippingRate != null
                    : false
                "
              >
                {{
                  cart.productTotal *
                    (1 + order.ShippingAddress.shippingRate / 100) +
                    order.liftGateCharge -
                    (cart.productTotal * order.Company.discount) / 100 -
                    (cart.productTotal * order.orderDiscount) / 100
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </th>
              <th
                *ngIf="
                  order.ShippingAddress
                    ? order.ShippingAddress.shippingRate == null
                    : true
                "
              >
                TBD
              </th>
            </tr>
          </table>
        </div>
      </div>
    </ng-container>

    <!-- Success  -->
    <ng-container *ngIf="activeStep == 'success'">
      <div class="row">
        <div class="col-12">
          <h4>Thank you for your order.</h4>
          <p>Your order has been successfully placed.</p>
        </div>
      </div>
    </ng-container>

    <!-- Error  -->
    <ng-container *ngIf="activeStep == 'error'">
      <div class="row">
        <div class="col-12 alert alert-danger">
          <h4>There was an error placing your order.</h4>
          <p>
            We encountered an error taking your order. Please reach out to the
            Pope's Plant Farm and let them know you received this message.
          </p>
        </div>
      </div>
    </ng-container>

    <!-- Loading Placeholder -->
    <div style="text-align: center" *ngIf="activeStep == 'loading'">
      <img src="assets/img/loading.svg" alt="loading" />
    </div>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        *ngIf="
          activeStep != 'reviewAndSubmit' &&
          activeStep != 'success' &&
          activeStep != 'error'
        "
        type="button"
        class="btn btn-primary"
        (click)="moveToNextStep(form)"
        [disabled]="!form.valid"
      >
        Next
      </button>

      <button
        *ngIf="activeStep == 'reviewAndSubmit'"
        type="button"
        class="btn btn-primary"
        (click)="placeOrder()"
        [disabled]="!form.valid"
      >
        Place Order
      </button>

      <button
        *ngIf="
          isAdmin() &&
          (activeStep == 'selectBillingAddress' ||
            activeStep == 'selectShippingAddress')
        "
        type="button"
        class="btn btn-danger"
        (click)="skipStep(form)"
      >
        Skip
      </button>

      <button
        *ngIf="
          orderSteps[0] != activeStep &&
          activeStep != 'success' &&
          activeStep != 'error'
        "
        type="button"
        class="btn btn-default"
        (click)="moveToPriorStep(form)"
      >
        Back
      </button>
      <button
        *ngIf="activeStep == 'success'"
        class="btn btn-primary"
        (click)="activeModal.close()"
      >
        View Order Confirmation
      </button>
    </div>
  </form>
</div>
