import { CartService } from "../shared/services/cart.service";
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { NotFoundError } from "../shared/errors/not-found-error";
import { IProduct } from "../shared/interfaces/product.interface";
import { orderQtyRequiredValidator } from "../shared/validators/order-qty-required.validator";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.css"],
})
export class ProductCardComponent implements OnInit {
  constructor(private _cartService: CartService) {}

  @Input() product: IProduct;
  @Input() boxesOrdered: number;
  @Input() halfShelvesOrdered: number;
  @Input() comments: string;
  @Input() fileServerUrl: string;
  @Output() addItemToCart = new EventEmitter<any>();
  @Output() updateItemInCart = new EventEmitter<any>();
  @Output() removeItemFromCart = new EventEmitter<any>();

  public inlineOrderForm;
  public inCart: boolean;
  public tmpBoxesOrdered;
  public tmpHalfShelvesOrdered;

  ngOnInit() {
    this.tmpBoxesOrdered = this.boxesOrdered;
    this.tmpHalfShelvesOrdered = this.halfShelvesOrdered;

    this.inlineOrderForm = new FormGroup(
      {
        productId: new FormControl(this.product.id, [Validators.required]),
        boxesOrdered: new FormControl(this.boxesOrdered, [Validators.min(0)]),
        halfShelvesOrdered: new FormControl(this.halfShelvesOrdered, [
          Validators.min(0),
        ]),
        comments: new FormControl(this.comments),
      },
      { validators: orderQtyRequiredValidator }
    );

    if (this.halfShelvesOrdered > 0 || this.boxesOrdered > 0) {
      this.inCart = true;
    } else {
      this.inCart = false;
    }
  }

  public addItem(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.addItemToCart(data).subscribe(
      (res: any) => {
        this.boxesOrdered = res.CartProduct.boxQty;
        this.halfShelvesOrdered = res.CartProduct.halfShelfQty;
        this.comments = res.CartProduct.comments;

        let addItemResponse = {
          type: "success",
          product: {
            CartProduct: res.CartProduct,
            id: this.product.id,
            eachCost: this.product.eachCost,
            qtyPerBox: this.product.qtyPerBox,
            qtyPerHalfShelf: this.product.qtyPerHalfShelf,
          },
        };
        this.inCart = true;
        this.addItemToCart.emit(addItemResponse);
      },
      (error) => {
        this.boxesOrdered = 0;
        this.halfShelvesOrdered = 0;
        this.comments = "";

        let addItemResponse = {
          type: "error",
          product: this.product,
          alert: {
            type: "danger",
            title: "Item not added to cart.",
            msg: error.originalError.errors[0].description,
          },
        };
        this.inCart = false;
        this.addItemToCart.emit(addItemResponse);
      }
    );
  }

  public updateItem(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.updateItemInCart(data).subscribe(
      (res: any) => {
        this.boxesOrdered = res.CartProduct.boxQty;
        this.halfShelvesOrdered = res.CartProduct.halfShelfQty;
        this.comments = res.CartProduct.comments;

        let updateItemResponse = {
          type: "success",
          product: {
            CartProduct: res.CartProduct,
            id: this.product.id,
            eachCost: this.product.eachCost,
            qtyPerBox: this.product.qtyPerBox,
            qtyPerHalfShelf: this.product.qtyPerHalfShelf,
          },
        };
        this.inCart = true;
        this.updateItemInCart.emit(updateItemResponse);
      },
      (error) => {
        let updateItemResponse = {
          type: "error",
          product: this.product,
          alert: {
            type: "danger",
            title: "Cart could not be updated.",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.inCart = true;
        this.updateItemInCart.emit(updateItemResponse);
      }
    );
  }

  public removeItem(productId, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.removeItemFromCart(productId).subscribe(
      (res: any) => {
        this.boxesOrdered = 0;
        this.halfShelvesOrdered = 0;
        let removeItemResponse = {
          type: "success",
          productId: this.product.id,
          alert: {
            type: "success",
            title: "Item Removed From Cart.",
            msg: "",
          },
        };
        this.inCart = false;
        this.inlineOrderForm.controls["boxesOrdered"].setValue(0);
        this.inlineOrderForm.controls["halfShelvesOrdered"].setValue(0);
        this.removeItemFromCart.emit(removeItemResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let removeItemResponse = {
            type: "success",
            productId: this.product.id,
            alert: {
              type: "success",
              title: "Item Removed From Cart.",
              msg: "",
            },
          };
          this.removeItemFromCart.emit(removeItemResponse);
        } else {
          let removeItemResponse = {
            type: "error",
            productId: this.product.id,
            alert: {
              type: "danger",
              title: "Item Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.inCart = true;
          this.removeItemFromCart.emit(removeItemResponse);
        }
      }
    );
  }

  public getBoxCost(product) {
    return product.eachCost * product.qtyPerBox;
  }

  public getHalfShelfCost(product) {
    return product.eachCost * product.qtyPerHalfShelf;
  }

  public getBoxTotal(product) {
    return product.eachCost * product.qtyPerBox * this.tmpBoxesOrdered;
  }

  public getHalfShelfTotal(product) {
    return (
      product.eachCost * product.qtyPerHalfShelf * this.tmpHalfShelvesOrdered
    );
  }

  public getBoxIndividualUnits(product) {
    return product.qtyPerBox * this.tmpBoxesOrdered;
  }

  public getHalfShelfIndividualUnits(product) {
    return product.qtyPerHalfShelf * this.tmpHalfShelvesOrdered;
  }

  public getProductTotal(product) {
    return this.getBoxTotal(product) + this.getHalfShelfTotal(product);
  }

  public updateBoxesOrderedCalcs() {
    let enteredValue = this.inlineOrderForm.get("boxesOrdered").value;

    if (enteredValue >= 0) {
      this.tmpBoxesOrdered = enteredValue;
    }
  }

  public updateHalfShelvesOrderedCalcs() {
    let enteredValue = this.inlineOrderForm.get("halfShelvesOrdered").value;
    if (enteredValue >= 0) {
      this.tmpHalfShelvesOrdered = enteredValue;
    }
  }

  public resetValues() {
    this.inlineOrderForm.controls["boxesOrdered"].setValue(this.boxesOrdered);
    this.inlineOrderForm.controls["halfShelvesOrdered"].setValue(
      this.halfShelvesOrdered
    );
    this.inlineOrderForm.controls["comments"].setValue(this.comments);
    this.tmpBoxesOrdered = this.boxesOrdered;
    this.tmpHalfShelvesOrdered = this.halfShelvesOrdered;
  }
}
