import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotFoundError } from "../shared/errors/not-found-error";
import { IBoxSkid } from "../shared/interfaces/boxskid.interface";
import { CartService } from "../shared/services/cart.service";

@Component({
  selector: "app-boxskid-card",
  templateUrl: "./boxskid-card.component.html",
  styleUrls: ["./boxskid-card.component.css"],
})
export class BoxSkidCardComponent implements OnInit {
  constructor(private _cartService: CartService) {}

  @Input() boxSkid: IBoxSkid;
  @Input() boxSkidsOrdered: number;
  @Input() comments: string;
  @Input() fileServerUrl: string;
  @Output() addBoxSkidToCart = new EventEmitter<any>();
  @Output() updateBoxSkidInCart = new EventEmitter<any>();
  @Output() removeBoxSkidFromCart = new EventEmitter<any>();

  public inlineOrderForm;
  public inCart: boolean;
  public tmpBoxSkidsOrdered;

  ngOnInit() {
    this.tmpBoxSkidsOrdered = this.boxSkidsOrdered;

    this.inlineOrderForm = new FormGroup({
      boxSkidId: new FormControl(this.boxSkid.id, [Validators.required]),
      boxSkidsOrdered: new FormControl(this.boxSkidsOrdered, [
        Validators.min(0),
      ]),
      comments: new FormControl(this.comments),
    });

    if (this.boxSkidsOrdered > 0) {
      this.inCart = true;
    } else {
      this.inCart = false;
    }
  }

  public addBoxSkid(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.addBoxSkidToCart(data).subscribe(
      (res: any) => {
        this.boxSkidsOrdered = res.CartBoxSkid.boxSkidQty;
        this.comments = res.CartBoxSkid.comments;

        let addBoxSkidResponse = {
          type: "success",
          boxSkid: res.CartBoxSkid,
        };
        this.inCart = true;
        this.addBoxSkidToCart.emit(addBoxSkidResponse);
      },
      (error) => {
        this.boxSkidsOrdered = 0;
        this.comments = "";

        let addBoxSkidResponse = {
          type: "error",
          boxSkid: this.boxSkid,
          alert: {
            type: "danger",
            title: "Box Skid not added to cart.",
            msg: error.originalError.errors[0].description,
          },
        };
        this.inCart = false;
        this.addBoxSkidToCart.emit(addBoxSkidResponse);
      }
    );
  }

  public updateBoxSkid(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.updateBoxSkidInCart(data).subscribe(
      (res: any) => {
        this.boxSkidsOrdered = res.CartBoxSkid.boxSkidQty;
        this.comments = res.CartBoxSkid.comments;

        let updateBoxSkidResponse = {
          type: "success",
          boxSkid: res.CartBoxSkid,
        };
        this.inCart = true;
        this.updateBoxSkidInCart.emit(updateBoxSkidResponse);
      },
      (error) => {
        let updateBoxSkidResponse = {
          type: "error",
          boxSkid: this.boxSkid,
          alert: {
            type: "danger",
            title: "Box Skid could not be updated.",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.updateBoxSkidInCart.emit(updateBoxSkidResponse);
      }
    );
  }

  public removeBoxSkid(boxSkidId, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.removeBoxSkidFromCart(boxSkidId).subscribe(
      (res: any) => {
        this.boxSkidsOrdered = 0;
        let removeBoxSkidResponse = {
          type: "success",
          boxSkidId: this.boxSkid.id,
          alert: {
            type: "success",
            title: "Box Skid Removed From Cart.",
            msg: "",
          },
        };
        this.inCart = false;
        this.inlineOrderForm.controls["boxSkidsOrdered"].setValue(0);
        this.removeBoxSkidFromCart.emit(removeBoxSkidResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let removeBoxSkidResponse = {
            type: "success",
            boxSkidId: this.boxSkid.id,
            alert: {
              type: "success",
              title: "Box Skid Removed From Cart.",
              msg: "",
            },
          };
          this.removeBoxSkidFromCart.emit(removeBoxSkidResponse);
        } else {
          let removeItemResponse = {
            type: "error",
            boxSkidId: this.boxSkid.id,
            alert: {
              type: "danger",
              title: "Box Skid Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.inCart = true;
          this.removeBoxSkidFromCart.emit(removeItemResponse);
        }
      }
    );
  }

  public getBoxSkidTotal() {
    return this.boxSkid.eachCost * this.tmpBoxSkidsOrdered;
  }

  public getBoxSkidIndividualUnits() {
    return this.tmpBoxSkidsOrdered * this.boxSkid.itemQty;
  }

  public updateBoxSkidsOrderedCalcs() {
    let enteredValue = this.inlineOrderForm.get("boxSkidsOrdered").value;

    if (enteredValue >= 0) {
      this.tmpBoxSkidsOrdered = enteredValue;
    }
  }

  public resetValues() {
    this.inlineOrderForm.controls["boxSkidsOrdered"].setValue(
      this.boxSkidsOrdered
    );
    this.inlineOrderForm.controls["comments"].setValue(this.comments);
    this.tmpBoxSkidsOrdered = this.boxSkidsOrdered;
  }
}
