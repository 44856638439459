import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";

@Injectable()
export class OrderService extends DataService {
  constructor(http: HttpClient) {
    super("orders", http);
  }

  public getCurrentOrders() {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/current-orders")
      .pipe(catchError(this.handleError));
  }

  public updateShippingRate(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/update-shipping-rate",
        JSON.stringify(resource),
        { headers: super.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  public updateShipDate(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/update-ship-date",
        JSON.stringify(resource),
        { headers: super.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  public updateOrderAddress(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/update-order-address",
        JSON.stringify(resource),
        { headers: super.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  public updateOrderContact(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/update-order-contact",
        JSON.stringify(resource),
        { headers: super.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  public updateOrderPONumber(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/update-po-number",
        JSON.stringify(resource),
        { headers: super.getHeaders() }
      )
      .pipe(catchError(this.handleError));
  }

  public updateOrderNote(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/update-note", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public removeItemFromOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/remove-item", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public removeRackFromOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/remove-rack", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public removeHalfShelfFromOrder(resource) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/remove-halfshelf",
        JSON.stringify(resource),
        {
          headers: super.getHeaders(),
        }
      )
      .pipe(catchError(this.handleError));
  }

  public removeBoxSkidFromOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/remove-boxskid", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public addItemToOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/add-item", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public addLiftGateToOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/add-liftgate", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  public addCreditToOrder(resource) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/add-credit", JSON.stringify(resource), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }
}
