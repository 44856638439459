import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/auth/services/auth.service";
import { OrderService } from "src/app/shared/services/order.service";

@Component({
  selector: "app-add-credit-to-order-modal",
  templateUrl: "./add-credit-to-order-modal.component.html",
  styleUrls: ["./add-credit-to-order-modal.component.css"]
})
export class AddCreditToOrderModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _orderService: OrderService
  ) {}

  @Input() orderId: string;
  @Input() maxAllowedCreditValue: any;
  @Output() addCredit = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return "Add Credit";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public submitChanges(f) {
    let dto = {
      orderId: f.orderId,
      credit: f.credit
    };

    this._orderService.addCreditToOrder(dto).subscribe(
      (res: any) => {
        const dto = {
          type: "success",
          order: res
        };
        this.addCredit.emit(dto);
        this.activeModal.close();
      },
      error => {
        console.log(error);
      }
    );
  }
}
