import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppError } from "../errors/app-error";
import { NotFoundError } from "../errors/not-found-error";
import { BadInput } from "../errors/bad-input";
import { environment } from "../../../environments/environment";

import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

let env = environment.production ? "production" : "development";
import * as config from "../../../config.json";

@Injectable()
export class ImageService {
  private _url: string;

  constructor(private _http: HttpClient) {
    this._url = config[env].apiUrl + "images";
  }

  public uploadImages(
    uploadType: ImageTypes,
    parentId,
    imagesToUpload,
    isUpdate = false
  ) {
    const formData: FormData = new FormData();
    imagesToUpload.forEach((img) => {
      formData.append("images", img);
    });
    formData.append("parentId", parentId); //parent will be the productId, rackId, halfshelfId, boxSkidId this belongs to.
    formData.append("isUpdate", isUpdate.toString());

    return this._http
      .post(`${this._url}/${uploadType}`, formData, {
        headers: new HttpHeaders({ enctype: "multipart/form-data" }),
      })
      .pipe(catchError(this.handleError));
  }

  public getImage(itemType: ImageTypes, fileName) {
    return this._http
      .get(this._url + "/" + itemType + "/" + fileName)
      .pipe(catchError(this.handleError));
  }

  public isAcceptedFileType(file) {
    let nameParts = file.name.toUpperCase().split(".");
    const fileExtension = nameParts[nameParts.length - 1].trim();

    return (
      fileExtension === "PNG" ||
      fileExtension === "JPEG" ||
      fileExtension === "JPG"
    );
  }

  public handleError(error: Response) {
    if (error.status === 404) return throwError(new NotFoundError(error));

    if (error.status === 400) return throwError(new BadInput(error));

    return throwError(new AppError(error));
  }
}

export enum ImageTypes {
  "product" = "product",
  "rack" = "rack",
  "halfshelf" = "halfshelf",
  "boxskid" = "boxskid",
}
