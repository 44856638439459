import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotFoundError } from "../shared/errors/not-found-error";
import { IHalfShelf } from "../shared/interfaces/halfshelf.interface";
import { CartService } from "../shared/services/cart.service";

@Component({
  selector: "app-halfshelf-card",
  templateUrl: "./halfshelf-card.component.html",
  styleUrls: ["./halfshelf-card.component.css"],
})
export class HalfshelfCardComponent implements OnInit {
  constructor(private _cartService: CartService) {}

  @Input() halfShelf: IHalfShelf;
  @Input() halfShelvesOrdered: number;
  @Input() comments: string;
  @Input() fileServerUrl: string;
  @Output() addHalfShelfToCart = new EventEmitter<any>();
  @Output() updateHalfShelfInCart = new EventEmitter<any>();
  @Output() removeHalfShelfFromCart = new EventEmitter<any>();

  public inlineOrderForm;
  public inCart: boolean;
  public tmpHalfShelvesOrdered;

  ngOnInit() {
    this.tmpHalfShelvesOrdered = this.halfShelvesOrdered;

    this.inlineOrderForm = new FormGroup({
      halfShelfId: new FormControl(this.halfShelf.id, [Validators.required]),
      halfShelvesOrdered: new FormControl(this.halfShelvesOrdered, [
        Validators.min(0),
      ]),
      comments: new FormControl(this.comments),
    });

    if (this.halfShelvesOrdered > 0) {
      this.inCart = true;
    } else {
      this.inCart = false;
    }
  }

  public addHalfShelf(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.addHalfShelfToCart(data).subscribe(
      (res: any) => {
        this.halfShelvesOrdered = res.CartHalfShelf.halfShelfQty;
        this.comments = res.CartHalfShelf.comments;

        let addHalfShelfResponse = {
          type: "success",
          halfShelf: {
            CartHalfShelf: res.CartHalfShelf,
            id: this.halfShelf.id,
            eachCost: this.halfShelf.eachCost,
          },
        };
        this.inCart = true;
        this.addHalfShelfToCart.emit(addHalfShelfResponse);
      },
      (error) => {
        this.halfShelvesOrdered = 0;
        this.comments = "";

        let addHalfShelfResponse = {
          type: "error",
          halfShelf: this.halfShelf,
          alert: {
            type: "danger",
            title: "Half Shelf not added to cart.",
            msg: error.originalError.errors[0].description,
          },
        };
        this.inCart = false;
        this.addHalfShelfToCart.emit(addHalfShelfResponse);
      }
    );
  }

  public updateHalfShelf(data, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.updateHalfShelfInCart(data).subscribe(
      (res: any) => {
        this.halfShelvesOrdered = res.CartHalfShelf.halfShelfQty;
        this.comments = res.CartHalfShelf.comments;

        let updateHalfShelfResponse = {
          type: "success",
          halfShelf: {
            CartHalfShelf: res.CartHalfShelf,
            id: this.halfShelf.id,
            eachCost: this.halfShelf.eachCost,
          },
        };
        this.inCart = true;
        this.updateHalfShelfInCart.emit(updateHalfShelfResponse);
      },
      (error) => {
        let updateHalfShelfResponse = {
          type: "error",
          halfShelf: this.halfShelf,
          alert: {
            type: "danger",
            title: "Half Shelf could not be updated.",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.updateHalfShelfInCart.emit(updateHalfShelfResponse);
      }
    );
  }

  public removeHalfShelf(halfShelfId, inlineOrderForm) {
    inlineOrderForm.close();
    this._cartService.removeHalfShelfFromCart(halfShelfId).subscribe(
      (res: any) => {
        this.halfShelvesOrdered = 0;
        let removeHalfShelfResponse = {
          type: "success",
          halfShelfId: this.halfShelf.id,
          alert: {
            type: "success",
            title: "Half Shelf Removed From Cart.",
            msg: "",
          },
        };
        this.inCart = false;
        this.inlineOrderForm.controls["halfShelvesOrdered"].setValue(0);
        this.removeHalfShelfFromCart.emit(removeHalfShelfResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let removeHalfShelfResponse = {
            type: "success",
            halfShelfId: this.halfShelf.id,
            alert: {
              type: "success",
              title: "Half Shelf Removed From Cart.",
              msg: "",
            },
          };
          this.removeHalfShelfFromCart.emit(removeHalfShelfResponse);
        } else {
          let removeItemResponse = {
            type: "error",
            halfShelfId: this.halfShelf.id,
            alert: {
              type: "danger",
              title: "Half Shelf Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.inCart = true;
          this.removeHalfShelfFromCart.emit(removeItemResponse);
        }
      }
    );
  }

  public getHalfShelfTotal() {
    return this.halfShelf.eachCost * this.tmpHalfShelvesOrdered;
  }

  public getHalfShelfIndividualUnits() {
    return this.tmpHalfShelvesOrdered * this.halfShelf.itemQty;
  }

  public updateHalfShelvesOrderedCalcs() {
    let enteredValue = this.inlineOrderForm.get("halfShelvesOrdered").value;

    if (enteredValue >= 0) {
      this.tmpHalfShelvesOrdered = enteredValue;
    }
  }

  public resetValues() {
    this.inlineOrderForm.controls["halfShelvesOrdered"].setValue(
      this.halfShelvesOrdered
    );
    this.inlineOrderForm.controls["comments"].setValue(this.comments);
    this.tmpHalfShelvesOrdered = this.halfShelvesOrdered;
  }
}
