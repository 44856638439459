<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <!-- Create a New Address -->
    <div class="row">
      <div class="col-12">
        <h5>Modify Contact</h5>
      </div>
      <input
        name="addressId"
        id="addressId"
        [ngModel]="contact.addressId"
        type="text"
        hidden
      />
      <div class="col-12 form-group">
        <label for="name">Name</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="Enter Name"
          id="name"
          name="name"
          [ngModel]="contact.name"
          #name="ngModel"
        />
        <div class="form-alert" *ngIf="name.touched && !name.valid">
          <div *ngIf="name.errors.required">
            Name Required.
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="email">Email</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter Email"
          id="email"
          name="email"
          [ngModel]="contact.email"
          #email="ngModel"
        />
      </div>
      <div class="col-12 form-group">
        <label for="phone">Phone</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter Phone"
          id="phone"
          name="phone"
          [ngModel]="contact.phone"
          #phone="ngModel"
        />
      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitChanges(form.value)"
        [disabled]="!form.valid"
      >
        Update
      </button>
    </div>
  </form>
</div>
