<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ getModalHeader() }}
    <fa-icon
      class="info-icon"
      popoverClass="info-popover"
      placement="bottom"
      [icon]="infoIcon"
      [ngbPopover]="helpInfoMsg"
      triggers="mouseenter:mouseleave"
      [popoverTitle]="helpInfoTitle"
    ></fa-icon>
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <!-- If Billing Address -->
    <div
      *ngIf="address.type == 'billing'"
      class="row"
      ngModelGroup="addressGroup"
      #addressGroup="ngModelGroup"
      streetOrPOBoxRequired
    >
      <div class="col-12">
        <h4>{{ address.Company ? address.Company.name : "" }}</h4>
      </div>
      <div class="col-12 form-group">
        <label for="street1">Enter Street Adress</label>
        <input
          type="text"
          class="form-control"
          placeholder="Street Address"
          id="street1"
          name="street1"
          [ngModel]="address.street1"
          #street1="ngModel"
        />
        <div
          class="form-alert"
          *ngIf="street1.touched && poBox.touched && addressGroup.errors"
        >
          <div *ngIf="addressGroup.errors.streetOrPOBoxRequired">
            Either the Street Address or PO Box need to be supplied.
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="street2" class="sr-only">Enter Street 2</label>
        <input
          type="text"
          class="form-control"
          placeholder="Steet Address 2"
          id="street2"
          name="street2"
          [ngModel]="address.street2"
        />
      </div>
      <div class="col-12 form-group">
        <label for="poBox">PO Box</label>
        <input
          type="text"
          class="form-control"
          placeholder="PO Box"
          id="poBox"
          name="poBox"
          [ngModel]="address.poBox"
          #poBox="ngModel"
        />
        <div
          class="form-alert"
          *ngIf="street1.touched && poBox.touched && addressGroup.errors"
        >
          <div *ngIf="addressGroup.errors.streetOrPOBoxRequired">
            Either the Street Address or PO Box need to be supplied.
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="city">City</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="City"
          id="city"
          name="city"
          [ngModel]="address.city"
          #city="ngModel"
        />
        <div class="form-alert" *ngIf="city.touched && !city.valid">
          <div *ngIf="city.errors.required">
            City is required.
          </div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="state">State</label>
        <select
          class="form-control"
          required
          name="state"
          id="state"
          [ngModel]="address.state"
          #state="ngModel"
        >
          <option *ngFor="let state of stateOptions" [ngValue]="state">{{
            state
          }}</option>
        </select>
        <div class="form-alert" *ngIf="state.touched && !state.valid">
          <div *ngIf="state.errors.selectionRequired">
            State is required.
          </div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="zip">Zip Code</label>
        <input
          required
          pattern="^[0-9]{5}(?:-[0-9]{4})?$"
          type="text"
          class="form-control"
          placeholder="Zip Code"
          id="zip"
          name="zip"
          [ngModel]="address.zip"
          #zip="ngModel"
        />
        <div class="form-alert" *ngIf="zip.touched && !zip.valid">
          <div *ngIf="zip.errors.required">
            Zip Code is required.
          </div>
          <div *ngIf="zip.errors.pattern">
            Please enter a valid zip code in the 12345 or the 12345-6789 format.
          </div>
        </div>
      </div>
      <div *ngIf="address.type == 'shipping'" class="col-6 form-group">
        <label for="shippingRate">Shipping Rate (%)</label>
        <input
          required
          isBetween="0:100"
          type="number"
          class="form-control"
          placeholder="Shipping Rate %"
          id="shippingRate"
          name="shippingRate"
          [ngModel]="address.shippingRate"
          #shippingRate="ngModel"
        />
        <div
          class="form-alert"
          *ngIf="shippingRate.touched && !shippingRate.valid"
        >
          <div *ngIf="shippingRate.errors.required">
            Shipping rate is required.
          </div>
          <div *ngIf="shippingRate.errors.isBetween">
            Shipping rate should be a number between
            {{ shippingRate.errors.isBetween.minAllowed }} and
            {{ shippingRate.errors.isBetween.maxAllowed }}.
          </div>
        </div>
      </div>
    </div>

    <!-- If Shipping Address -->
    <div
      *ngIf="address.type == 'shipping'"
      class="row"
      ngModelGroup="addressGroup"
      #addressGroup="ngModelGroup"
    >
      <div class="col-12">
        <h4>{{ address.Company ? address.Company.name : "" }}</h4>
      </div>
      <div class="col-12 form-group">
        <label for="companyName">Ship-to Company Name</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="Enter ship-to company name."
          id="companyName"
          name="companyName"
          [ngModel]="address.companyName"
          #companyName="ngModel"
        />
        <div
          class="form-alert"
          *ngIf="companyName.touched && companyName.errors"
        >
          <div *ngIf="companyName.required">
            Companing name is required.
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="street1">Enter Street Adress</label>
        <input
          type="text"
          required
          class="form-control"
          placeholder="Street Address"
          id="street1"
          name="street1"
          [ngModel]="address.street1"
          #street1="ngModel"
        />
        <div class="form-alert" *ngIf="street1.touched && street1.errors">
          <div *ngIf="street1.errors.required">
            Street Address is required.
          </div>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="street2" class="sr-only">Enter Street 2</label>
        <input
          type="text"
          class="form-control"
          placeholder="Steet Address 2"
          id="street2"
          name="street2"
          [ngModel]="address.street2"
        />
      </div>
      <div class="col-12 form-group">
        <label for="city">City</label>
        <input
          required
          type="text"
          class="form-control"
          placeholder="City"
          id="city"
          name="city"
          [ngModel]="address.city"
          #city="ngModel"
        />
        <div class="form-alert" *ngIf="city.touched && !city.valid">
          <div *ngIf="city.errors.required">
            City is required.
          </div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="state">State</label>
        <select
          class="form-control"
          required
          name="state"
          id="state"
          [ngModel]="address.state"
          #state="ngModel"
        >
          <option *ngFor="let state of stateOptions" [ngValue]="state">{{
            state
          }}</option>
        </select>
        <div class="form-alert" *ngIf="state.touched && !state.valid">
          <div *ngIf="state.errors.selectionRequired">
            State is required.
          </div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="zip">Zip Code</label>
        <input
          required
          pattern="^[0-9]{5}(?:-[0-9]{4})?$"
          type="text"
          class="form-control"
          placeholder="Zip Code"
          id="zip"
          name="zip"
          [ngModel]="address.zip"
          #zip="ngModel"
        />
        <div class="form-alert" *ngIf="zip.touched && !zip.valid">
          <div *ngIf="zip.errors.required">
            Zip Code is required.
          </div>
          <div *ngIf="zip.errors.pattern">
            Please enter a valid zip code in the 12345 or the 12345-6789 format.
          </div>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="shippingRate">Shipping Rate (%)</label>
        <input
          required
          isBetween="0:100"
          type="number"
          class="form-control"
          placeholder="Shipping Rate %"
          id="shippingRate"
          name="shippingRate"
          [ngModel]="address.shippingRate"
          #shippingRate="ngModel"
        />
        <div
          class="form-alert"
          *ngIf="shippingRate.touched && !shippingRate.valid"
        >
          <div *ngIf="shippingRate.errors.required">
            Shipping rate is required.
          </div>
          <div *ngIf="shippingRate.errors.isBetween">
            Shipping rate should be a number between
            {{ shippingRate.errors.isBetween.minAllowed }} and
            {{ shippingRate.errors.isBetween.maxAllowed }}.
          </div>
        </div>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update(form.value.addressGroup)"
        [disabled]="!form.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete Address
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>
        Are you sure you want to delete {{ address.type }} address "{{
          address.street1
        }}
        {{ address.street2 }} {{ address.poBox }} {{ address.city }},
        {{ address.state }} {{ address.zip }}".
      </p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>
    <form #deleteForm="ngForm">
      <div class="form-group">
        <input type="number" hidden [ngModel]="address.id" name="id" id="id" />
        <button class="btn btn-primary" (click)="delete(deleteForm.value.id)">
          Confirm Delete
        </button>
        <button class="btn btn-default float-right" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
