import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundError } from "src/app/shared/errors/not-found-error";
import { IProduct } from "src/app/shared/interfaces/product.interface";
import {
  ImageService,
  ImageTypes,
} from "src/app/shared/services/image.service";
import { BoxSkidService } from "src/app/shared/services/boxskid.service";
import { ProductService } from "src/app/shared/services/product.service";
import { IBoxSkid } from "../../shared/interfaces/boxskid.interface";
import { BoxService } from "../../shared/services/box.service";
import { IBox } from "../../shared/interfaces/box.interface";

@Component({
  selector: "app-boxskid-modal",
  templateUrl: "./boxskid-modal.component.html",
  styleUrls: ["./boxskid-modal.component.css"],
})
export class BoxSkidModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private _modalService: NgbModal,
    private _boxSkidService: BoxSkidService,
    private _imageService: ImageService,
    private _boxService: BoxService,
    private _fb: FormBuilder
  ) {}

  @Input() boxSkid: IBoxSkid;
  @Output() createBoxSkid = new EventEmitter<any>();
  @Output() deleteBoxSkid = new EventEmitter<any>();
  @Output() updateBoxSkid = new EventEmitter<any>();

  public modalAction: ModalActions;
  public boxSkidImagesToUpload: Array<File> = null;
  public imageChanged: boolean = false;
  public boxOptions: Array<IBox>;
  public productOptions: Array<IProduct>;
  public isLoading: boolean;

  //Forms
  public boxSkidForm = this._fb.group({
    name: ["", Validators.required],
    upc: ["", Validators.required],
    itemNo: ["", Validators.required],
    display: [true],
    adminOnly: [false],
    products: this._fb.array([]),
  });

  ngOnInit() {
    this.isLoading = false;
    //Determine Purpose of Launching this Modal
    if (this.boxSkid) {
      this.modalAction = ModalActions.Edit;
      //Set Initial Values
      this.boxSkidForm.get("name").setValue(this.boxSkid.name);
      this.boxSkidForm.get("upc").setValue(this.boxSkid.upc);
      this.boxSkidForm.get("itemNo").setValue(this.boxSkid.itemNo);
      this.boxSkidForm.get("display").setValue(this.boxSkid.display);
      this.boxSkidForm.get("adminOnly").setValue(this.boxSkid.adminOnly);

      this.boxSkid.Products.forEach((product) => {
        let form = this.productForm;
        form.get("productId").setValue(product.id);
        form.get("boxQty").setValue(product.BoxSkidProduct.boxQty);
        form.get("boxEachCost").setValue(product.BoxSkidProduct.boxEachCost);
        this.products.push(form);
      });

      //Get image
      if (this.boxSkid.BoxSkidImages) {
        if (this.boxSkid.BoxSkidImages.length > 0) {
          this._imageService
            .getImage(
              ImageTypes.boxskid,
              this.boxSkid.BoxSkidImages[0].fileName
            )
            .subscribe(
              (url) => {
                this.boxSkid.BoxSkidImages[0].imageUrl = url;
              },
              (error) => {
                console.log("error getting box skid image.");
              }
            );
        }
      }
    } else {
      this.modalAction = ModalActions.Create;
      this.boxSkid = <IBoxSkid>{};
      this.boxSkid.display = true;
      this.addProduct();
    }

    this._boxService.getAll().subscribe(
      (res: Array<IBox>) => {
        this.boxOptions = res;
      },
      (error) => {
        console.log("There was an error getting boxes.");
      }
    );

    //Get products we will need for our dropdown
    this.productService.getAll().subscribe(
      (products: Array<IProduct>) => {
        this.productOptions = products.filter((p) => p.boxable);
      },
      (error) => {
        console.log("There was an error getting products.");
      }
    );
  }

  get productForm(): FormGroup {
    return this._fb.group({
      productId: ["", Validators.required],
      boxQty: [1, Validators.required],
      boxEachCost: ["", [Validators.required, Validators.min(0)]],
    });
  }

  get products(): FormArray {
    return this.boxSkidForm.get("products") as FormArray;
  }

  public getBoxSkidCost() {
    return this.products.value
      .map((product) => product.boxQty * product.boxEachCost)
      .reduce((result, cost) => result + cost);
  }

  public productMsg(product) {
    if (!this.productOptions) return null;
    const productId = product.get("productId");
    if (!productId) return null;

    const matchingProduct = this.productOptions.find(
      (p) => p.id === productId.value
    );

    if (!matchingProduct) return null;

    const boxSize = matchingProduct.Box.size;
    const qtyPerBox = matchingProduct.qtyPerBox;

    return `${qtyPerBox} items / box (${boxSize}) = ${
      qtyPerBox * product.get("boxQty").value
    } pieces @ $${product.get("boxEachCost").value / qtyPerBox} each`;
  }

  public addProduct(): void {
    this.products.push(this.productForm);
  }

  public removeProduct(index: number): void {
    this.products.removeAt(index);
  }

  public changeProduct(e) {
    const boxSkidProductsIndex = e.target.id.split("-")[1];
    const selectedProductId = e.target.value;
    const productOpt = this.productOptions.find(
      (p) => p.id === selectedProductId
    );

    this.products.controls[boxSkidProductsIndex].patchValue({
      productId: selectedProductId,
      boxEachCost: productOpt.eachCost * productOpt.qtyPerBox,
    });
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New Skid of Boxes";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit Skid of Boxes";
    } else {
      return "Skid of Boxes Form";
    }
  }

  public updateBoxSkidImage(files: FileList) {
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      if (!this._imageService.isAcceptedFileType(files.item(i))) {
        hasError = true;
        break;
      }
    }

    if (hasError)
      return console.log(
        "The file type selected is not a valid file type. Only png, jpg or jpeg types allowed."
      );

    this.imageChanged = true;
    delete this.boxSkid.BoxSkidImages;
    this.boxSkidImagesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.boxSkidImagesToUpload.push(files.item(i));
    }
  }

  public add() {
    this.isLoading = true;

    this._boxSkidService.create(this.boxSkidForm.value).subscribe(
      (createRes: any) => {
        if (this.imageChanged) {
          this._imageService
            .uploadImages(
              ImageTypes.boxskid,
              createRes.boxSkid.id,
              this.boxSkidImagesToUpload
            )
            .subscribe(
              (uploadImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                uploadImageRes.filesData.forEach((fileData) => {
                  createRes.boxSkid.BoxSkidImages.push({
                    boxSkidId: uploadImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let boxSkidCreateResponse = {
                  type: "success",
                  boxSkid: createRes.boxSkid,
                  image: uploadImageRes.image,
                  alert: {
                    type: createRes.status,
                    title: "Box Skid Created",
                    msg: createRes.message,
                  },
                };
                this.activeModal.close();
                this.createBoxSkid.emit(boxSkidCreateResponse);
              },
              (error) => {
                this.isLoading = false;

                let boxSkidCreateResponse = {
                  type: "success",
                  boxSkid: createRes.boxSkid,
                  alert: {
                    type: createRes.status,
                    title: "Box Skid Created",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.createBoxSkid.emit(boxSkidCreateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let boxSkidCreateResponse = {
            type: "success",
            boxSkid: createRes.boxSkid,
            alert: {
              type: createRes.status,
              title: "Box Skid Added",
              msg: createRes.message,
            },
          };
          this.activeModal.close();
          this.createBoxSkid.emit(boxSkidCreateResponse);
        }
      },
      (error) => {
        let boxSkidCreateResponse = {
          type: "error",
          boxSkid: this.boxSkid,
          alert: {
            type: "danger",
            title: "Box Skid Not Created",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.createBoxSkid.emit(boxSkidCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: string) {
    this.activeModal.close();

    this._boxSkidService.delete(id).subscribe(
      (res: any) => {
        let deleteBoxSkidResponse = {
          type: "success",
          boxSkid: this.boxSkid,
          alert: {
            type: "success",
            title: "Box Skid deleted.",
            msg: res.message,
          },
        };
        this.deleteBoxSkid.emit(deleteBoxSkidResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let deleteBoxSkidResponse = {
            type: "success",
            boxSkid: this.boxSkid,
            alert: {
              type: "success",
              title: "Box Skid deleted.",
              msg:
                "Box Skid " + this.boxSkid.description + " has been deleted.",
            },
          };
          this.deleteBoxSkid.emit(deleteBoxSkidResponse);
        } else {
          let deleteBoxSkidResponse = {
            type: "error",
            boxSkid: this.boxSkid,
            alert: {
              type: "danger",
              title: "Box Skid was not deleted.",
              msg: error.originalError.error.errors[0].description,
            },
          };
          this.deleteBoxSkid.emit(deleteBoxSkidResponse);
        }
      }
    );
  }

  public update() {
    this.isLoading = true;
    this.boxSkidForm.value.id = this.boxSkid.id;

    this._boxSkidService.update(this.boxSkidForm.value).subscribe(
      (updateBoxSkidRes: any) => {
        if (this.imageChanged) {
          updateBoxSkidRes.boxSkid.BoxSkidImages = [];
          this._imageService
            .uploadImages(
              ImageTypes.boxskid,
              this.boxSkid.id,
              this.boxSkidImagesToUpload,
              true
            )
            .subscribe(
              (updateImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                updateImageRes.filesData.forEach((fileData) => {
                  updateBoxSkidRes.boxSkid.BoxSkidImages.push({
                    boxSkidId: updateImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let boxSkidUpdateResponse = {
                  type: "success",
                  boxSkid: updateBoxSkidRes.boxSkid,
                  alert: {
                    type: updateBoxSkidRes.status,
                    title: "Box Skid Updated",
                    msg: updateBoxSkidRes.message,
                  },
                };
                this.activeModal.close();
                this.updateBoxSkid.emit(boxSkidUpdateResponse);
              },
              (error) => {
                this.isLoading = false;

                let boxSkidUpdateResponse = {
                  type: "success",
                  boxSkid: updateBoxSkidRes.boxSkid,
                  alert: {
                    type: updateBoxSkidRes.status,
                    title: "Box Skid Updated",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.updateBoxSkid.emit(boxSkidUpdateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let boxSkidUpdateResponse = {
            type: "success",
            boxSkid: updateBoxSkidRes.boxSkid,
            alert: {
              type: updateBoxSkidRes.status,
              title: "Box Skid Updated",
              msg: updateBoxSkidRes.message,
            },
          };
          this.activeModal.close();
          this.updateBoxSkid.emit(boxSkidUpdateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let boxSkidUpdateResponse = {
          type: "error",
          boxSkid: this.boxSkid,
          alert: {
            type: "danger",
            title: "Box Skid Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateBoxSkid.emit(boxSkidUpdateResponse);
      }
    );
  }

  public closeModal() {
    this.activeModal.close();
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create",
}
