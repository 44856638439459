import { PasswordValidators } from './../../common/validators/password.validators';
import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {OnInit} from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from './../../services/auth.service';

@Component({
    selector: 'reset-password-form',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
 
export class ResetPasswordComponent implements OnInit {
 
    hasError: boolean;
    errorMessage: string;
    title: string = "Pope's Plant Farm Orders"
    incommingPath: string;

    //Reset Password Form
    form = new FormGroup({
            password: new FormControl('',[
                Validators.required,
                Validators.minLength(7),
                PasswordValidators.containsANumber,
                PasswordValidators.containsAnUppercaseLetter,
                PasswordValidators.containsASpecialCharacter
            ]),
            passwordConfirm: new FormControl(), //No validators here, b/c at the form level it must match the password field.
            passcode: new FormControl() //this is a hidden prop that is set systematically
        }, PasswordValidators.passwordsMatch);

    get password(){
        return this.form.get('password');
    }

    get passwordConfirm(){
        return this.form.get('passwordConfirm');
    }

    constructor(
        private _route: ActivatedRoute,
        private _authService:AuthService, 
        private _router:Router) {}
 
    ngOnInit(){
        this._authService.removeToken();
        this._route.params.subscribe(
            params =>{
                this.form.setValue({password: '', passwordConfirm: '', passcode: params['passcode']});
        });

        this._route.url.subscribe(
            result => {
                this.incommingPath = result[0].path;
        });
    }

    changePassword(f: any) {

        this._authService.activate(f)
            .subscribe(
                (data: any) => {
                    if(data.statusCode == 200){
                        this._router.navigate(['login'],{ queryParams: { f: this.incommingPath } });
                    }
                },
                error => {
                    this.errorMessage = error.originalError.errors[0].description;
            });

    }

}