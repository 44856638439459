<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="col-12 form-group">
      <label for="printOption">Select Print Option</label>
      <select
        class="form-control"
        required
        name="printOption"
        id="printOption"
        ngModel
        #selectedOption="ngModel"
      >
        <option
          *ngFor="let printOption of printOptions"
          [ngValue]="printOption"
        >
          {{ printOption }}
        </option>
      </select>
      <div
        class="form-alert"
        *ngIf="selectedOption.touched && !selectedOption.valid"
      >
        <div *ngIf="selectedOption.errors.required">
          Print Option is required.
        </div>
      </div>
    </div>
    <div class="modal-button-group form-group">
      <button
        class="btn btn-primary"
        (click)="printOrder(form.value)"
        [disabled]="!form.valid"
      >
        Print
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>
