<div
  *ngIf="
    (cart?.Products?.length === 0 &&
      cart?.Racks?.length === 0 &&
      cart?.HalfShelves?.length === 0 &&
      cart?.BoxSkids?.length === 0) ||
    !cart
  "
  class="container empty-cart-container"
>
  <fa-icon class="cart-icon" [icon]="cartIcon"></fa-icon>
  <p class="empty-cart">
    Looks like your cart is empty. Browse the store and add items to your order.
  </p>
</div>

<div
  *ngIf="
    cart?.Products?.length > 0 ||
    cart?.Racks?.length > 0 ||
    cart?.HalfShelves?.length > 0 ||
    cart?.BoxSkids?.length > 0
  "
  class="container cart-container"
>
  <h3>Cart Summary</h3>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Line</th>
          <th>Name</th>
          <th>Item #</th>
          <th>Pieces</th>
          <th>Unit</th>
          <th>Unit Price</th>
          <th>Extended Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <!-- Prebuilt Racks Data -->
        <ng-container *ngFor="let rack of cart.Racks; index as i">
          <!-- Ordered Rack -->
          <tr>
            <td>{{ i + 1 }}</td>
            <td>
              {{ rack.name }}
            </td>
            <td>
              {{ rack.itemNo }}
            </td>
            <td>{{ rack.itemQty * rack.CartRack.rackQty }}</td>
            <td>{{ rack.CartRack.rackQty }} rack</td>
            <td>
              {{ rack.eachCost | currency : "USD" : "symbol" : "1.2-2" }}/rack
            </td>
            <td>
              {{
                rack.eachCost * rack.CartRack.rackQty
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
            <td>
              <fa-icon
                class="remove-from-cart-icon"
                [icon]="removeFromCartIcon"
                (click)="removeRack(rack)"
              ></fa-icon>
            </td>
          </tr>
          <tr *ngIf="rack.CartRack.comments">
            <td [class.no-top-border]="rack.CartRack.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="rack.CartRack.comments"
              colspan="6"
            >
              {{ rack.CartRack.comments }}
            </td>
          </tr>
          <ng-container *ngFor="let lineItem of rack.Products; index as j">
            <tr style="color: lightslategray">
              <td class="no-top-border">
                &nbsp;&nbsp;&nbsp;{{ i + 1 }}.{{ j + 1 }}
              </td>
              <td colspan="2">
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>{{ lineItem.RackProduct.qty * rack.CartRack.rackQty }}</td>
              <td>1 ea.</td>
              <td>
                {{
                  lineItem.RackProduct.eachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/ea.
              </td>
              <td>
                {{
                  lineItem.RackProduct.qty *
                    rack.CartRack.rackQty *
                    lineItem.RackProduct.eachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td></td>
            </tr>
          </ng-container>
        </ng-container>

        <!-- Prebuilt HalfShelves Data -->
        <ng-container *ngFor="let halfShelf of cart.HalfShelves; index as i">
          <!-- Ordered Half Shelf -->
          <tr>
            <td>{{ cart.Racks.length + i + 1 }}</td>
            <td>
              {{ halfShelf.name }}
            </td>
            <td>
              {{ halfShelf.itemNo }}
            </td>
            <td>
              {{ halfShelf.itemQty * halfShelf.CartHalfShelf.halfShelfQty }}
            </td>
            <td>{{ halfShelf.CartHalfShelf.halfShelfQty }} half shelf</td>
            <td>
              {{
                halfShelf.eachCost | currency : "USD" : "symbol" : "1.2-2"
              }}/half shelf
            </td>
            <td>
              {{
                halfShelf.eachCost * halfShelf.CartHalfShelf.halfShelfQty
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
            <td>
              <fa-icon
                class="remove-from-cart-icon"
                [icon]="removeFromCartIcon"
                (click)="removeHalfShelf(halfShelf)"
              ></fa-icon>
            </td>
          </tr>
          <tr *ngIf="halfShelf.CartHalfShelf.comments">
            <td [class.no-top-border]="halfShelf.CartHalfShelf.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="halfShelf.CartHalfShelf.comments"
              colspan="6"
            >
              {{ halfShelf.CartHalfShelf.comments }}
            </td>
          </tr>
          <ng-container *ngFor="let lineItem of halfShelf.Products; index as j">
            <tr style="color: lightslategray">
              <td class="no-top-border">
                &nbsp;&nbsp;&nbsp;{{ cart.Racks.length + i + 1 }}.{{ j + 1 }}
              </td>
              <td colspan="2">
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>
                {{
                  lineItem.HalfShelfProduct.qty *
                    halfShelf.CartHalfShelf.halfShelfQty
                }}
              </td>
              <td>1 ea.</td>
              <td>
                {{
                  lineItem.HalfShelfProduct.eachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/ea.
              </td>
              <td>
                {{
                  lineItem.HalfShelfProduct.qty *
                    halfShelf.CartHalfShelf.halfShelfQty *
                    lineItem.HalfShelfProduct.eachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td></td>
            </tr>
          </ng-container>
        </ng-container>

        <!-- Box Skids Data -->
        <ng-container *ngFor="let boxSkid of cart.BoxSkids; index as i">
          <!-- Ordered Box Skid -->
          <tr>
            <td>{{ cart.Racks.length + cart.HalfShelves.length + i + 1 }}</td>
            <td>
              {{ boxSkid.name }}
            </td>
            <td>
              {{ boxSkid.itemNo }}
            </td>
            <td>
              {{ boxSkid.itemQty * boxSkid.CartBoxSkid.boxSkidQty }}
            </td>
            <td>{{ boxSkid.CartBoxSkid.boxSkidQty }} skid</td>
            <td>
              {{
                boxSkid.eachCost | currency : "USD" : "symbol" : "1.2-2"
              }}/skid
            </td>
            <td>
              {{
                boxSkid.eachCost * boxSkid.CartBoxSkid.boxSkidQty
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
            <td>
              <fa-icon
                class="remove-from-cart-icon"
                [icon]="removeFromCartIcon"
                (click)="removeBoxSkid(boxSkid)"
              ></fa-icon>
            </td>
          </tr>
          <tr *ngIf="boxSkid.CartBoxSkid.comments">
            <td [class.no-top-border]="boxSkid.CartBoxSkid.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="boxSkid.CartBoxSkid.comments"
              colspan="6"
            >
              {{ boxSkid.CartBoxSkid.comments }}
            </td>
          </tr>
          <ng-container *ngFor="let lineItem of boxSkid.Products; index as j">
            <tr style="color: lightslategray">
              <td class="no-top-border">
                &nbsp;&nbsp;&nbsp;{{
                  cart.Racks.length + cart.HalfShelves.length + i + 1
                }}.{{ j + 1 }}
              </td>
              <td colspan="2">
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>
                {{
                  lineItem.qtyPerBox *
                    lineItem.BoxSkidProduct.boxQty *
                    boxSkid.CartBoxSkid.boxSkidQty
                }}
              </td>
              <td>
                {{
                  lineItem.BoxSkidProduct.boxQty *
                    boxSkid.CartBoxSkid.boxSkidQty
                }}
                box
              </td>
              <td>
                {{
                  lineItem.BoxSkidProduct.boxEachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/box
              </td>
              <td>
                {{
                  lineItem.BoxSkidProduct.boxQty *
                    boxSkid.CartBoxSkid.boxSkidQty *
                    lineItem.BoxSkidProduct.boxEachCost
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td></td>
            </tr>
          </ng-container>
        </ng-container>

        <!-- Regular Product Data -->
        <ng-container *ngFor="let lineItem of cart.Products; index as i">
          <!-- Ordered Box and half shelf -->
          <ng-container
            *ngIf="
              lineItem.CartProduct.boxQty > 0 &&
              lineItem.CartProduct.halfShelfQty > 0
            "
          >
            <tr>
              <td rowspan="2">
                {{
                  cart.Racks.length +
                    cart.HalfShelves.length +
                    cart.BoxSkids.length +
                    i +
                    1
                }}
              </td>
              <td rowspan="2">
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>{{ lineItem.itemNo }}-HS</td>
              <td>
                {{
                  lineItem.qtyPerHalfShelf * lineItem.CartProduct.halfShelfQty
                }}
              </td>
              <td>{{ lineItem.CartProduct.halfShelfQty }} half shelf</td>
              <td>
                {{
                  lineItem.eachCost * lineItem.qtyPerHalfShelf
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/half shelf
              </td>
              <td>
                {{
                  lineItem.CartProduct.halfShelfQty *
                    lineItem.eachCost *
                    lineItem.qtyPerHalfShelf
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td rowspan="2">
                <fa-icon
                  class="remove-from-cart-icon"
                  [icon]="removeFromCartIcon"
                  (click)="removeItem(lineItem)"
                ></fa-icon>
              </td>
            </tr>
            <tr>
              <td rowspan="1">{{ lineItem.itemNo }}-B</td>
              <td>
                {{ lineItem.qtyPerBox * lineItem.CartProduct.boxQty }}
              </td>
              <td>{{ lineItem.CartProduct.boxQty }} box</td>
              <td>
                {{
                  lineItem.eachCost * lineItem.qtyPerBox
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/box
              </td>
              <td>
                {{
                  lineItem.CartProduct.boxQty *
                    lineItem.eachCost *
                    lineItem.qtyPerBox | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
            </tr>
            <tr *ngIf="lineItem.CartProduct.comments">
              <td [class.no-top-border]="lineItem.CartProduct.comments"></td>
              <td
                class="comments"
                [class.no-top-border]="lineItem.CartProduct.comments"
                colspan="6"
              >
                {{ lineItem.CartProduct.comments }}
              </td>
            </tr>
          </ng-container>

          <!-- Ordered only half shelf -->
          <ng-container
            *ngIf="
              lineItem.CartProduct.halfShelfQty > 0 &&
              lineItem.CartProduct.boxQty == 0
            "
          >
            <tr>
              <td>
                {{
                  cart.Racks.length +
                    cart.HalfShelves.length +
                    cart.BoxSkids.length +
                    +i +
                    1
                }}
              </td>
              <td>
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>{{ lineItem.itemNo }}-HS</td>
              <td>
                {{
                  lineItem.qtyPerHalfShelf * lineItem.CartProduct.halfShelfQty
                }}
              </td>
              <td>{{ lineItem.CartProduct.halfShelfQty }} half shelf</td>
              <td>
                {{
                  lineItem.eachCost * lineItem.qtyPerHalfShelf
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/half shelf
              </td>
              <td>
                {{
                  lineItem.CartProduct.halfShelfQty *
                    lineItem.eachCost *
                    lineItem.qtyPerHalfShelf
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td>
                <fa-icon
                  class="remove-from-cart-icon"
                  [icon]="removeFromCartIcon"
                  (click)="removeItem(lineItem)"
                ></fa-icon>
              </td>
            </tr>
            <tr *ngIf="lineItem.CartProduct.comments">
              <td [class.no-top-border]="lineItem.CartProduct.comments"></td>
              <td
                class="comments"
                [class.no-top-border]="lineItem.CartProduct.comments"
                colspan="6"
              >
                {{ lineItem.CartProduct.comments }}
              </td>
            </tr>
          </ng-container>

          <!-- Ordered only box -->
          <ng-container
            *ngIf="
              lineItem.CartProduct.boxQty > 0 &&
              lineItem.CartProduct.halfShelfQty == 0
            "
          >
            <tr>
              <td>
                {{
                  cart.Racks.length +
                    cart.HalfShelves.length +
                    cart.BoxSkids.length +
                    +i +
                    1
                }}
              </td>
              <td>
                {{ lineItem.name }}
                <span class="product-description"
                  ><br />{{ lineItem.description }}
                </span>
              </td>
              <td>{{ lineItem.itemNo }}-B</td>
              <td>{{ lineItem.qtyPerBox * lineItem.CartProduct.boxQty }}</td>
              <td>{{ lineItem.CartProduct.boxQty }} box</td>
              <td>
                {{
                  lineItem.eachCost * lineItem.qtyPerBox
                    | currency : "USD" : "symbol" : "1.2-2"
                }}/box
              </td>
              <td>
                {{
                  lineItem.CartProduct.boxQty *
                    lineItem.eachCost *
                    lineItem.qtyPerBox | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
              <td>
                <fa-icon
                  class="remove-from-cart-icon"
                  [icon]="removeFromCartIcon"
                  (click)="removeItem(lineItem)"
                ></fa-icon>
              </td>
            </tr>
            <tr *ngIf="lineItem.CartProduct.comments">
              <td [class.no-top-border]="lineItem.CartProduct.comments"></td>
              <td
                class="comments"
                [class.no-top-border]="lineItem.CartProduct.comments"
                colspan="6"
              >
                {{ lineItem.CartProduct.comments }}
              </td>
            </tr>
          </ng-container>
        </ng-container>

        <tr>
          <th class="unit-totals-cell" colspan="5">
            <span
              *ngIf="
                cart.halfShelfQtyTotal > 0 ||
                cart.prebuiltRackQtyTotal > 0 ||
                cart.prebuiltHalfShelfQtyTotal > 0
              "
              >{{
                (cart.halfShelfQtyTotal + cart.prebuiltHalfShelfQtyTotal) /
                  this.halfShelvesPerRack +
                  cart.prebuiltRackQtyTotal
              }}
              {{
                (cart.halfShelfQtyTotal + cart.prebuiltHalfShelfQtyTotal) /
                  this.halfShelvesPerRack +
                  cart.prebuiltRackQtyTotal >
                1
                  ? "racks;"
                  : "rack; "
              }}
            </span>
            <span *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt === 1"
              >{{ cart.totalNumOfBoxesFromRegularAndPrebuilt }} box</span
            >
            <span *ngIf="cart.totalNumOfBoxesFromRegularAndPrebuilt > 1"
              >{{ cart.totalNumOfBoxesFromRegularAndPrebuilt }} boxes</span
            >
          </th>
          <th>Product Total</th>
          <th>
            <span>{{
              cart.productTotal | currency : "USD" : "symbol" : "1.2-2"
            }}</span>
          </th>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-12">
      <app-order-requirements
        *ngIf="cart"
        [cart]="cart"
        [changeCount]="changeCount"
        (orderValidityChange)="onOrderValidityChange($event)"
      ></app-order-requirements>
    </div>
    <div class="col-12">
      <button
        class="btn btn-primary"
        (click)="startOrder()"
        [disabled]="!isOrderValid"
      >
        Checkout
      </button>
      <button class="continue-shopping-btn btn btn-default" routerLink="/store">
        Continue Shopping
      </button>
    </div>
  </div>
</div>
