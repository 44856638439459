<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [hidden]="!isLoading" style="text-align: center">
    <img src="assets/img/loading.svg" />
  </div>
  <form [hidden]="isLoading" class="form" [formGroup]="halfShelfForm">
    <div class="form-group">
      <label for="name">Name</label>
      <input
        ngbAutofocus
        type="text"
        id="name"
        name="name"
        formControlName="name"
        class="form-control"
      />
    </div>

    <div class="row">
      <div class="col-6 form-group">
        <label for="upc">UPC</label>
        <input
          type="text"
          id="upc"
          name="upc"
          formControlName="upc"
          class="form-control"
        />
      </div>

      <div class="col-6 form-group">
        <label for="itemNo">Item No.</label>
        <input
          type="text"
          id="itemNo"
          name="itemNo"
          formControlName="itemNo"
          class="form-control"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4 switch-group">
        <label class="switch-label">Display for Sale</label><br />
        <ui-switch name="display" formControlName="display"></ui-switch>
      </div>
      <div class="col-4 switch-group">
        <label class="switch-label">Admin Only</label><br />
        <ui-switch name="adminOnly" formControlName="adminOnly"></ui-switch>
      </div>
      <div class="col-4">
        <p>HalfShelf Cost</p>
        <p>{{ getHalfShelfCost() | currency : "USD" : "symbol" : "1.2-2" }}</p>
      </div>
    </div>
    <hr />

    <div class="row" formArrayName="products">
      <div class="col-12">
        <h3>Products</h3>
      </div>

      <ng-container *ngFor="let product of products.controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="col-6 form-group">
            <label for="productSelect-{{ i }}">Product</label>
            <select
              class="form-control"
              id="productSelect-{{ i }}"
              (change)="changeProduct($event)"
              formControlName="productId"
            >
              <option value="" disabled>Choose product</option>
              <option
                *ngFor="let productOpt of productOptions"
                value="{{ productOpt.id }}"
              >
                {{ productOpt.name }}
              </option>
            </select>
          </div>

          <div class="col-2 form-group">
            <label for="qty">Qty</label>
            <input
              type="number"
              id="qty"
              name="qty"
              formControlName="qty"
              class="form-control"
            />
          </div>
          <div class="col-2 form-group">
            <label for="eachCost">Cost (ea.)</label>
            <input
              type="number"
              id="eachCost"
              name="eachCost"
              formControlName="eachCost"
              class="form-control"
            />
            <div
              class="form-alert"
              *ngIf="
                product.get('eachCost').touched &&
                !product.get('eachCost').valid
              "
            >
              <div *ngIf="product.get('eachCost').errors.required">
                Each Cost is required.
              </div>
              <div *ngIf="product.get('eachCost').errors.min">
                Each Cost has to be greater than or equal to
                {{ product.get("eachCost").errors.min.min }}.
              </div>
            </div>
          </div>
          <div class="col-1">
            <button
              *ngIf="products.length > 1"
              class="btn btn-danger"
              (click)="removeProduct(i)"
            >
              X
            </button>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12">
        <button class="btn btn-primary" (click)="addProduct()">
          Add Product
        </button>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="halfShelfImage">Half Shelf Image</label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            class="form-control-file"
            id="halfShelfImage"
            (change)="updateHalfShelfImage($event.target.files)"
          />
        </div>
        <img
          class="halfshelf-image"
          *ngIf="
            halfShelf.HalfShelfImages
              ? halfShelf.HalfShelfImages.length > 0
              : false
          "
          alt="HalfShelf-Image"
          src="{{ halfShelf.HalfShelfImages[0].imageUrl }}"
        />
        <p *ngIf="imageChanged">
          After you save, the new image will be available.
        </p>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Create'"
        class="btn btn-primary"
        (click)="add()"
        [disabled]="!halfShelfForm.valid"
      >
        Create Half Shelf
      </button>
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update()"
        [disabled]="!halfShelfForm.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete Half Shelf
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete: {{ halfShelf.upc }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to delete Half Shelf "{{ halfShelf.name }}".</p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>

    <div class="form-group">
      <button class="btn btn-primary" (click)="delete(halfShelf.id)">
        Confirm Delete
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>
