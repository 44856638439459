<div class="modal-header">
  <h4 class="modal-title pull-left">{{getModalHeader()}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">

    <div class="col-12 form-group">
      <input type="text" 
        hidden 
        class="form-control" 
        id="orderId" 
        name="orderId" 
        [ngModel]="orderId">
    </div>
    <div class="col-12 form-group">
      <label for="product">Select Product</label>
      <select class="form-control" required name="product" id="product" ngModel #selectedProduct="ngModel">
        <option *ngFor="let product of productOptions" [ngValue]="product">{{product.name}}</option>
      </select>
      <div class="form-alert" *ngIf="selectedProduct.touched && !selectedProduct.valid">
        <div *ngIf="selectedProduct.errors.required">
          Product is required.
        </div>
      </div>
    </div>
    <div *ngIf="selectedProduct?.value?.boxable" class="col-12 form-group">
      <label for="boxQty">Box Qty</label>
      <input type="number" class="form-control" id="boxQty" name="boxQty" ngModel #boxQty="ngModel">
    </div>

    <div *ngIf="selectedProduct?.value?.rackable" class="col-12 form-group">
      <label for="halfShelfQty">Half Shelf Qty</label>
      <input type="number" class="form-control" id="halfShelfQty" name="halfShelfQty" ngModel #halfShelfQty="ngModel">
    </div>

    <div class="col-12 form-group">
      <p><strong>Comments</strong></p>
      <label for="comments" class="sr-only">Comments</label>
      <textarea id="comments" name="comments" class="form-control" placeholder="Enter Notes Here ..." ngModel #comments="ngModel"></textarea>
    </div>


    <div class="modal-button-group form-group">
      <button class="btn btn-primary" (click)="addItemToOrder(form.value)" [disabled]="!form.valid">Add Item</button>
      <button class="btn btn-default float-right" (click)="closeModal()">Cancel</button>
    </div>



  </form>
</div>