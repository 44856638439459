<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="row">
      <input
        name="orderId"
        id="orderId"
        [ngModel]="orderId"
        type="text"
        hidden
      />
      <div class="col-12 form-group">
        <label for="credit">Enter Credit Amount</label>
        <input
          required
          isBetween="0:{{ maxAllowedCreditValue }}"
          type="number"
          class="form-control"
          placeholder="Enter Credit Amount"
          id="credit"
          name="credit"
          [ngModel]="credit"
          #credit="ngModel"
        />
        <div class="form-alert" *ngIf="credit.touched && !credit.valid">
          <div *ngIf="credit.errors.required">
            Credit Required.
          </div>
          <div *ngIf="credit.errors.isBetween">
            Credit must be between {{ credit.errors.isBetween.minAllowed }} and
            {{ credit.errors.isBetween.maxAllowed }}.
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitChanges(form.value)"
        [disabled]="!form.valid"
      >
        Add/Update Credit
      </button>
    </div>
  </form>
</div>
