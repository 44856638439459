import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { ICart } from "../interfaces/cart.interface";
import { SHELVES_PER_RACK } from "../data/constants";

@Injectable()
export class CartService extends DataService {
  constructor(http: HttpClient) {
    super("carts", http);
  }

  public addItemToCart(product) {
    return super
      .getHttp()
      .post(super.getApiUrl() + "/" + "add-item", JSON.stringify(product), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public updateItemInCart(product) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/" + "update-item", JSON.stringify(product), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public removeItemFromCart(productId) {
    return super
      .getHttp()
      .delete(super.getApiUrl() + "/remove-item/" + productId, {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public addRackToCart(rack) {
    return super
      .getHttp()
      .post(super.getApiUrl() + "/" + "add-rack", JSON.stringify(rack), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public updateRackInCart(rack) {
    return super
      .getHttp()
      .patch(super.getApiUrl() + "/" + "update-rack", JSON.stringify(rack), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public removeRackFromCart(rackId) {
    return super
      .getHttp()
      .delete(super.getApiUrl() + "/remove-rack/" + rackId, {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public addHalfShelfToCart(halfShelf) {
    return super
      .getHttp()
      .post(
        super.getApiUrl() + "/" + "add-halfshelf",
        JSON.stringify(halfShelf),
        {
          headers: super.getHeaders(),
        }
      )
      .pipe(catchError(super.handleError));
  }

  public updateHalfShelfInCart(halfShelf) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/" + "update-halfshelf",
        JSON.stringify(halfShelf),
        {
          headers: super.getHeaders(),
        }
      )
      .pipe(catchError(super.handleError));
  }

  public removeHalfShelfFromCart(halfShelfId) {
    return super
      .getHttp()
      .delete(super.getApiUrl() + "/remove-halfshelf/" + halfShelfId, {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public addBoxSkidToCart(boxSkid) {
    return super
      .getHttp()
      .post(super.getApiUrl() + "/" + "add-boxskid", JSON.stringify(boxSkid), {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public updateBoxSkidInCart(boxSkid) {
    return super
      .getHttp()
      .patch(
        super.getApiUrl() + "/" + "update-boxskid",
        JSON.stringify(boxSkid),
        {
          headers: super.getHeaders(),
        }
      )
      .pipe(catchError(super.handleError));
  }

  public removeBoxSkidFromCart(boxSkidId) {
    return super
      .getHttp()
      .delete(super.getApiUrl() + "/remove-boxskid/" + boxSkidId, {
        headers: super.getHeaders(),
      })
      .pipe(catchError(super.handleError));
  }

  public getUsersCartProducts() {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/my-cart/products")
      .pipe(catchError(super.handleError));
  }

  public getUsersCart() {
    return super
      .getHttp()
      .get(super.getApiUrl() + "/my-cart")
      .pipe(catchError(super.handleError));
  }

  public setCartComputedFields(cart: ICart) {
    //Product Quantities
    this.setBoxQtyTotal(cart);
    this.setHalfShelfQtyTotal(cart);
    this.setPrebuiltRackQtyTotal(cart);
    this.setPrebuiltHalfShelfQtyTotal(cart);
    this.setBoxSkidQtyTotal(cart);
    this.setTotalNumOfBoxesFromRegularAndPrebuilt(cart);

    cart.rackQtyTotal =
      cart.halfShelfQtyTotal / (SHELVES_PER_RACK * 2) +
      cart.prebuiltHalfShelfQtyTotal / (SHELVES_PER_RACK * 2) +
      cart.prebuiltRackQtyTotal;

    //Product Amounts
    this.setBoxTotal(cart);
    this.setHalfShelfTotal(cart);
    this.setPrebuiltRackTotal(cart);
    this.setPrebuiltHalfShelfTotal(cart);
    this.setBoxSkidTotal(cart);

    cart.rackTotal =
      cart.halfShelfTotal +
      cart.prebuiltRackTotal +
      cart.prebuiltHalfShelfTotal;
    cart.productTotal = cart.boxTotal + cart.rackTotal + cart.boxSkidTotal;
  }

  private setBoxQtyTotal(cart: ICart) {
    cart.boxQtyTotal = 0;

    cart.Products.forEach((lineItem) => {
      cart.boxQtyTotal += lineItem.CartProduct.boxQty;
    });
  }

  private setHalfShelfQtyTotal(cart: ICart) {
    cart.halfShelfQtyTotal = 0;

    cart.Products.forEach((lineItem) => {
      cart.halfShelfQtyTotal += lineItem.CartProduct.halfShelfQty;
    });
  }

  private setPrebuiltRackQtyTotal(cart: ICart) {
    cart.prebuiltRackQtyTotal = 0;
    if (cart.Racks) {
      cart.Racks.forEach((rack) => {
        cart.prebuiltRackQtyTotal += rack.CartRack.rackQty;
      });
    }
  }

  private setPrebuiltHalfShelfQtyTotal(cart: ICart) {
    cart.prebuiltHalfShelfQtyTotal = 0;
    if (cart.HalfShelves) {
      cart.HalfShelves.forEach((halfShelf) => {
        cart.prebuiltHalfShelfQtyTotal += halfShelf.CartHalfShelf.halfShelfQty;
      });
    }
  }

  private setBoxSkidQtyTotal(cart: ICart) {
    cart.boxSkidQtyTotal = 0;
    if (cart.BoxSkids) {
      cart.BoxSkids.forEach((boxSkid) => {
        cart.boxSkidQtyTotal += boxSkid.CartBoxSkid.boxSkidQty;
      });
    }
  }

  private setTotalNumOfBoxesFromRegularAndPrebuilt(cart: ICart) {
    cart.totalNumOfBoxesFromRegularAndPrebuilt = 0;
    cart.Products.forEach((lineItem) => {
      cart.totalNumOfBoxesFromRegularAndPrebuilt += lineItem.CartProduct.boxQty;
    });

    cart.BoxSkids.forEach((boxSkid) => {
      cart.totalNumOfBoxesFromRegularAndPrebuilt +=
        boxSkid.CartBoxSkid.boxSkidQty * boxSkid.boxQty;
    });
  }

  private setBoxTotal(cart: ICart) {
    cart.boxTotal = 0;

    cart.Products.forEach((lineItem) => {
      cart.boxTotal +=
        lineItem.eachCost * lineItem.qtyPerBox * lineItem.CartProduct.boxQty;
    });
  }

  private setHalfShelfTotal(cart: ICart) {
    cart.halfShelfTotal = 0;

    cart.Products.forEach((lineItem) => {
      cart.halfShelfTotal +=
        lineItem.eachCost *
        lineItem.qtyPerHalfShelf *
        lineItem.CartProduct.halfShelfQty;
    });
  }

  private setPrebuiltRackTotal(cart: ICart) {
    cart.prebuiltRackTotal = 0;

    cart.Racks.forEach((rack) => {
      cart.prebuiltRackTotal += rack.CartRack.rackQty * rack.eachCost;
    });
  }

  private setPrebuiltHalfShelfTotal(cart: ICart) {
    cart.prebuiltHalfShelfTotal = 0;

    cart.HalfShelves.forEach((halfShelf) => {
      cart.prebuiltHalfShelfTotal +=
        halfShelf.CartHalfShelf.halfShelfQty * halfShelf.eachCost;
    });
  }

  private setBoxSkidTotal(cart: ICart) {
    cart.boxSkidTotal = 0;

    cart.BoxSkids.forEach((boxSkid) => {
      cart.boxSkidTotal += boxSkid.CartBoxSkid.boxSkidQty * boxSkid.eachCost;
    });
  }
}
