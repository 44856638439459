import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundError } from "src/app/shared/errors/not-found-error";
import { IProduct } from "src/app/shared/interfaces/product.interface";
import {
  ImageService,
  ImageTypes,
} from "src/app/shared/services/image.service";
import { HalfShelfService } from "src/app/shared/services/halfshelf.service";
import { ProductService } from "src/app/shared/services/product.service";
import { IHalfShelf } from "./../../shared/interfaces/halfshelf.interface";

@Component({
  selector: "app-halfshelf-modal",
  templateUrl: "./halfshelf-modal.component.html",
  styleUrls: ["./halfshelf-modal.component.css"],
})
export class HalfShelfModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private _modalService: NgbModal,
    private _halfShelfService: HalfShelfService,
    private _imageService: ImageService,
    private _fb: FormBuilder
  ) {}

  @Input() halfShelf: IHalfShelf;
  @Output() createHalfShelf = new EventEmitter<any>();
  @Output() deleteHalfShelf = new EventEmitter<any>();
  @Output() updateHalfShelf = new EventEmitter<any>();

  public modalAction: ModalActions;
  public halfShelfImagesToUpload: Array<File> = null;
  public imageChanged: boolean = false;
  public productOptions: Array<IProduct>;
  public isLoading: boolean;

  //Forms
  public halfShelfForm = this._fb.group({
    name: ["", Validators.required],
    upc: ["", Validators.required],
    itemNo: ["", Validators.required],
    display: [true],
    adminOnly: [false],
    products: this._fb.array([]),
  });

  ngOnInit() {
    this.isLoading = false;
    //Determine Purpose of Launching this Modal
    if (this.halfShelf) {
      this.modalAction = ModalActions.Edit;
      //Set Initial Values
      this.halfShelfForm.get("name").setValue(this.halfShelf.name);
      this.halfShelfForm.get("upc").setValue(this.halfShelf.upc);
      this.halfShelfForm.get("itemNo").setValue(this.halfShelf.itemNo);
      this.halfShelfForm.get("display").setValue(this.halfShelf.display);
      this.halfShelfForm.get("adminOnly").setValue(this.halfShelf.adminOnly);

      this.halfShelf.Products.forEach((product) => {
        let form = this.productForm;
        form.get("productId").setValue(product.id);
        form.get("qty").setValue(product.HalfShelfProduct.qty);
        form.get("eachCost").setValue(product.HalfShelfProduct.eachCost);
        this.products.push(form);
      });

      //Get image
      if (this.halfShelf.HalfShelfImages) {
        if (this.halfShelf.HalfShelfImages.length > 0) {
          this._imageService
            .getImage(
              ImageTypes.halfshelf,
              this.halfShelf.HalfShelfImages[0].fileName
            )
            .subscribe(
              (url) => {
                this.halfShelf.HalfShelfImages[0].imageUrl = url;
              },
              (error) => {
                console.log("error getting halfshelf image.");
              }
            );
        }
      }
    } else {
      this.modalAction = ModalActions.Create;
      this.halfShelf = <IHalfShelf>{};
      this.halfShelf.display = true;
      this.addProduct();
    }

    //Get products we will need for our dropdown
    this.productService.getAll().subscribe(
      (products: Array<IProduct>) => {
        this.productOptions = products;
      },
      (error) => {
        console.log("There was an error getting products.");
      }
    );
  }

  get productForm(): FormGroup {
    return this._fb.group({
      productId: ["", Validators.required],
      qty: [1, Validators.required],
      eachCost: ["", [Validators.required, Validators.min(0)]],
    });
  }

  get products(): FormArray {
    return this.halfShelfForm.get("products") as FormArray;
  }

  public getHalfShelfCost() {
    return this.products.value
      .map((product) => product.qty * product.eachCost)
      .reduce((result, cost) => result + cost);
  }

  public addProduct(): void {
    this.products.push(this.productForm);
  }

  public removeProduct(index: number): void {
    this.products.removeAt(index);
  }

  public changeProduct(e) {
    const halfShelfProductsIndex = e.target.id.split("-")[1];
    const selectedProductId = e.target.value;
    const productOpt = this.productOptions.find(
      (p) => p.id === selectedProductId
    );

    this.products.controls[halfShelfProductsIndex].patchValue({
      productId: selectedProductId,
      eachCost: productOpt.eachCost,
    });
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New Half Shelf";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit Half Shelf";
    } else {
      return "Half Shelf Form";
    }
  }

  public updateHalfShelfImage(files: FileList) {
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      if (!this._imageService.isAcceptedFileType(files.item(i))) {
        hasError = true;
        break;
      }
    }

    if (hasError)
      return console.log(
        "The file type selected is not a valid file type. Only png, jpg or jpeg types allowed."
      );

    this.imageChanged = true;
    delete this.halfShelf.HalfShelfImages;
    this.halfShelfImagesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.halfShelfImagesToUpload.push(files.item(i));
    }
  }

  public add() {
    this.isLoading = true;

    this._halfShelfService.create(this.halfShelfForm.value).subscribe(
      (createRes: any) => {
        if (this.imageChanged) {
          this._imageService
            .uploadImages(
              ImageTypes.halfshelf,
              createRes.halfShelf.id,
              this.halfShelfImagesToUpload
            )
            .subscribe(
              (uploadImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                uploadImageRes.filesData.forEach((fileData) => {
                  createRes.halfShelf.HalfShelfImages.push({
                    halfShelfId: uploadImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let halfShelfCreateResponse = {
                  type: "success",
                  halfShelf: createRes.halfShelf,
                  image: uploadImageRes.image,
                  alert: {
                    type: createRes.status,
                    title: "Half Shelf Created",
                    msg: createRes.message,
                  },
                };
                this.activeModal.close();
                this.createHalfShelf.emit(halfShelfCreateResponse);
              },
              (error) => {
                this.isLoading = false;

                let halfShelfCreateResponse = {
                  type: "success",
                  halfShelf: createRes.halfShelf,
                  alert: {
                    type: createRes.status,
                    title: "Half Shelf Created",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.createHalfShelf.emit(halfShelfCreateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let halfShelfCreateResponse = {
            type: "success",
            halfShelf: createRes.halfShelf,
            alert: {
              type: createRes.status,
              title: "Half Shelf Added",
              msg: createRes.message,
            },
          };
          this.activeModal.close();
          this.createHalfShelf.emit(halfShelfCreateResponse);
        }
      },
      (error) => {
        let halfShelfCreateResponse = {
          type: "error",
          halfShelf: this.halfShelf,
          alert: {
            type: "danger",
            title: "Half Shelf Not Created",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.createHalfShelf.emit(halfShelfCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: string) {
    this.activeModal.close();

    this._halfShelfService.delete(id).subscribe(
      (res: any) => {
        let deleteHalfShelfResponse = {
          type: "success",
          halfShelf: this.halfShelf,
          alert: {
            type: "success",
            title: "Half Shelf deleted.",
            msg: res.message,
          },
        };
        this.deleteHalfShelf.emit(deleteHalfShelfResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let deleteHalfShelfResponse = {
            type: "success",
            halfShelf: this.halfShelf,
            alert: {
              type: "success",
              title: "Half Shelf deleted.",
              msg:
                "Half Shelf " +
                this.halfShelf.description +
                " has been deleted.",
            },
          };
          this.deleteHalfShelf.emit(deleteHalfShelfResponse);
        } else {
          let deleteHalfShelfResponse = {
            type: "error",
            halfShelf: this.halfShelf,
            alert: {
              type: "danger",
              title: "Half Shelf was not deleted.",
              msg: error.originalError.error.errors[0].description,
            },
          };
          this.deleteHalfShelf.emit(deleteHalfShelfResponse);
        }
      }
    );
  }

  public update() {
    this.isLoading = true;
    this.halfShelfForm.value.id = this.halfShelf.id;

    this._halfShelfService.update(this.halfShelfForm.value).subscribe(
      (updateHalfShelfRes: any) => {
        if (this.imageChanged) {
          updateHalfShelfRes.halfShelf.HalfShelfImages = [];
          this._imageService
            .uploadImages(
              ImageTypes.halfshelf,
              this.halfShelf.id,
              this.halfShelfImagesToUpload,
              true
            )
            .subscribe(
              (updateImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                updateImageRes.filesData.forEach((fileData) => {
                  updateHalfShelfRes.halfShelf.HalfShelfImages.push({
                    halfShelfId: updateImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let halfShelfUpdateResponse = {
                  type: "success",
                  halfShelf: updateHalfShelfRes.halfShelf,
                  alert: {
                    type: updateHalfShelfRes.status,
                    title: "Half Shelf Updated",
                    msg: updateHalfShelfRes.message,
                  },
                };
                this.activeModal.close();
                this.updateHalfShelf.emit(halfShelfUpdateResponse);
              },
              (error) => {
                this.isLoading = false;

                let halfShelfUpdateResponse = {
                  type: "success",
                  halfShelf: updateHalfShelfRes.halfShelf,
                  alert: {
                    type: updateHalfShelfRes.status,
                    title: "Half Shelf Updated",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.updateHalfShelf.emit(halfShelfUpdateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let halfShelfUpdateResponse = {
            type: "success",
            halfShelf: updateHalfShelfRes.halfShelf,
            alert: {
              type: updateHalfShelfRes.status,
              title: "Half Shelf Updated",
              msg: updateHalfShelfRes.message,
            },
          };
          this.activeModal.close();
          this.updateHalfShelf.emit(halfShelfUpdateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let halfShelfUpdateResponse = {
          type: "error",
          halfShelf: this.halfShelf,
          alert: {
            type: "danger",
            title: "Half Shelf Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateHalfShelf.emit(halfShelfUpdateResponse);
      }
    );
  }

  public closeModal() {
    this.activeModal.close();
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create",
}
