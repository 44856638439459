import {Component} from '@angular/core';

interface Credentials {
  username: string,
  password: string
}

@Component({
    selector: 'unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.css']
})
 
export class UnauthorizedComponent {
 
    credentials: Credentials;
 
    constructor() {}
 
}