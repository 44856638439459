import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderService } from "src/app/shared/services/order.service";
import { AddressService } from "src/app/shared/services/address.service";

@Component({
  selector: "app-edit-ship-date-modal",
  templateUrl: "./edit-ship-date-modal.component.html",
  styleUrls: ["./edit-ship-date-modal.component.css"],
})
export class EditShipDateModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _orderService: OrderService
  ) {}

  @Input() dto: any;
  @Output() updateShipDate = new EventEmitter<any>();

  public shipDate;

  public modalAction: ModalActions;

  ngOnInit() {
    this.modalAction = ModalActions.Edit;
    this.dto.shipDate = new Date(this.dto.shipDate);
    this.dto.shipDate = {
      year: this.dto.shipDate.getUTCFullYear(),
      month: this.dto.shipDate.getUTCMonth() + 1,
      day: this.dto.shipDate.getUTCDate(),
    };
  }

  public getModalHeader() {
    return "Update Ship Date";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public update(f) {
    let shipDate = new Date(
      this.dto.shipDate.year,
      this.dto.shipDate.month - 1,
      this.dto.shipDate.day
    );

    shipDate.setUTCHours(12);

    let dto = {
      orderId: this.dto.orderId,
      shipDate: shipDate,
    };

    this._orderService.updateShipDate(dto).subscribe(
      (res: any) => {
        let shipDateUpdateResponse = {
          type: "success",
          shipDate: res.shipDate,
          alert: {
            type: "success",
            title: "Shipping Date Updated",
            msg: res.message,
          },
        };
        this.activeModal.close();
        this.updateShipDate.emit(shipDateUpdateResponse);
      },
      (error) => {
        let shipDateUpdateResponse = {
          type: "error",
          shipDate: this.dto.shipDate,
          alert: {
            type: "danger",
            title: "Shipping Date Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateShipDate.emit(shipDateUpdateResponse);
      }
    );
  }

  public isDisabledDate(date) {
    let dateAsDate = new Date(date.year, date.month - 1, date.day);
    return dateAsDate.getDay() !== 1;
  }
}

enum ModalActions {
  Edit = "Edit",
}
