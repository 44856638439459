<div class="modal-header">
    <h4 class="modal-title pull-left">{{getModalHeader()}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <form class="form" #form="ngForm">
        <div class="row">
            <div class="col-12 ship-to-container">
                <span><strong>Ship To</strong></span>
                <span>{{dto.companyName}}</span>
                <span *ngIf="dto.street1">{{dto.street1}}</span>
                <span *ngIf="dto.steeet2">{{dto.steet2}}</span>
                <span>{{dto.city}}, {{dto.state}} {{dto.zip}}</span>
            </div>
        <div class="col-6 form-group">
            <label for="shippingRate">Shipping Rate (%)</label>
            <input required isBetween="0:100" type="number" class="form-control" placeholder="Shipping Rate %" id="shippingRate"
              name="shippingRate" [ngModel]="dto.shippingRate" #shippingRate="ngModel">
            <div class="form-alert" *ngIf="shippingRate.touched && !shippingRate.valid">
              <div *ngIf="shippingRate.errors.required">
                Shipping rate is required.
              </div>
              <div *ngIf="shippingRate.errors.isBetween">
                Shipping rate should be a number between {{shippingRate.errors.isBetween.minAllowed}} and
                {{shippingRate.errors.isBetween.maxAllowed}}.
              </div>
            </div>
          </div>
      </div>

      <div class="modal-button-group form-group">
          <button *ngIf="modalAction === 'Edit'" class="btn btn-primary" (click)="update(form.value)" [disabled]="!form.valid">Update</button>
          <button class="btn btn-default float-right" (click)="closeModal()">Cancel</button>
        </div>
    </form>
  </div>