import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BoxSkidService } from "../shared/services/boxskid.service";
import { IBoxSkid } from "../shared/interfaces/boxskid.interface";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { BoxSkidModalComponent } from "../modals/boxskid-modal/boxsid-modal.component";

@Component({
  selector: "app-boxskids",
  templateUrl: "./boxskids.component.html",
  styleUrls: ["./boxskids.component.css"],
})
export class BoxSkidsComponent implements OnInit {
  constructor(
    private _boxSkidService: BoxSkidService,
    private _modalService: NgbModal
  ) {}

  public boxSkids: Array<IBoxSkid>;
  public matchingBoxSkids: Array<IBoxSkid>;
  public boxSkidsShowing: Array<IBoxSkid>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 10;
  public totalItems: number;

  //Filter Vars
  public filters = {
    name: "",
    description: "",
    upc: "",
    itemNo: "",
    display: "",
    adminOnly: "",
    notes: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._boxSkidService.getAll().subscribe(
      (boxSkids: Array<IBoxSkid>) => {
        this.boxSkids = boxSkids;
        this.matchingBoxSkids = this.boxSkids;
        this.boxSkidsShowing = this.boxSkids.slice(0, this.itemsPerPage);
        this.totalItems = boxSkids.length;
      },
      (error) => {
        console.log("There was an error getting box skids.");
      }
    );
  }
  public clearToastAlert() {
    this.alert = null;
  }

  public pageChanged(event: any): void {
    this.boxSkidsShowing = this.matchingBoxSkids.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(BoxSkidModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.createBoxSkid.subscribe((event) => {
      this.onCreateBoxSkid(event);
    });
  }

  public startUpdate(boxSkid) {
    const modalRef = this._modalService.open(BoxSkidModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.boxSkid = boxSkid;
    modalRef.componentInstance.deleteBoxSkid.subscribe((event) => {
      this.onDeleteBoxSkid(event);
    });
    modalRef.componentInstance.updateBoxSkid.subscribe((event) => {
      this.onUpdateBoxSkid(event);
    });
  }

  public onCreateBoxSkid(e) {
    if (e.type === "success") {
      this.boxSkids.push(e.boxSkid);
      this.alert = e.alert;
      this.boxSkidsShowing = this.boxSkids.slice(0, this.itemsPerPage);
      this.totalItems = this.boxSkids.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteBoxSkid(e) {
    if (e.type === "success") {
      let index = this.boxSkids.findIndex(
        (boxSkid) => boxSkid.id == e.boxSkid.id
      );
      this.boxSkids.splice(index, 1);
      this.boxSkidsShowing = this.boxSkids.slice(0, this.itemsPerPage);
      this.totalItems = this.boxSkids.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateBoxSkid(e) {
    if (e.type === "success") {
      let updatedBoxSkidIndex = this.boxSkids.findIndex(
        (boxSkid) => boxSkid.id === e.boxSkid.id
      );

      this.boxSkids[updatedBoxSkidIndex] = e.boxSkid;

      let updatedBoxSkidShowingIndex = this.boxSkidsShowing.findIndex(
        (boxSkid) => boxSkid.id === e.boxSkid.id
      );
      this.boxSkidsShowing[updatedBoxSkidShowingIndex] =
        this.boxSkids[updatedBoxSkidIndex];

      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingBoxSkids = this.boxSkids.filter(
      (boxSkid) =>
        this.applyBoxSkidNameFilter(boxSkid) &&
        this.applyBoxSkidDescriptionFilter(boxSkid) &&
        this.applyBoxSkidUPCFilter(boxSkid) &&
        this.applyBoxSkidItemNoFilter(boxSkid) &&
        (this.filters.display
          ? (boxSkid.display ? "Y" : "N") == this.filters.display.toUpperCase()
          : true) &&
        (this.filters.adminOnly
          ? (boxSkid.adminOnly ? "Y" : "N") ==
            this.filters.adminOnly.toUpperCase()
          : true)
    );

    this.boxSkidsShowing = this.matchingBoxSkids.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingBoxSkids.length;
  }

  private applyBoxSkidNameFilter(boxSkid) {
    if (this.filters.name) {
      if (boxSkid.name) {
        return boxSkid.name
          .toUpperCase()
          .includes(this.filters.name.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyBoxSkidDescriptionFilter(boxSkid) {
    if (this.filters.description) {
      if (boxSkid.description) {
        return boxSkid.description
          .toUpperCase()
          .includes(this.filters.description.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyBoxSkidUPCFilter(boxSkid) {
    if (this.filters.upc) {
      if (boxSkid.upc) {
        return boxSkid.upc
          .toUpperCase()
          .includes(this.filters.upc.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyBoxSkidItemNoFilter(boxSkid) {
    if (this.filters.itemNo) {
      if (boxSkid.itemNo) {
        return boxSkid.itemNo
          .toUpperCase()
          .includes(this.filters.itemNo.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public clearFilters() {
    this.filters.name = "";
    this.filters.description = "";
    this.filters.upc = "";
    this.filters.itemNo = "";
    this.filters.display = "";
    this.filters.adminOnly = "";
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingBoxSkids.length > 0) {
      let headers =
        "id,upc,itemNo,name,description,costEach,numBoxesOnSkid,totalPiecesOnSkid,dispaly,adminOnly";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingBoxSkids.length; i++) {
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].id ? "" : this.matchingBoxSkids[i].id) +
          '",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].upc ? "" : this.matchingBoxSkids[i].upc) +
          '",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].itemNo
            ? ""
            : this.matchingBoxSkids[i].itemNo) +
          '",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].name
            ? ""
            : this.matchingBoxSkids[i].name.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].description
            ? ""
            : this.matchingBoxSkids[i].description.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].eachCost
            ? ""
            : this.matchingBoxSkids[i].eachCost) +
          '",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].boxQty
            ? ""
            : this.matchingBoxSkids[i].boxQty) +
          '",';
        csv +=
          '"' +
          (!this.matchingBoxSkids[i].itemQty
            ? ""
            : this.matchingBoxSkids[i].itemQty) +
          '",';
        csv += '"' + (this.matchingBoxSkids[i].display ? "Y" : "N") + '",';
        csv += '"' + (this.matchingBoxSkids[i].adminOnly ? "Y" : "N") + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "prebuilt_skids_of_boxes.csv";

    //@ts-ignore
    if (navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
