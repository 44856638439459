<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [hidden]="!isLoading" style="text-align: center">
    <img src="assets/img/loading.svg" />
  </div>
  <form [hidden]="isLoading" class="form" [formGroup]="boxSkidForm">
    <div class="form-group">
      <label for="name">Name</label>
      <input
        ngbAutofocus
        type="text"
        id="name"
        name="name"
        formControlName="name"
        class="form-control"
      />
      <div
        class="form-alert"
        *ngIf="
          boxSkidForm.get('name').touched && !boxSkidForm.get('name').valid
        "
      >
        <div *ngIf="boxSkidForm.get('name').errors.required">
          Name is required.
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6 form-group">
        <label for="upc">UPC</label>
        <input
          required
          type="text"
          id="upc"
          name="upc"
          formControlName="upc"
          class="form-control"
        />
        <div
          class="form-alert"
          *ngIf="
            boxSkidForm.get('upc').touched && !boxSkidForm.get('upc').valid
          "
        >
          <div *ngIf="boxSkidForm.get('upc').errors.required">
            UPC is required.
          </div>
        </div>
      </div>

      <div class="col-6 form-group">
        <label for="itemNo">Item No.</label>
        <input
          required
          type="text"
          id="itemNo"
          name="itemNo"
          formControlName="itemNo"
          class="form-control"
        />
        <div
          class="form-alert"
          *ngIf="
            boxSkidForm.get('itemNo').touched &&
            !boxSkidForm.get('itemNo').valid
          "
        >
          <div *ngIf="boxSkidForm.get('itemNo').errors.required">
            Item No. is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4 switch-group">
        <label class="switch-label">Display for Sale</label><br />
        <ui-switch name="display" formControlName="display"></ui-switch>
      </div>
      <div class="col-4 switch-group">
        <label class="switch-label">Admin Only</label><br />
        <ui-switch name="adminOnly" formControlName="adminOnly"></ui-switch>
      </div>
      <div class="col-4">
        <p>Box Skid Cost</p>
        <p>
          {{ getBoxSkidCost() | currency : "USD" : "symbol" : "1.2-2" }}
        </p>
      </div>
    </div>
    <hr />

    <div formArrayName="products">
      <div class="row">
        <div class="col-12">
          <h3>Products</h3>
        </div>
      </div>

      <ng-container *ngFor="let product of products.controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="row">
            <div class="col-5 form-group">
              <label for="productSelect-{{ i }}">Product</label>
              <select
                class="form-control"
                id="productSelect-{{ i }}"
                (change)="changeProduct($event)"
                formControlName="productId"
              >
                <option value="" disabled>Choose Boxable Product</option>
                <option
                  *ngFor="let productOpt of productOptions"
                  value="{{ productOpt.id }}"
                >
                  {{ productOpt.name }}
                </option>
              </select>
            </div>

            <div class="col-2 form-group">
              <label for="boxQty">Box Qty</label>
              <input
                type="number"
                id="boxQty"
                name="boxQty"
                formControlName="boxQty"
                class="form-control"
              />
            </div>

            <div class="col-4 form-group">
              <label for="boxEachCost">Box Cost (ea.)</label>
              <input
                type="number"
                id="boxEachCost"
                name="boxEachCost"
                formControlName="boxEachCost"
                class="form-control"
              />
              <div
                class="form-alert"
                *ngIf="
                  product.get('boxEachCost').touched &&
                  !product.get('boxEachCost').valid
                "
              >
                <div *ngIf="product.get('boxEachCost').errors.required">
                  Box Each Cost is required.
                </div>
                <div *ngIf="product.get('boxEachCost').errors.min">
                  Each Cost has to be greater than or equal to
                  {{ product.get("boxEachCost").errors.min.min }}.
                </div>
              </div>
            </div>

            <div class="col-1">
              <button
                *ngIf="products.length > 1"
                class="btn btn-danger"
                (click)="removeProduct(i)"
              >
                X
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="margin-bottom: 1rem">
              {{ productMsg(product) }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary" (click)="addProduct()">
            Add Product
          </button>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="boxSkidImage">Box Skid Image</label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            class="form-control-file"
            id="boxSkidImage"
            (change)="updateBoxSkidImage($event.target.files)"
          />
        </div>
        <img
          class="boxskid-image"
          *ngIf="
            boxSkid.BoxSkidImages ? boxSkid.BoxSkidImages.length > 0 : false
          "
          alt="BoxSkid-Image"
          src="{{ boxSkid.BoxSkidImages[0].imageUrl }}"
        />
        <p *ngIf="imageChanged">
          After you save, the new image will be available.
        </p>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Create'"
        class="btn btn-primary"
        (click)="add()"
        [disabled]="!boxSkidForm.valid"
      >
        Create Box Skid
      </button>
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update()"
        [disabled]="!boxSkidForm.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete Box Skid
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete: {{ boxSkid.upc }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to delete Box Skid "{{ boxSkid.name }}".</p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>

    <div class="form-group">
      <button class="btn btn-primary" (click)="delete(boxSkid.id)">
        Confirm Delete
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>
