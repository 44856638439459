import {Component} from '@angular/core';

@Component({
    selector: 'disabled',
    templateUrl: './disabled.component.html',
    styleUrls: ['./disabled.component.css']
})
 
export class DisabledComponent {
 
    constructor() {}
 
}