import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

let env = environment.production ? 'production' : 'development';
import * as config from '../../../config.json';

@Injectable()
export class ConfigService {

  constructor() {
    this._apiUrl = config[env].apiUrl;
    this._staticFileServerUrl = config[env].staticFileServerUrl;
  }

  private _apiUrl: string;
  private _staticFileServerUrl: string;

  public getApiUrl(): string {
    return this._apiUrl;
  }

  public getStaticFileServerUrl(): string {
    return this._staticFileServerUrl;
  }

}