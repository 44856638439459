<div class="container">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    {{ viewType }} <small class="text-muted">({{ orders.length }})</small>
    <fa-icon
      *ngIf="isAdmin()"
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <table class="table table-striped table-sm">
    <tr>
      <th class="small-col">Id</th>
      <th *ngIf="isAdmin()">S</th>
      <th *ngIf="isAdmin()">B</th>
      <th>Placed By</th>
      <th>Racks</th>
      <th>Boxes</th>
      <th>Skids</th>
      <th>Product Total</th>
      <th>Shipping Total</th>
      <th>Order Total</th>
      <th>
        Requested Ship Date
        <fa-icon
          (click)="sortMatchingOrders('shipDate')"
          *ngIf="sortAsc && currentSortBy == 'shipDate'"
          class="sort-icons"
          [icon]="sortAscIcon"
        ></fa-icon>
        <fa-icon
          (click)="sortMatchingOrders('shipDate')"
          *ngIf="!sortAsc && currentSortBy == 'shipDate'"
          class="sort-icons"
          [icon]="sortDescIcon"
        ></fa-icon>
      </th>
      <th *ngIf="isAdmin()">Picked</th>
      <th *ngIf="isAdmin()">Shipped</th>
      <th *ngIf="isAdmin()">Invoiced</th>
    </tr>
    <tr id="tableControls">
      <td class="small-col">
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.id"
          placeholder="Filter by Id"
        />
      </td>
      <td *ngIf="isAdmin()"></td>
      <td *ngIf="isAdmin()"></td>
      <td>
        <input
          *ngIf="isAdmin()"
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.companyName"
          placeholder="Filter by Company"
        />
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td *ngIf="isAdmin()"></td>
      <td *ngIf="isAdmin()"></td>
      <td *ngIf="isAdmin()"></td>
    </tr>
    <tr class="data-row" [id]="order.id" *ngFor="let order of ordersShowing">
      <td class="small-col">
        <a routerLink="/orders/{{ order.id }}">{{ order.id | shortId }}</a
        ><br />
        {{ order.placedDate | date : "short" }}
      </td>
      <td *ngIf="isAdmin()">
        <fa-icon
          *ngIf="order.shippingAddressId == null || order.shippingRate == null"
          class="error-icon"
          [icon]="errorIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="
            !(order.shippingAddressId == null || order.shippingRate == null)
          "
          class="check-icon"
          [icon]="checkIcon"
        ></fa-icon>
      </td>
      <td *ngIf="isAdmin()">
        <fa-icon
          *ngIf="order.billingAddressId == null"
          class="error-icon"
          [icon]="errorIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="!(order.billingAddressId == null)"
          class="check-icon"
          [icon]="checkIcon"
        ></fa-icon>
      </td>
      <td>
        {{ order.placedBy }}<br />

        <span *ngIf="isAdmin()">{{ order.companyName }}</span
        ><br />
        <span
          class="shippingCompanyName"
          *ngIf="order.companyName != order.shippingCompanyName"
          >({{ order.shippingCompanyName }})</span
        >
      </td>
      <td>
        {{
          order.prebuiltRacksOrdered +
            (order.halfShelvesOrdered + order.prebuiltHalfShelvesOrdered) /
              this.halfShelvesPerRack
        }}
      </td>
      <td>{{ order.boxesOrdered }}</td>
      <td>{{ order.boxSkidsOrdered }}</td>
      <td>{{ order.productTotal | currency : "USD" : "symbol" : "1.2-2" }}</td>

      <td *ngIf="order.shippingRate != null">
        {{ order.shippingTotal | currency : "USD" : "symbol" : "1.2-2" }}
      </td>
      <td *ngIf="order.shippingRate == null">TBD</td>

      <td *ngIf="order.shippingRate != null">
        {{
          order.productTotal +
            order.shippingTotal +
            order.liftGateCharge -
            order.customerDiscountAmt -
            order.orderDiscountAmt -
            order.credit | currency : "USD" : "symbol" : "1.2-2"
        }}
      </td>
      <td *ngIf="order.shippingRate == null">TBD</td>
      <td>{{ order.shipDate | date : "shortDate" }}</td>
      <td *ngIf="isAdmin()">
        <input
          (change)="onOrderChanged(order, 'isPicked')"
          type="checkbox"
          id="isPicked"
          name="isPicked"
          [(ngModel)]="order.isPicked"
        />
      </td>
      <td *ngIf="isAdmin()">
        <input
          (change)="onOrderChanged(order, 'isShipped')"
          type="checkbox"
          id="isShipped"
          name="isShipped"
          [(ngModel)]="order.isShipped"
        />
      </td>
      <td *ngIf="isAdmin()">
        <input
          (change)="onOrderChanged(order, 'isInvoiced')"
          type="checkbox"
          id="isInvoiced"
          name="isInvoiced"
          [(ngModel)]="order.isInvoiced"
        />
      </td>
    </tr>
  </table>

  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
  <button
    *ngIf="isAdmin()"
    class="btn btn-default not-picked-btn"
    (click)="startLaunchReport('not-picked-details')"
  >
    Get Not Picked Report (Details)
  </button>
  <button
    *ngIf="isAdmin()"
    class="btn btn-default"
    (click)="startLaunchReport('not-picked-group')"
  >
    Get Not Picked Report (Group)
  </button>
  <button
    *ngIf="isAdmin()"
    class="btn btn-default not-invoiced-btn"
    (click)="downloadOrdersNotInvoiced()"
  >
    Get Not Invoiced Report
  </button>
</div>
