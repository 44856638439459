<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="col-12 form-group">
      <label for="name">Name</label>
      <input
        required
        type="text"
        class="form-control"
        placeholder="company name"
        id="name"
        name="name"
        [ngModel]="modalAction === 'Create' ? '' : company.name"
        #name="ngModel"
      />
      <div class="form-alert" *ngIf="name.touched && !name.valid">
        <div *ngIf="name.errors.required">Customer name is required.</div>
      </div>
    </div>

    <div class="col-12 form-group">
      <label for="discount">Discount %</label>
      <input
        required
        type="number"
        class="form-control"
        id="discount"
        name="discount"
        [ngModel]="modalAction === 'Create' ? '' : company.discount"
        #discount="ngModel"
      />
      <div class="form-alert" *ngIf="discount.touched && !discount.valid">
        <div *ngIf="discount.errors.required">
          Discount is required, enter "0" if no discount should be applied to
          their orders.
        </div>
      </div>
    </div>
    <div class="col-12 form-group">
      <label for="notes">Notes</label>
      <textarea
        rows="5"
        id="notes"
        name="notes"
        [ngModel]="modalAction === 'Create' ? '' : company.notes"
        #notes="ngModel"
        class="form-control"
      ></textarea>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Create'"
        class="btn btn-primary"
        (click)="add(form.value)"
        [disabled]="!form.valid"
      >
        Create Customer
      </button>
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update(form.value)"
        [disabled]="!form.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete Customer
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete: {{ company.name }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>
        Are you sure you want to delete customer "{{ company.name }}". This will
        permanently delete the customer and all of its associated addresses and
        user accounts.
      </p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>
    <form #deleteForm="ngForm">
      <div class="form-group">
        <input type="number" hidden [ngModel]="company.id" name="id" id="id" />
        <button class="btn btn-primary" (click)="delete(deleteForm.value.id)">
          Confirm Delete
        </button>
        <button class="btn btn-default float-right" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
