<nav
  *ngIf="loggedIn()"
  class="navbar navbar-expand-md navbar-light bg-light fixed-top"
>
  <a class="navbar-brand">
    <img src="../../assets/img/logo.png" height="45" alt="" />
  </a>
  <button
    class="navbar-toggler hidden-sm-up"
    type="button"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault"
    aria-controls="navbarsDefault"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    [ngbCollapse]="isNavbarCollapsed"
    class="collapse navbar-collapse"
    id="navbarsDefault"
  >
    <ul class="navbar-nav mr-auto">
      <li *ngIf="isAdmin()" class="nav-item dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="id01" ngbDropdownToggle
          >Admin</a
        >
        <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/orders">Orders (Current)</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              class="dropdown-item"
              routerLink="/orders"
              [queryParams]="{ view: 'all' }"
              >Orders (All)</a
            >
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/products">Products</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/racks">Racks</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/halfshelves">Half Shelves</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/boxskids"
              >Prebuilt Skids of Boxes</a
            >
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/users">Users</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/customers">Customers</a>
          </li>
          <li
            role="menu-item"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="dropdown-item" routerLink="/addresses">Addresses</a>
          </li>
        </div>
      </li>
      <li
        class="nav-item"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" routerLink="/store">Store</a>
      </li>
      <li
        class="nav-item"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" routerLink="/cart">Cart</a>
      </li>
      <li
        class="nav-item"
        *ngIf="isCustomer()"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" routerLink="/orders">Orders</a>
      </li>
      <li
        class="nav-item"
        *ngIf="loggedIn()"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" routerLink="/login">Logout</a>
      </li>
    </ul>
  </div>
</nav>
