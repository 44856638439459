<div class="order-summary-container">
  <h5>Order Summary</h5>
  <table class="table">
    <tr>
      <th>Item</th>
      <th>Qty</th>
      <th>Subtotal</th>
    </tr>
    <tr>
      <th>
        Boxes<fa-icon
          class="info-icon"
          popoverClass="info-popover"
          [icon]="infoIcon"
          [ngbPopover]="boxHelpInfoMsg"
          triggers="mouseenter:mouseleave"
          [popoverTitle]="boxHelpInfoTitle"
        ></fa-icon>
      </th>
      <td>{{ cart.totalNumOfBoxesFromRegularAndPrebuilt }}</td>
      <td>
        {{
          cart.boxTotal + cart.boxSkidTotal
            | currency : "USD" : "symbol" : "1.2-2"
        }}
      </td>
    </tr>
    <tr>
      <th>
        Racks<fa-icon
          class="info-icon"
          popoverClass="info-popover"
          [icon]="infoIcon"
          [ngbPopover]="rackHelpInfoMsg"
          triggers="mouseenter:mouseleave"
          [popoverTitle]="rackHelpInfoTitle"
        ></fa-icon>
      </th>
      <td>{{ cart.rackQtyTotal }}</td>
      <td>{{ cart.rackTotal | currency : "USD" : "symbol" : "1.2-2" }}</td>
    </tr>
    <tr>
      <th colspan="2">
        Product Total<fa-icon
          class="info-icon"
          popoverClass="info-popover"
          [icon]="infoIcon"
          [ngbPopover]="totalHelpInfoMsg"
          triggers="mouseenter:mouseleave"
          [popoverTitle]="totalHelpInfoTitle"
        ></fa-icon>
      </th>
      <th style="text-align: right">
        {{ cart.productTotal | currency : "USD" : "symbol" : "1.2-2" }}
      </th>
    </tr>
  </table>
  <app-order-requirements
    *ngIf="cart"
    [cart]="cart"
    [changeCount]="changeCount"
  ></app-order-requirements>
  <button class="review-order-btn btn" routerLink="/cart">Review Order</button>
</div>
