<div class="product-card">
  <div class="row header">
    <div class="col-5">
      <img
        *ngIf="halfShelf.HalfShelfImages.length > 0"
        src="{{ halfShelf.HalfShelfImages[0].imageUrl }}"
        alt="product-image"
        class="product-image"
      />
      <img
        *ngIf="!(halfShelf.HalfShelfImages.length > 0)"
        src="./assets/img/ic-default-image-green.png"
        alt="product-image"
        class="product-image"
      />
    </div>
    <div class="product-info-container col-7">
      <h5>
        {{ halfShelf.name
        }}<img
          [ngbPopover]="popContent"
          [autoClose]="false"
          triggers="manual"
          #pr="ngbPopover"
          (click)="pr.isOpen() ? pr.close() : pr.open()"
          (shown)="resetValues()"
          placement="bottom"
          popoverClass="inline-order-form"
          [popoverTitle]="popTitle"
          class="add-to-cart-icon"
          src="assets/img/ic-add-to-cart-green.png"
        />
      </h5>

      <p class="product-description">{{ halfShelf.description }}</p>
      <ul class="product-info-list">
        <li>
          <img class="price-icon" src="assets/img/ic-price-gray.png" />
          <span
            >{{
              halfShelf.eachCost | currency: "USD":"symbol":"1.2-2"
            }}
            each</span
          >
        </li>
        <li class="in-cart-text">
          <span *ngIf="halfShelvesOrdered > 0"
            >{{ halfShelvesOrdered }} half shelves
          </span>
          <span *ngIf="halfShelvesOrdered > 0">in cart </span>
        </li>
        <li class="itemNo">{{ halfShelf.itemNo }}</li>
      </ul>
    </div>
  </div>

  <ng-template #popTitle
    ><strong>Order</strong
    ><span (click)="pr.close()" class="close-icon float-right"
      >x</span
    ></ng-template
  >
  <ng-template #popContent>
    <div class="order-form">
      <form [formGroup]="inlineOrderForm">
        <input
          id="halfShelfId"
          name="halfShelfId"
          formControlName="halfShelfId"
          type="text"
          hidden
        />
        <div class="row">
          <p class="col-12"><strong>Half Shelves</strong></p>
          <div class="form-group col-3">
            <label for="halfShelvesOrdered" class="sr-only">Qty</label>
            <input
              id="halfShelvesOrdered"
              class="form-control"
              type="number"
              placeholder="Qty"
              formControlName="halfShelvesOrdered"
              (change)="updateHalfShelvesOrderedCalcs()"
            />
            <div
              class="form-alert"
              *ngIf="
                inlineOrderForm.get('halfShelvesOrdered').touched &&
                !inlineOrderForm.get('halfShelvesOrdered').valid
              "
            >
              <div *ngIf="inlineOrderForm.get('halfShelvesOrdered').errors.min">
                If entered, must be greater than 0.
              </div>
            </div>
          </div>
          <div class="col-3">
            <span>Ea.</span><br />
            <span>{{
              halfShelf.eachCost | currency: "USD":"symbol":"1.2-2"
            }}</span>
          </div>
          <div class="col-3">
            <span>Subtotal</span><br />
            <span>{{
              getHalfShelfTotal() | currency: "USD":"symbol":"1.2-2"
            }}</span>
          </div>
          <div class="col-3">
            <span>Units</span><br />
            <span>{{ getHalfShelfIndividualUnits() }}</span>
          </div>
        </div>
        <div class="row">
          <p class="col-12"><strong>Comments</strong></p>
          <div class="form-group col-12">
            <label for="comments" class="sr-only">Qty</label>
            <textarea
              id="comments"
              name="comments"
              class="form-control"
              placeholder="Enter Notes Here ..."
              formControlName="comments"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="row">
      <div class="col-6">
        <button
          *ngIf="!inCart"
          class="btn btn-primary"
          (click)="addHalfShelf(inlineOrderForm.value, pr)"
          [disabled]="!inlineOrderForm.valid"
        >
          Add To Order
        </button>
        <button
          *ngIf="inCart"
          class="btn btn-primary"
          (click)="updateHalfShelf(inlineOrderForm.value, pr)"
          [disabled]="!inlineOrderForm.valid"
        >
          Update
        </button>
        <button
          *ngIf="inCart"
          class="btn btn-remove"
          (click)="removeHalfShelf(halfShelf.id, pr)"
        >
          Remove
        </button>
      </div>
    </div>
  </ng-template>
</div>
