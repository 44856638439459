import { Component, OnInit } from "@angular/core";
import { OrderService } from "../shared/services/order.service";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../auth/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpdateShippingRateModalComponent } from "../modals/update-shipping-rate-modal/update-shipping-rate-modal.component";
import { ActivatedRoute } from "@angular/router";
import { PickRptDateRangeModalComponent } from "../modals/pick-rpt-date-range-modal/pick-rpt-date-range-modal.component";
import { ReportService } from "./../shared/services/report.service";
import { SHELVES_PER_RACK } from "../shared/data/constants";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent implements OnInit {
  constructor(
    private _orderService: OrderService,
    private _reportService: ReportService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _activatedRoute: ActivatedRoute
  ) {}

  public orders: Array<any> = [];
  public ordersShowing: Array<any> = [];
  public matchingOrders: Array<any> = [];

  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 10;
  public totalItems: number;

  //Filter Vars
  public filters = {
    id: "",
    companyName: "",
  };

  public sortAsc = true;
  public currentSortBy = "shipDate";

  public viewType = "Orders";
  public halfShelvesPerRack = SHELVES_PER_RACK * 2;

  //Icons
  public downloadIcon = faFileDownload;
  public clipboardListIcon = faClipboardList;
  public checkIcon = faCheck;
  public errorIcon = faTimes;
  public sortAscIcon = faSortAmountDown;
  public sortDescIcon = faSortAmountUp;

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params.view === "all" || !this.isAdmin()) {
        this.viewType = "All Orders";
        this._orderService.getAll().subscribe(
          (res: any) => {
            this.orders = res;
            this.matchingOrders = this.orders;
            this.ordersShowing = this.orders.slice(0, this.itemsPerPage);
            this.totalItems = this.orders.length;
          },
          (error) => {
            console.log("There was an error getting your orders.");
          }
        );
      } else {
        this.viewType = "Current Orders";
        this._orderService.getCurrentOrders().subscribe(
          (res: any) => {
            this.orders = res;
            this.matchingOrders = this.orders;
            this.ordersShowing = this.orders.slice(0, this.itemsPerPage);
            this.totalItems = this.orders.length;
          },
          (error) => {
            console.log("There was an error getting your orders.");
          }
        );
      }
    });
  }

  public onOrderChanged(order, flagChanged) {
    this.clearToastAlert();
    this._orderService.update(order).subscribe(
      (res) => {},
      (error) => {
        this.alert = {
          type: "danger",
          title: "Not Updated",
          msg: error.originalError.error.errors[0].description,
        };
        //Undo the change.
        order[flagChanged] = !order[flagChanged];
      }
    );
  }

  public clearToastAlert() {
    this.alert = null;
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public pageChanged(event: any): void {
    this.ordersShowing = this.matchingOrders.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public changeFilter() {
    this.matchingOrders = this.orders.filter(
      (order) =>
        order.id.includes(this.filters.id) &&
        (order.companyName
          .toLowerCase()
          .startsWith(this.filters.companyName.toLowerCase()) ||
          order.shippingCompanyName
            .toLowerCase()
            .startsWith(this.filters.companyName.toLowerCase()))
    );

    this.ordersShowing = this.matchingOrders.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingOrders.length;
  }

  public clearFilters() {
    this.filters.id = "";
  }

  public startUpdateShippingRate(order) {
    const modalRef = this._modalService.open(UpdateShippingRateModalComponent);
    let dto = {
      orderId: order.id,
      addressId: order.shippingAddressId,
      street1: order.shippingStreetAddress1,
      street2: order.shippingStreetAddress2,
      city: order.shippingCity,
      state: order.shippingState,
      zip: order.shippingZip,
    };

    modalRef.componentInstance.dto = dto;
    modalRef.componentInstance.updateShippingRate.subscribe((event) => {
      this.onUpdateShippingRate(event);
    });
  }

  public onUpdateShippingRate(e) {
    if (e.type === "success") {
    } else if (e.type === "error") {
      this.alert = e.alert;
    }
  }

  public sortMatchingOrders(sortField: string, keepSortType?: boolean) {
    this.currentSortBy = sortField;

    if (!keepSortType) {
      this.sortAsc = !this.sortAsc;
    }

    if (this.currentSortBy == "shipDate") {
      if (this.sortAsc) {
        this.matchingOrders.sort((a, b) => {
          a = a[sortField] == null ? "" : a[sortField];
          b = b[sortField] == null ? "" : b[sortField];
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      } else {
        this.matchingOrders.sort((a, b) => {
          a = a[sortField] == null ? "" : a[sortField];
          b = b[sortField] == null ? "" : b[sortField];
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
      }
    } else {
      if (this.sortAsc) {
        this.matchingOrders.sort((a, b) => {
          if (a[sortField] < b[sortField]) return -1;
          if (a[sortField] > b[sortField]) return 1;
          return 0;
        });
      } else {
        this.matchingOrders.sort((a, b) => {
          if (a[sortField] > b[sortField]) return -1;
          if (a[sortField] < b[sortField]) return 1;
          return 0;
        });
      }
    }

    this.ordersShowing = this.matchingOrders.slice(0, this.itemsPerPage);
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingOrders.length > 0) {
      let headers =
        "id,orderDate,requestedShipDate,placedBy,billtoCompany,billingStreetAddress1,billingStreetAddress2,billingPOBox,billingCity,billingState,billingZip,billingContactName,billingContactEmail,billingContactPhone,shiptoCompany,shippingStreetAddress1,shippingStreetAddress2,shippingCity,shippingState,shippingZip,shippingContactName,shippingContactEmail,shippingContactPhone,needShippingReview,needBillingReview,racks,boxes,boxSkids,product,shipping,liftGateCharge,customerDiscountAmt,orderDiscountAmt,credit,order,picked,shipped,orderNote";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingOrders.length; i++) {
        csv +=
          '"' +
          (!this.matchingOrders[i].id ? "" : this.matchingOrders[i].id) +
          '",';
        csv +=
          '"' +
          (!this.matchingOrders[i].placedDate
            ? ""
            : this.matchingOrders[i].placedDate.split("T")[0]) +
          '",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shipDate
            ? ""
            : this.matchingOrders[i].shipDate.split("T")[0]) +
          '",';
        csv +=
          '"' +
          (!this.matchingOrders[i].placedBy
            ? ""
            : this.matchingOrders[i].placedBy) +
          '",';
        csv +=
          '"' +
          (!this.matchingOrders[i].companyName
            ? ""
            : this.matchingOrders[i].companyName.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingStreetAddress1
            ? ""
            : this.matchingOrders[i].billingStreetAddress1.replace(
                /"/g,
                '""'
              )) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingStreetAddress2
            ? ""
            : this.matchingOrders[i].billingStreetAddress2.replace(
                /"/g,
                '""'
              )) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingPOBox
            ? ""
            : this.matchingOrders[i].billingPOBox.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingCity
            ? ""
            : this.matchingOrders[i].billingCity.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingState
            ? ""
            : this.matchingOrders[i].billingState.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingZip
            ? ""
            : this.matchingOrders[i].billingZip.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingContactName
            ? ""
            : this.matchingOrders[i].billingContactName.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingContactEmail
            ? ""
            : this.matchingOrders[i].billingContactEmail.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].billingContactPhone
            ? ""
            : this.matchingOrders[i].billingContactPhone.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingCompanyName
            ? ""
            : this.matchingOrders[i].shippingCompanyName.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingStreetAddress1
            ? ""
            : this.matchingOrders[i].shippingStreetAddress1.replace(
                /"/g,
                '""'
              )) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingStreetAddress2
            ? ""
            : this.matchingOrders[i].shippingStreetAddress2.replace(
                /"/g,
                '""'
              )) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingCity
            ? ""
            : this.matchingOrders[i].shippingCity.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingState
            ? ""
            : this.matchingOrders[i].shippingState.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingZip
            ? ""
            : this.matchingOrders[i].shippingZip.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingContactName
            ? ""
            : this.matchingOrders[i].shippingContactName.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingContactEmail
            ? ""
            : this.matchingOrders[i].shippingContactEmail.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingOrders[i].shippingContactPhone
            ? ""
            : this.matchingOrders[i].shippingContactPhone.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (this.matchingOrders[i].shippingRate == null ||
          this.matchingOrders[i].shippingAddressId == null
            ? "Y"
            : "N") +
          '",';
        csv +=
          '"' +
          (this.matchingOrders[i].billingAddressId == null ? "Y" : "N") +
          '",';
        csv +=
          '"' +
          ((this.matchingOrders[i].halfShelvesOrdered +
            this.matchingOrders[i].prebuiltHalfShelvesOrdered) /
            this.halfShelvesPerRack +
            this.matchingOrders[i].prebuiltRacksOrdered) +
          '",';
        csv += '"' + this.matchingOrders[i].boxesOrdered + '",';
        csv += '"' + this.matchingOrders[i].boxSkidsOrdered + '",';
        csv += '"' + this.matchingOrders[i].productTotal + '",';
        csv +=
          '"' +
          (this.matchingOrders[i].shippingTotal
            ? this.matchingOrders[i].shippingTotal
            : 0) +
          '",';
        csv += '"' + this.matchingOrders[i].liftGateCharge + '",';
        csv += '"' + this.matchingOrders[i].customerDiscountAmt + '",';
        csv += '"' + this.matchingOrders[i].orderDiscountAmt + '",';
        csv += '"' + this.matchingOrders[i].credit + '",';
        csv +=
          '"' +
          (this.matchingOrders[i].productTotal +
            this.matchingOrders[i].shippingTotal +
            this.matchingOrders[i].liftGateCharge -
            this.matchingOrders[i].customerDiscountAmt -
            this.matchingOrders[i].orderDiscountAmt -
            this.matchingOrders[i].credit) +
          '",';
        csv += '"' + (this.matchingOrders[i].isPicked ? "Y" : "N") + '",';
        csv += '"' + (this.matchingOrders[i].isShipped ? "Y" : "N") + '",';
        csv +=
          '"' +
          (!this.matchingOrders[i].note
            ? ""
            : this.matchingOrders[i].note.replace(/"/g, '""')) +
          ' "\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "orders.csv";

    //@ts-ignore
    if (navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  public startLaunchReport(reportName: string) {
    this.clearToastAlert();

    const modalRef = this._modalService.open(PickRptDateRangeModalComponent);
    modalRef.componentInstance.reportName = reportName;
    modalRef.componentInstance.submitted.subscribe(
      ({ reportName, fromDate, toDate, toDateForReportTitle }) => {
        if (reportName === "not-picked-details") {
          this.downloadOrdersNotPicked(fromDate, toDate, toDateForReportTitle);
        } else if (reportName === "not-picked-group") {
          this.downloadOrdersNotPickedGrouped(
            fromDate,
            toDate,
            toDateForReportTitle
          );
        }
      }
    );
  }

  public downloadOrdersNotPicked(fromDate, toDate, toDateForReportTitle) {
    this._reportService.getOrdersNotPicked(fromDate, toDate).subscribe(
      (res: any) => {
        let csv = "";
        if (res.length > 0) {
          let headers =
            "placedDate,requestedShipDate,orderId,billtoCompany,shiptoCompany,line,subline,rackName,rackUpc,prebuiltHalfShelfName,prebuiltHalfShelfUpc,boxSkidName,boxSkidUpc,productUpc,product,description,boxQty,halfShelfQty,prebuiltHalfShelfQty,rackQty,boxPieces,halfShelfPieces,preBuiltHalfShelfPieces,rackPieces,boxSize,qtyPerBox,comments,orderNote";
          csv += headers + "\n";
          for (let i = 0; i < res.length; i++) {
            csv += '"' + (!res[i].date ? "" : res[i].date) + '",';
            csv +=
              '"' +
              (!res[i].requestedShipDate ? "" : res[i].requestedShipDate) +
              '",';
            csv += '"' + (!res[i].orderId ? "" : res[i].orderId) + '",';
            csv +=
              '"' +
              (!res[i].companyName
                ? ""
                : res[i].companyName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingCompanyName
                ? ""
                : res[i].shippingCompanyName.replace(/"/g, '""')) +
              '",';
            csv += '"' + (!res[i].line ? "" : res[i].line) + '",';
            csv += '"' + (!res[i].subline ? "" : res[i].subline) + '",';
            csv +=
              '"' +
              (!res[i].rackName ? "" : res[i].rackName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' + (!res[i].rackUpc ? "" : "*" + res[i].rackUpc + "*") + '",';
            csv +=
              '"' +
              (!res[i].prebuiltHalfShelfName
                ? ""
                : res[i].prebuiltHalfShelfName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].prebuiltHalfShelfUpc
                ? ""
                : "*" + res[i].prebuiltHalfShelfUpc + "*") +
              '",';
            csv +=
              '"' +
              (!res[i].boxSkidName
                ? ""
                : res[i].boxSkidName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].boxSkidUpc ? "" : "*" + res[i].boxSkidUpc + "*") +
              '",';
            csv +=
              '"' +
              (!res[i].productUpc ? "" : "*" + res[i].productUpc + "*") +
              '",';
            csv +=
              '"' +
              (!res[i].productName
                ? ""
                : res[i].productName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].productDescription
                ? ""
                : res[i].productDescription.replace(/"/g, '""')) +
              '",';
            csv += '"' + (!res[i].boxQty ? "0" : res[i].boxQty) + '",';
            csv +=
              '"' + (!res[i].halfShelfQty ? "0" : res[i].halfShelfQty) + '",';
            csv +=
              '"' +
              (!res[i].prebuiltHalfShelfQty
                ? "0"
                : res[i].prebuiltHalfShelfQty) +
              '",';
            csv += '"' + (!res[i].rackQty ? "0" : res[i].rackQty) + '",';
            csv += '"' + (!res[i].boxPieces ? "0" : res[i].boxPieces) + '",';
            csv +=
              '"' +
              (!res[i].halfShelfPieces ? "0" : res[i].halfShelfPieces) +
              '",';
            csv +=
              '"' +
              (!res[i].prebuiltHalfShelfPieces
                ? "0"
                : res[i].prebuiltHalfShelfPieces) +
              '",';
            csv += '"' + (!res[i].rackPieces ? "0" : res[i].rackPieces) + '",';
            csv += '"' + (!res[i].boxSize ? "n/a" : res[i].boxSize) + '",';
            csv += '"' + (!res[i].qtyPerBox ? "n/a" : res[i].qtyPerBox) + '",';
            csv +=
              '"' +
              (!res[i].comments ? "" : res[i].comments.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].note ? "" : res[i].note.replace(/"/g, '""')) +
              '"\n';
          }
        }
        let blob = new Blob([csv], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let fileName =
          "orders-not-picked_" +
          fromDate +
          "_to_" +
          toDateForReportTitle +
          ".csv";
        //@ts-ignore
        if (navigator.msSaveOrOpenBlob) {
          //@ts-ignore
          navigator.msSaveBlob(blob, fileName);
        } else {
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.log("There was an error getting the not picked report.");
      }
    );
  }

  public downloadOrdersNotPickedGrouped(
    fromDate,
    toDate,
    toDateForReportTitle
  ) {
    this._reportService.getOrdersNotPickedGrouped(fromDate, toDate).subscribe(
      (res: any) => {
        let csv = "";

        if (res.length > 0) {
          let headers = "Item,TotalPieces";
          csv += headers + "\n";
          for (let i = 0; i < res.length; i++) {
            csv +=
              '"' +
              (!res[i].productName
                ? ""
                : res[i].productName.replace(/"/g, '""')) +
              '",';
            csv += '"' + (!res[i].totalPieces ? 0 : res[i].totalPieces) + '"\n';
          }
        }

        let blob = new Blob([csv], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let fileName =
          "orders-not-picked-grouped_" +
          fromDate +
          "_to_" +
          toDateForReportTitle +
          ".csv";

        //@ts-ignore
        if (navigator.msSaveOrOpenBlob) {
          //@ts-ignore
          navigator.msSaveBlob(blob, fileName);
        } else {
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.log(
          "There was an error getting the not picked grouped report."
        );
      }
    );
  }

  public downloadOrdersNotInvoiced() {
    this._reportService.getOrdersNotInvoiced().subscribe(
      (res: any) => {
        let csv = "";

        if (res.length > 0) {
          let headers =
            "invoiceId,	placedDate, requestedShipDate, placedBy, billToCompanyId, billtoCompany, billingStreetAddress1, billingStreetAddress2, billingPOBox, billingCity, billingState, billingZip, shiptoCompany, shippingStreetAddress1, shippingStreetAddress2, shippingCity, shippingState, shippingZip, shippingRate, shippingTotal, customerDiscountRate, customerDiscountAmt, orderDiscountRate, orderDiscountAmt, credit, liftGateCharge, productTotal, orderTotal,  billingContactName, billingContactEmail, billingContactPhone, shippingContactName, shippingContactEmail, shippingContactPhone, line, productId, upc, itemNo, productName, productDescription, categoryName, eachCost, qtyPerBox, qtyPerHalfShelf, boxQty, halfShelfQty, prebuiltRackQty, prebuiltHalfShelfQty, boxSkidQty, totalPieces, lineTotalBeforeDiscount, comments";
          csv += headers + "\n";
          for (let i = 0; i < res.length; i++) {
            csv += '"' + (!res[i].invoiceId ? "" : res[i].invoiceId) + '",';
            csv += '"' + (!res[i].placedDate ? "" : res[i].placedDate) + '",';
            csv += '"' + (!res[i].shipDate ? "" : res[i].shipDate) + '",';
            csv += '"' + (!res[i].placedBy ? "" : res[i].placedBy) + '",';
            csv +=
              '"' +
              (!res[i].billToCompanyId ? "" : res[i].billToCompanyId) +
              '",';
            csv +=
              '"' +
              (!res[i].companyName
                ? ""
                : res[i].companyName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingStreetAddress1
                ? ""
                : res[i].billingStreetAddress1.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingStreetAddress2
                ? ""
                : res[i].billingStreetAddress2.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingPOBox
                ? ""
                : res[i].billingPOBox.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingCity
                ? ""
                : res[i].billingCity.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingState
                ? ""
                : res[i].billingState.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingZip
                ? ""
                : res[i].billingZip.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingCompanyName
                ? ""
                : res[i].shippingCompanyName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingStreetAddress1
                ? ""
                : res[i].shippingStreetAddress1.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingStreetAddress2
                ? ""
                : res[i].shippingStreetAddress2.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingCity
                ? ""
                : res[i].shippingCity.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingState
                ? ""
                : res[i].shippingState.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingZip
                ? ""
                : res[i].shippingZip.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' + (!res[i].shippingRate ? "" : res[i].shippingRate) + '",';
            csv +=
              '"' + (!res[i].shippingTotal ? "" : res[i].shippingTotal) + '",';
            csv +=
              '"' +
              (!res[i].customerDiscount ? "" : res[i].customerDiscount) +
              '",';
            csv +=
              '"' +
              (!res[i].customerDiscountAmt ? "" : res[i].customerDiscountAmt) +
              '",';
            csv +=
              '"' + (!res[i].orderDiscount ? "" : res[i].orderDiscount) + '",';
            csv +=
              '"' +
              (!res[i].orderDiscountAmt ? "" : res[i].orderDiscountAmt) +
              '",';
            csv += '"' + (!res[i].credit ? "" : res[i].credit) + '",';
            csv +=
              '"' +
              (!res[i].liftGateCharge ? "" : res[i].liftGateCharge) +
              '",';
            csv +=
              '"' + (!res[i].productTotal ? "" : res[i].productTotal) + '",';
            csv += '"' + (!res[i].orderTotal ? "" : res[i].orderTotal) + '",';
            csv +=
              '"' +
              (!res[i].billingContactName
                ? ""
                : res[i].billingContactName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingContactEmail
                ? ""
                : res[i].billingContactEmail.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].billingContactPhone
                ? ""
                : res[i].billingContactPhone.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingContactName
                ? ""
                : res[i].shippingContactName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingContactEmail
                ? ""
                : res[i].shippingContactEmail.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].shippingContactPhone
                ? ""
                : res[i].shippingContactPhone.replace(/"/g, '""')) +
              '",';
            csv += '"' + (!res[i].line ? "" : res[i].line) + '",';
            csv += '"' + (!res[i].productId ? "" : res[i].productId) + '",';
            csv += '"' + (!res[i].upc ? "" : res[i].upc) + '",';
            csv += '"' + (!res[i].itemNo ? "" : res[i].itemNo) + '",';
            csv +=
              '"' +
              (!res[i].productName
                ? ""
                : res[i].productName.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].productDescription
                ? ""
                : res[i].productDescription.replace(/"/g, '""')) +
              '",';
            csv +=
              '"' +
              (!res[i].categoryName
                ? ""
                : res[i].categoryName.replace(/"/g, '""')) +
              '",';
            csv += '"' + (!res[i].eachCost ? "0" : res[i].eachCost) + '",';
            csv += '"' + (!res[i].qtyPerBox ? "0" : res[i].qtyPerBox) + '",';
            csv +=
              '"' +
              (!res[i].qtyPerHalfShelf ? "0" : res[i].qtyPerHalfShelf) +
              '",';
            csv += '"' + (!res[i].boxQty ? "0" : res[i].boxQty) + '",';
            csv +=
              '"' + (!res[i].halfShelfQty ? "0" : res[i].halfShelfQty) + '",';
            csv +=
              '"' +
              (!res[i].prebuiltRackQty ? "0" : res[i].prebuiltRackQty) +
              '",';
            csv +=
              '"' +
              (!res[i].prebuiltHalfShelfQty
                ? "0"
                : res[i].prebuiltHalfShelfQty) +
              '",';
            csv += '"' + (!res[i].boxSkidQty ? "0" : res[i].boxSkidQty) + '",';
            csv +=
              '"' + (!res[i].totalPieces ? "0" : res[i].totalPieces) + '",';
            csv +=
              '"' +
              (!res[i].lineTotalBeforeDiscount
                ? "0"
                : res[i].lineTotalBeforeDiscount) +
              '",';
            csv +=
              '"' +
              (!res[i].comments ? "" : res[i].comments.replace(/"/g, '""')) +
              '"\n';
          }
        }

        let blob = new Blob([csv], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let fileName = "orders-not-invoiced.csv";

        //@ts-ignore
        if (navigator.msSaveOrOpenBlob) {
          //@ts-ignore
          navigator.msSaveBlob(blob, fileName);
        } else {
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.log("There was an error getting the not picked report.");
      }
    );
  }
}
