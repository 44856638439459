<div class="container">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    Users<fa-icon
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <table class="table table-striped table-sm">
    <tr>
      <th>Email</th>
      <th>Role</th>
      <th>Company</th>
      <th>First Name</th>
      <th>Last Name</th>
    </tr>
    <tr id="tableControls">
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.email"
          placeholder="Filter by Email"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.role"
          placeholder="Filter by Role"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.company"
          placeholder="Filter by Company"
        />
      </td>
      <td colspan="2"></td>
    </tr>
    <tr
      class="data-row"
      [id]="user.id"
      (click)="startUpdate(user)"
      *ngFor="let user of usersShowing"
    >
      <td>{{ user.email }}</td>
      <td>{{ getRoles(user) }}</td>
      <td>{{ user.Company ? user.Company.name : "" }}</td>
      <td>{{ user.firstName }}</td>
      <td>{{ user.lastName }}</td>
    </tr>
  </table>

  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
  <button (click)="startAdd()" class="btn btn-primary">Add User</button>
</div>
