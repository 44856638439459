import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/auth/services/auth.service";
import { OrderService } from "src/app/shared/services/order.service";

@Component({
  selector: "app-edit-po-number-modal",
  templateUrl: "./edit-po-number-modal.component.html",
  styleUrls: ["./edit-po-number-modal.component.css"],
})
export class EditPoNumberModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _orderService: OrderService
  ) {}

  @Input() order: { orderId: string; poNumber: string };
  @Input() info: any;
  @Output() updatePONumber = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return "Edit Order's PO Number";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public submitChanges(f) {
    let dto = {
      orderId: f.orderId,
      poNumber: f.poNumber,
    };

    this._orderService.updateOrderPONumber(dto).subscribe(
      (res: any) => {
        let updatePONumberResponse = {
          type: "success",
          poNumber: res.poNumber,
          alert: {
            type: "success",
            title: "PO Number Updated",
            msg: res.message,
          },
        };
        this.updatePONumber.emit(updatePONumberResponse);
        this.activeModal.close();
      },
      (error) => {
        let updatePONumberResponse = {
          type: "error",
          poNumber: dto.poNumber,
          alert: {
            type: "danger",
            title: "PO Number Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.updatePONumber.emit(updatePONumberResponse);
        this.activeModal.close();
      }
    );
  }
}
