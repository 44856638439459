import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderService } from "src/app/shared/services/order.service";

@Component({
  selector: "app-edit-order-note-modal",
  templateUrl: "./edit-order-note-modal.component.html",
  styleUrls: ["./edit-order-note-modal.component.css"],
})
export class EditOrderNoteModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _orderService: OrderService
  ) {}

  @Input() order: { orderId: string; note: string };
  @Input() info: any;
  @Output() updateOrderNote = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return "Edit Order's Note";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public submitChanges(f) {
    let dto = {
      orderId: f.orderId,
      note: f.note,
    };

    this._orderService.updateOrderNote(dto).subscribe(
      (res: any) => {
        let updateOrderNoteResponse = {
          type: "success",
          note: res.note,
          alert: {
            type: "success",
            title: "Order Note Updated",
            msg: res.message,
          },
        };
        this.updateOrderNote.emit(updateOrderNoteResponse);
        this.activeModal.close();
      },
      (error) => {
        let updateOrderNoteResponse = {
          type: "error",
          note: dto.note,
          alert: {
            type: "danger",
            title: "Order Note Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.updateOrderNote.emit(updateOrderNoteResponse);
        this.activeModal.close();
      }
    );
  }
}
