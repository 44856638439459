import { AppError } from "../../shared/errors/app-error";
import { BadInput } from "../../shared/errors/bad-input";
import { NotFoundError } from "../../shared/errors/not-found-error";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";
import { JwtHelperService } from "@auth0/angular-jwt";
const jwtHelper = new JwtHelperService();
import { Router } from "@angular/router";

let env = environment.production ? "production" : "development";
import * as config from "../../../config.json";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthService {
  constructor(private _http: HttpClient, private _router: Router) {}

  public login(credentials): Observable<any> {
    return this._http
      .post(config[env].apiUrl + "authenticate", credentials)
      .catch(this.handleError);
  }

  public activate(data) {
    return this._http
      .post(config[env].apiUrl + "activate", data)
      .catch(this.handleError);
  }

  public requestResetEmail(email: string) {
    return this._http
      .post(config[env].apiUrl + "reset-request", email)
      .catch(this.handleError);
  }

  public sendAccountRequestEmail(dto) {
    return this._http
      .post(config[env].apiUrl + "send-account-request-email", dto)
      .catch(this.handleError);
  }

  public getImageUploadURL(dto) {
    return this._http
      .post(config[env].apiUrl + "image-upload-url", dto)
      .map((res: any) => res.json())
      .catch(this.handleError);
  }

  public loggedIn() {
    return !jwtHelper.isTokenExpired(localStorage.getItem("id_token"));
  }

  public isAdmin() {
    let roles = jwtHelper
      .decodeToken(localStorage.getItem("id_token"))
      .roles.split("|");
    if (roles.includes(UserRoles.Admin)) {
      return true;
    } else {
      return false;
    }
  }

  public isCustomer() {
    let companyId = jwtHelper.decodeToken(localStorage.getItem("id_token"))
      .companyId;
    let roles = jwtHelper
      .decodeToken(localStorage.getItem("id_token"))
      .roles.split("|");
    if (roles.includes(UserRoles.Customer) && companyId) {
      return true;
    } else {
      return false;
    }
  }

  public canPlaceOrder() {
    let roles = jwtHelper
      .decodeToken(localStorage.getItem("id_token"))
      .roles.split("|");
    if (roles.includes(UserRoles.Customer) || roles.includes(UserRoles.Admin)) {
      return true;
    } else {
      return false;
    }
  }

  public getLoggedInUser() {
    let loggedInUser = {
      id: jwtHelper.decodeToken(localStorage.getItem("id_token")).id,
      email: jwtHelper.decodeToken(localStorage.getItem("id_token")).email,
      roles: jwtHelper.decodeToken(localStorage.getItem("id_token")).roles,
    };
    return loggedInUser;
  }

  public isDisabled() {
    if (
      jwtHelper.decodeToken(localStorage.getItem("id_token")).role ===
      "Disabled"
    ) {
      return true;
    } else {
      return false;
    }
  }

  public expiresIn() {
    return (
      (jwtHelper.decodeToken(localStorage.getItem("id_token")).exp -
        Date.now() / 1000) *
      1000
    );
  }

  public logout() {
    localStorage.removeItem("id_token");
    this._router.navigate(["/login"]);
  }

  public removeToken() {
    localStorage.removeItem("id_token");
  }

  public handleError(error: Response) {
    if (error.status === 404) return Observable.throw(new NotFoundError(error));

    if (error.status === 400) return Observable.throw(new BadInput(error));

    return Observable.throw(new AppError(error));
  }
}

export enum UserRoles {
  "Admin" = "Admin",
  "Customer" = "Customer",
}
