import { UsersComponent } from "./users/users.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { UserModalComponent } from "./modals/user-modal/user-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SupportComponent } from "./support/support.component";
import { routing } from "./app.routing";
import { SharedModule } from "./shared/shared.module";
import { AuthModule } from "./auth/auth.module";
import { HttpClientModule } from "@angular/common/http";
import { ProductsComponent } from "./products/products.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UiSwitchModule } from "ngx-ui-switch";
import { NavbarComponent } from "./navbar/navbar.component";
import { ProductModalComponent } from "./modals/product-modal/product-modal.component";
import { ProductCardComponent } from "./product-card/product-card.component";
import { StoreComponent } from "./store/store.component";
import { CartComponent } from "./cart/cart.component";
import { OrderSummaryComponent } from "./order-summary/order-summary.component";
import { PlaceOrderModalComponent } from "./modals/place-order-modal/place-order-modal.component";
import { OrdersComponent } from "./orders/orders.component";
import { OrderConfirmationComponent } from "./order-confirmation/order-confirmation.component";
import { OrderComponent } from "./order/order.component";
import { AddressesComponent } from "./addresses/addresses.component";
import { CompaniesComponent } from "./companies/companies.component";
import { AddressModalComponent } from "./modals/address-modal/address-modal.component";
import { UpdateShippingRateModalComponent } from "./modals/update-shipping-rate-modal/update-shipping-rate-modal.component";
import { EditOrderAddressModalComponent } from "./modals/edit-order-address-modal/edit-order-address-modal.component";
import { CompanyModalComponent } from "./modals/company-modal/company-modal.component";
import { SendConfirmationModalComponent } from "./modals/send-confirmation-modal/send-confirmation-modal.component";
import { AddItemToOrderModalComponent } from "./modals/add-item-to-order-modal/add-item-to-order-modal.component";
import { EditShipDateModalComponent } from "./modals/edit-ship-date-modal/edit-ship-date-modal.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { SendInvoiceModalComponent } from "./modals/send-invoice-modal/send-invoice-modal.component";
import { EditOrderContactModalComponent } from "./modals/edit-order-contact-modal/edit-order-contact-modal.component";
import { AddCreditToOrderModalComponent } from "./modals/add-credit-to-order-modal/add-credit-to-order-modal.component";
import { PickRptDateRangeModalComponent } from "./modals/pick-rpt-date-range-modal/pick-rpt-date-range-modal.component";
import { RacksComponent } from "./racks/racks.component";
import { RackModalComponent } from "./modals/rack-modal/rack-modal.component";
import { RackCardComponent } from "./rack-card/rack-card.component";
import { EditPoNumberModalComponent } from "./modals/edit-po-number-modal/edit-po-number-modal.component";
import { HalfShelvesComponent } from "./halfshelves/halfshelves.component";
import { HalfShelfModalComponent } from "./modals/halfshelf-modal/halfshelf-modal.component";
import { HalfshelfCardComponent } from "./halfshelf-card/halfshelf-card.component";
import { AddLiftGateToOrderModalComponent } from "./modals/add-lift-gate-to-order-modal/add-lift-gate-to-order-modal.component";
import { EditOrderNoteModalComponent } from "./modals/edit-order-note-modal/edit-order-note-modal.component";
import { PrintOrderModalComponent } from "./modals/print-order-modal/print-order-modal.component";
import { BoxSkidModalComponent } from "./modals/boxskid-modal/boxsid-modal.component";
import { BoxSkidsComponent } from "./boxskids/boxskids.component";
import { BoxSkidCardComponent } from "./boxskid-card/boxskid-card.component";

@NgModule({
  declarations: [
    AppComponent,
    UserModalComponent,
    ProductModalComponent,
    UsersComponent,
    SupportComponent,
    ProductsComponent,
    NavbarComponent,
    ProductCardComponent,
    StoreComponent,
    CartComponent,
    OrderSummaryComponent,
    PlaceOrderModalComponent,
    OrdersComponent,
    OrderConfirmationComponent,
    OrderComponent,
    AddressesComponent,
    CompaniesComponent,
    AddressModalComponent,
    UpdateShippingRateModalComponent,
    EditOrderAddressModalComponent,
    SendConfirmationModalComponent,
    CompanyModalComponent,
    AddItemToOrderModalComponent,
    EditShipDateModalComponent,
    InvoiceComponent,
    SendInvoiceModalComponent,
    EditOrderContactModalComponent,
    AddCreditToOrderModalComponent,
    PickRptDateRangeModalComponent,
    RacksComponent,
    RackModalComponent,
    RackCardComponent,
    EditPoNumberModalComponent,
    HalfShelvesComponent,
    HalfShelfModalComponent,
    HalfshelfCardComponent,
    AddLiftGateToOrderModalComponent,
    EditOrderNoteModalComponent,
    PrintOrderModalComponent,
    BoxSkidsComponent,
    BoxSkidModalComponent,
    BoxSkidCardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    NgbModule,
    FontAwesomeModule,
    UiSwitchModule,
  ],
  providers: [],
  entryComponents: [
    UserModalComponent,
    ProductModalComponent,
    RackModalComponent,
    HalfShelfModalComponent,
    BoxSkidModalComponent,
    PlaceOrderModalComponent,
    AddressModalComponent,
    UpdateShippingRateModalComponent,
    EditOrderAddressModalComponent,
    EditOrderContactModalComponent,
    CompanyModalComponent,
    SendConfirmationModalComponent,
    SendInvoiceModalComponent,
    AddItemToOrderModalComponent,
    AddCreditToOrderModalComponent,
    AddLiftGateToOrderModalComponent,
    EditShipDateModalComponent,
    PickRptDateRangeModalComponent,
    EditPoNumberModalComponent,
    EditOrderNoteModalComponent,
    PrintOrderModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
