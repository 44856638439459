import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IContact } from "src/app/shared/interfaces/contact.interface";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/auth/services/auth.service";
import { OrderService } from "src/app/shared/services/order.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-edit-order-contact-modal",
  templateUrl: "./edit-order-contact-modal.component.html",
  styleUrls: ["./edit-order-contact-modal.component.css"]
})
export class EditOrderContactModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _orderService: OrderService
  ) {}

  @Input() contact: IContact;
  @Input() info: any;
  @Output() updateContact = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return 'Edit Order\'s Contact';
  }

  public closeModal() {
    this.activeModal.close();
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public submitChanges(f) {
    let dto = {
      addressId: f.addressId,
      name: f.name,
      email: f.email,
      phone: f.phone,
      contactType: this.info.contactType,
      orderId: this.info.orderId
    };

    this._orderService.updateOrderContact(dto).subscribe(
      (res: any) => {
        this.updateContact.emit(res);
        this.activeModal.close();
      },
      error => {
        console.log(error);
      }
    );
  }
}
