import { Component, OnInit } from "@angular/core";
import { RackService } from "./../shared/services/rack.service";
import { IRack } from "../shared/interfaces/rack.interface";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RackModalComponent } from "../modals/rack-modal/rack-modal.component";

@Component({
  selector: "app-racks",
  templateUrl: "./racks.component.html",
  styleUrls: ["./racks.component.css"],
})
export class RacksComponent implements OnInit {
  constructor(
    private _rackService: RackService,
    private _modalService: NgbModal
  ) {}

  public racks: Array<IRack>;
  public matchingRacks: Array<IRack>;
  public racksShowing: Array<IRack>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 10;
  public totalItems: number;

  //Filter Vars
  public filters = {
    name: "",
    description: "",
    upc: "",
    itemNo: "",
    display: "",
    adminOnly: "",
    notes: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._rackService.getAll().subscribe(
      (racks: Array<IRack>) => {
        this.racks = racks;
        this.matchingRacks = this.racks;
        this.racksShowing = this.racks.slice(0, this.itemsPerPage);
        this.totalItems = racks.length;
      },
      (error) => {
        console.log("There was an error getting racks.");
      }
    );
  }
  public clearToastAlert() {
    this.alert = null;
  }

  public pageChanged(event: any): void {
    this.racksShowing = this.matchingRacks.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(RackModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.createRack.subscribe((event) => {
      this.onCreateRack(event);
    });
  }

  public startUpdate(rack) {
    const modalRef = this._modalService.open(RackModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.rack = rack;
    modalRef.componentInstance.deleteRack.subscribe((event) => {
      this.onDeleteRack(event);
    });
    modalRef.componentInstance.updateRack.subscribe((event) => {
      this.onUpdateRack(event);
    });
  }

  public onCreateRack(e) {
    if (e.type === "success") {
      this.racks.push(e.rack);
      this.alert = e.alert;
      this.racksShowing = this.racks.slice(0, this.itemsPerPage);
      this.totalItems = this.racks.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteRack(e) {
    if (e.type === "success") {
      let index = this.racks.findIndex((rack) => rack.id == e.rack.id);
      this.racks.splice(index, 1);
      this.racksShowing = this.racks.slice(0, this.itemsPerPage);
      this.totalItems = this.racks.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateRack(e) {
    if (e.type === "success") {
      let updatedRackIndex = this.racks.findIndex(
        (rack) => rack.id === e.rack.id
      );

      this.racks[updatedRackIndex] = e.rack;

      let updatedRackShowingIndex = this.racksShowing.findIndex(
        (rack) => rack.id === e.rack.id
      );
      this.racksShowing[updatedRackShowingIndex] = this.racks[updatedRackIndex];

      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingRacks = this.racks.filter(
      (rack) =>
        this.applyRackNameFilter(rack) &&
        this.applyRackDescriptionFilter(rack) &&
        this.applyRackUPCFilter(rack) &&
        this.applyRackItemNoFilter(rack) &&
        (this.filters.display
          ? (rack.display ? "Y" : "N") == this.filters.display.toUpperCase()
          : true) &&
        (this.filters.adminOnly
          ? (rack.adminOnly ? "Y" : "N") == this.filters.adminOnly.toUpperCase()
          : true)
    );

    this.racksShowing = this.matchingRacks.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingRacks.length;
  }

  private applyRackNameFilter(rack) {
    if (this.filters.name) {
      if (rack.name) {
        return rack.name
          .toUpperCase()
          .includes(this.filters.name.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyRackDescriptionFilter(rack) {
    if (this.filters.description) {
      if (rack.description) {
        return rack.description
          .toUpperCase()
          .includes(this.filters.description.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyRackUPCFilter(rack) {
    if (this.filters.upc) {
      if (rack.upc) {
        return rack.upc.toUpperCase().includes(this.filters.upc.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  private applyRackItemNoFilter(rack) {
    if (this.filters.itemNo) {
      if (rack.itemNo) {
        return rack.itemNo
          .toUpperCase()
          .includes(this.filters.itemNo.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public clearFilters() {
    this.filters.name = "";
    this.filters.description = "";
    this.filters.upc = "";
    this.filters.itemNo = "";
    this.filters.display = "";
    this.filters.adminOnly = "";
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingRacks.length > 0) {
      let headers =
        "id,upc,itemNo,name,description,costEach,itemQty,dispaly,adminOnly";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingRacks.length; i++) {
        csv +=
          '"' +
          (!this.matchingRacks[i].id ? "" : this.matchingRacks[i].id) +
          '",';
        csv +=
          '"' +
          (!this.matchingRacks[i].upc ? "" : this.matchingRacks[i].upc) +
          '",';
        csv +=
          '"' +
          (!this.matchingRacks[i].itemNo ? "" : this.matchingRacks[i].itemNo) +
          '",';
        csv +=
          '"' +
          (!this.matchingRacks[i].name
            ? ""
            : this.matchingRacks[i].name.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingRacks[i].description
            ? ""
            : this.matchingRacks[i].description.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingRacks[i].eachCost
            ? ""
            : this.matchingRacks[i].eachCost) +
          '",';
        csv +=
          '"' +
          (!this.matchingRacks[i].itemQty
            ? ""
            : this.matchingRacks[i].itemQty) +
          '",';
        csv += '"' + (this.matchingRacks[i].display ? "Y" : "N") + '",';
        csv += '"' + (this.matchingRacks[i].adminOnly ? "Y" : "N") + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "racks.csv";

    //@ts-ignore
    if (navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
