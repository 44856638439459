import { PrintPDFService } from "./../shared/services/print-pdf.service";
import { EditShipDateModalComponent } from "./../modals/edit-ship-date-modal/edit-ship-date-modal.component";
import { AddItemToOrderModalComponent } from "./../modals/add-item-to-order-modal/add-item-to-order-modal.component";
import { Component, TemplateRef, Input } from "@angular/core";
import { OrderService } from "../shared/services/order.service";
import { Router } from "@angular/router";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../auth/services/auth.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditOrderAddressModalComponent } from "../modals/edit-order-address-modal/edit-order-address-modal.component";
import { IAddress, AddressTypes } from "../shared/interfaces/address.interface";
import { SendConfirmationModalComponent } from "../modals/send-confirmation-modal/send-confirmation-modal.component";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { NotFoundError } from "../shared/errors/not-found-error";
import { IContact } from "../shared/interfaces/contact.interface";
import { EditOrderContactModalComponent } from "../modals/edit-order-contact-modal/edit-order-contact-modal.component";
import { AddCreditToOrderModalComponent } from "./../modals/add-credit-to-order-modal/add-credit-to-order-modal.component";
import { EditPoNumberModalComponent } from "./../modals/edit-po-number-modal/edit-po-number-modal.component";
import { AddLiftGateToOrderModalComponent } from "./../modals/add-lift-gate-to-order-modal/add-lift-gate-to-order-modal.component";
import { SHELVES_PER_RACK } from "../shared/data/constants";
import { EditOrderNoteModalComponent } from "../modals/edit-order-note-modal/edit-order-note-modal.component";
import { PrintOrderModalComponent } from "../modals/print-order-modal/print-order-modal.component";
import { NumberUtils } from "../utils/number-utils";

@Component({
  selector: "app-order-confirmation",
  templateUrl: "./order-confirmation.component.html",
  styleUrls: ["./order-confirmation.component.css"],
})
export class OrderConfirmationComponent {
  constructor(
    private _orderService: OrderService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _router: Router,
    private _printPDFService: PrintPDFService
  ) {}

  @Input() order;
  public editIcon = faEdit;
  public removeFromOrderIcon = faTimesCircle;
  public alert: any;
  public activeModal: NgbActiveModal;
  public halfShelvesPerRack = SHELVES_PER_RACK * 2;
  public totalBoxesOrdered = 0;

  ngOnChanges() {
    if (this.order)
      this.totalBoxesOrdered = NumberUtils.getBoxesOrdered(this.order);
  }

  public startPrint() {
    const modalRef = this._modalService.open(PrintOrderModalComponent);
    modalRef.componentInstance.print.subscribe((event) => {
      this.onPrint(event);
    });
  }

  public onPrint(type: "Order Confirmation" | "Invoice" | "Packing Slip") {
    this._printPDFService.print(type, this.order);
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public startUpdateAddress(addressType) {
    this.clearToastAlert();
    let address: IAddress;
    if (addressType == "shipping") {
      address = {
        id: this.order.shippingAddressId,
        type: AddressTypes.shipping,
        companyName: this.order.shippingCompanyName,
        street1: this.order.shippingStreetAddress1,
        street2: this.order.shippingStreetAddress2,
        city: this.order.shippingCity,
        state: this.order.shippingState,
        zip: this.order.shippingZip,
        shippingRate: this.order.shippingRate,
      };
    } else {
      address = {
        id: this.order.billingAddressId,
        type: AddressTypes.billing,
        street1: this.order.billingStreetAddress1,
        street2: this.order.billingStreetAddress2,
        poBox: this.order.billingPOBox,
        city: this.order.billingCity,
        state: this.order.billingState,
        zip: this.order.billingZip,
      };
    }

    let info = {
      addressType: addressType,
      companyId: this.order.companyId,
      orderId: this.order.id,
    };

    const modalRef = this._modalService.open(EditOrderAddressModalComponent);
    modalRef.componentInstance.address = address;
    modalRef.componentInstance.info = info;
    modalRef.componentInstance.updateAddress.subscribe((event) => {
      this.onUpdateAddress(event);
    });
  }

  public onUpdateAddress(e) {
    if (e.updated) {
      if (e.addressType == "shipping") {
        this.order.shippingCompanyName = e.order.shippingCompanyName;
        this.order.shippingStreetAddress1 = e.order.shippingStreetAddress1;
        this.order.shippingStreetAddress2 = e.order.shippingStreetAddress2;
        this.order.shippingCity = e.order.shippingCity;
        this.order.shippingState = e.order.shippingState;
        this.order.shippingZip = e.order.shippingZip;
        this.order.shippingTotal = e.order.shippingTotal;
        this.order.shippingRate = e.order.shippingRate;
      }

      if (e.addressType == "billing") {
        this.order.billingStreetAddress1 = e.order.billingStreetAddress1;
        this.order.billingStreetAddress2 = e.order.billingStreetAddress2;
        this.order.billingPOBox = e.order.billingPOBox;
        this.order.billingCity = e.order.billingCity;
        this.order.billingState = e.order.billingState;
        this.order.billingZip = e.order.billingZip;
      }
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startUpdateContact(contactType) {
    this.clearToastAlert();
    let contact: IContact;
    if (contactType == "shipping") {
      contact = {
        addressId: this.order.shippingAddressId,
        name: this.order.shippingContactName,
        email: this.order.shippingContactEmail,
        phone: this.order.shippingContactPhone,
      };
    } else {
      contact = {
        addressId: this.order.billingAddressId,
        name: this.order.billingContactName,
        email: this.order.billingContactEmail,
        phone: this.order.billingContactPhone,
      };
    }

    let info = {
      contactType: contactType,
      companyId: this.order.companyId,
      orderId: this.order.id,
    };

    const modalRef = this._modalService.open(EditOrderContactModalComponent);
    modalRef.componentInstance.contact = contact;
    modalRef.componentInstance.info = info;
    modalRef.componentInstance.updateContact.subscribe((event) => {
      this.onUpdateContact(event);
    });
  }

  public onUpdateContact(e) {
    if (e.updated) {
      if (e.contactType == "shipping") {
        this.order.shippingContactName = e.order.shippingContactName;
        this.order.shippingContactEmail = e.order.shippingContactEmail;
        this.order.shippingContactPhone = e.order.shippingContactPhone;
      }

      if (e.contactType == "billing") {
        this.order.billingContactName = e.order.billingContactName;
        this.order.billingContactEmail = e.order.billingContactEmail;
        this.order.billingContactPhone = e.order.billingContactPhone;
      }
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startUpdateShipDate() {
    this.clearToastAlert();
    let dto = {
      orderId: this.order.id,
      shipDate: this.order.shipDate,
    };

    const modalRef = this._modalService.open(EditShipDateModalComponent);
    modalRef.componentInstance.dto = dto;
    modalRef.componentInstance.updateShipDate.subscribe((event) => {
      this.onUpdateShipDate(event);
    });
  }

  public onUpdateShipDate(e) {
    if (e.type == "success") {
      this.order.shipDate = e.shipDate;
      this.alert = {
        type: "success",
        title: "Updated",
        msg: "Ship Date has been updated.",
      };
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startUpdatePONumber() {
    this.clearToastAlert();
    let dto = {
      orderId: this.order.id,
      poNumber: this.order.poNumber,
    };

    const modalRef = this._modalService.open(EditPoNumberModalComponent);
    modalRef.componentInstance.order = dto;
    modalRef.componentInstance.updatePONumber.subscribe((event) => {
      this.onUpdatePONumber(event);
    });
  }

  public onUpdatePONumber(e) {
    if (e.type === "success") {
      this.order.poNumber = e.poNumber;
      this.alert = {
        type: "success",
        title: "Updated",
        msg: "PO number has been updated.",
      };
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startUpdateNote() {
    this.clearToastAlert();
    let dto = {
      orderId: this.order.id,
      note: this.order.note,
    };

    const modalRef = this._modalService.open(EditOrderNoteModalComponent);
    modalRef.componentInstance.order = dto;
    modalRef.componentInstance.updateOrderNote.subscribe((event) => {
      this.onUpdateNote(event);
    });
  }

  public onUpdateNote(e) {
    if (e.type === "success") {
      this.order.note = e.note;
      this.alert = {
        type: "success",
        title: "Updated",
        msg: "Order Note has been updated.",
      };
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startSendOrderConfirmation() {
    const modalRef = this._modalService.open(SendConfirmationModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.orderId = this.order.id;
  }

  public removeItem(item) {
    this._orderService.removeItemFromOrder(item).subscribe(
      (res: any) => {
        // Remove Product from List of OrderProducts
        let removedItemsIndex = this.order.OrderProducts.findIndex(
          (orderProduct) => orderProduct.id == item.id
        );
        this.order.OrderProducts.splice(removedItemsIndex, 1);

        //Update Totals
        this.order.productTotal = res.orderData.productTotal;
        this.order.shippingTotal = res.orderData.shippingTotal;
        this.order.customerDiscountAmt = res.orderData.customerDiscountAmt;
        this.order.orderDiscountAmt = res.orderData.orderDiscountAmt;
        this.order.orderTotal = res.orderData.orderTotal;
        this.order.boxesOrdered = res.orderData.boxesOrdered;
        this.order.halfShelvesOrdered = res.orderData.halfShelvesOrdered;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public removeRack(rack) {
    this._orderService.removeRackFromOrder(rack).subscribe(
      (res: any) => {
        // Remove Product from List of OrderProducts
        let removedRackIndex = this.order.OrderRacks.findIndex(
          (orderRack) => orderRack.id == rack.id
        );
        this.order.OrderRacks.splice(removedRackIndex, 1);

        //Update Totals
        this.order.productTotal = res.orderData.productTotal;
        this.order.shippingTotal = res.orderData.shippingTotal;
        this.order.customerDiscountAmt = res.orderData.customerDiscountAmt;
        this.order.orderDiscountAmt = res.orderData.orderDiscountAmt;
        this.order.orderTotal = res.orderData.orderTotal;
        this.order.prebuiltRacksOrdered = res.orderData.prebuiltRacksOrdered;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public removeHalfShelf(halfShelf) {
    this._orderService.removeHalfShelfFromOrder(halfShelf).subscribe(
      (res: any) => {
        // Remove Product from List of OrderProducts
        let removedHalfShelfIndex = this.order.OrderHalfShelves.findIndex(
          (orderHalfShelf) => orderHalfShelf.id === halfShelf.id
        );
        this.order.OrderHalfShelves.splice(removedHalfShelfIndex, 1);

        //Update Totals
        this.order.productTotal = res.orderData.productTotal;
        this.order.shippingTotal = res.orderData.shippingTotal;
        this.order.customerDiscountAmt = res.orderData.customerDiscountAmt;
        this.order.orderDiscountAmt = res.orderData.orderDiscountAmt;
        this.order.orderTotal = res.orderData.orderTotal;
        this.order.prebuiltHalfShelvesOrdered =
          res.orderData.prebuiltHalfShelvesOrdered;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public removeBoxSkid(boxSkid) {
    this._orderService.removeBoxSkidFromOrder(boxSkid).subscribe(
      (res: any) => {
        // Remove Product from List of OrderProducts
        let removedBoxSkidIndex = this.order.OrderBoxSkids.findIndex(
          (orderBoxSkid) => orderBoxSkid.id === boxSkid.id
        );
        this.order.OrderBoxSkids.splice(removedBoxSkidIndex, 1);

        //Update Totals
        this.order.productTotal = res.orderData.productTotal;
        this.order.shippingTotal = res.orderData.shippingTotal;
        this.order.customerDiscountAmt = res.orderData.customerDiscountAmt;
        this.order.orderDiscountAmt = res.orderData.orderDiscountAmt;
        this.order.orderTotal = res.orderData.orderTotal;
        this.order.boxSkidsOrdered = res.orderData.boxSkidsOrdered;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public closeModal() {
    this.activeModal.dismiss();
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._orderService.delete(id).subscribe(
      (res: any) => {
        this.alert = {
          type: "success",
          title: "Order deleted.",
          msg: res.message,
        };
        this._router.navigate(["orders"]);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          this.alert = {
            type: "success",
            title: "Order deleted.",
            msg: "Order " + this.order.id + " has been deleted.",
          };
          this._router.navigate(["orders"]);
        } else {
          this.alert = {
            type: "danger",
            title: "Order was not deleted.",
            msg: error.originalError.errors[0].description,
          };
        }
      }
    );
  }

  public startAddItemToOrder() {
    this.alert = null;
    const modalRef = this._modalService.open(AddItemToOrderModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.addItem.subscribe((event) => {
      this.onAddItem(event);
    });
  }

  public startAddCreditToOrder() {
    this.alert = null;
    const maxAllowedCreditValue =
      this.order.productTotal +
      this.order.shippingTotal +
      this.order.liftGateCharge -
      this.order.customerDiscountAmt -
      this.order.orderDiscountAmt;
    const modalRef = this._modalService.open(AddCreditToOrderModalComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.maxAllowedCreditValue = maxAllowedCreditValue;
    modalRef.componentInstance.addCredit.subscribe((event) => {
      this.onAddCredit(event);
    });
  }

  public startAddLiftGateOrder() {
    this.alert = null;
    const modalRef = this._modalService.open(AddLiftGateToOrderModalComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.addLiftGate.subscribe((event) => {
      this.onAddLiftGate(event);
    });
  }

  public clearToastAlert() {
    this.alert = null;
  }

  private onAddItem(e) {
    this.alert = e.alert;
    if (e.type === "success") {
      //1. Add Line to Order Products
      this.order.OrderProducts.push(e.orderProduct);

      //2. Update Order Totals
      this.order.productTotal = e.order.productTotal;
      this.order.shippingTotal = e.order.shippingTotal;
      this.order.customerDiscountAmt = e.order.customerDiscountAmt;
      this.order.orderDiscountAmt = e.order.orderDiscountAmt;
      this.order.orderTotal = e.order.orderTotal;
      this.order.boxesOrdered = e.order.boxesOrdered;
      this.order.halfShelvesOrdered = e.order.halfShelvesOrdered;
    }
  }

  private onAddCredit(e) {
    this.alert = e.alert;
    if (e.type === "success") {
      //Update Order Total
      this.order.credit = e.order.credit;
      this.order.orderTotal = e.order.orderTotal;
    }
  }

  private onAddLiftGate(e) {
    this.alert = e.alert;
    if (e.type === "success") {
      //Update Order Total
      this.order.liftGateCharge = e.order.liftGateCharge;
      this.order.orderTotal = e.order.orderTotal;
    }
  }
}
