import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderService } from "../../shared/services/order.service";

@Component({
  selector: "app-add-lift-gate-to-order-modal",
  templateUrl: "./add-lift-gate-to-order-modal.component.html",
  styleUrls: ["./add-lift-gate-to-order-modal.component.css"],
})
export class AddLiftGateToOrderModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _orderService: OrderService
  ) {}

  @Input() orderId: any;
  @Output() addLiftGate = new EventEmitter<any>();

  ngOnInit() {}

  public closeModal() {
    this.activeModal.close();
  }

  public addLiftGateToOrder(data) {
    this._orderService.addLiftGateToOrder(data).subscribe(
      (res: any) => {
        let addLiftgateResponse = {
          type: "success",
          order: res,
          alert: {
            type: "success",
            title: "Success!",
            msg: "Liftgate was added to the order.",
          },
        };
        this.activeModal.close();
        this.addLiftGate.emit(addLiftgateResponse);
      },
      (error) => {
        let addLiftGateResponse = {
          type: "error",
          alert: {
            type: "danger",
            title: "Error Adding Liftgate",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.addLiftGate.emit(addLiftGateResponse);
      }
    );
  }
}
