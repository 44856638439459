<div class="modal-header">
  <h4 class="modal-title pull-left">{{getModalHeader()}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">

    <div class="col-12 form-group">
      <input id="orderId" name="orderId" type="text" hidden [ngModel]="orderId">
      <label for="email">Email</label>
      <input required pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$' type="email" class="form-control" placeholder="email" id="email" name="email" [ngModel]="" #email="ngModel">
      <div class="form-alert" *ngIf="email.touched && !email.valid">
        <div *ngIf="email.errors.required">
          Email is required.
        </div>
        <div *ngIf="email.errors.pattern">
          Please enter a valid email.
        </div>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button class="btn btn-primary" (click)="sendConfirmation(form.value)" [disabled]="!form.valid">Send Email</button>
      <button class="btn btn-default float-right" (click)="closeModal()">Cancel</button>
    </div>

  </form>
</div>