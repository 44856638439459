import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "../shared/services/order.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _orderService: OrderService
  ) {}

  public order;

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this._orderService.getById({ id: params.id }).subscribe(
        (res) => {
          this.order = res;
        },
        (error) => {
          console.log("There was an error getting the order.");
        }
      );
    });
  }
}
