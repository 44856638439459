<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="form-group">
      <p for="role">What Type of User?</p>
      <div
        *ngFor="let role of roleOptions"
        class="custom-control custom-radio custom-control-inline"
      >
        <input
          required
          (click)="onRoleChange(role)"
          type="radio"
          id="is{{ role.name }}"
          name="role"
          [value]="role.id"
          [checked]="isFirstRole(role)"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="is{{ role.name }}">{{
          role.name
        }}</label>
      </div>
    </div>

    <div
      *ngIf="
        formOptions.selectedRole
          ? formOptions.selectedRole.name == 'Customer'
          : false
      "
      class="form-group"
    >
      <label for="companyId">Select Company</label>
      <select
        ngbAutofocus
        class="form-control"
        selectionRequired
        name="companyId"
        id="companyId"
        [ngModel]="formOptions.selectedCompany.id"
        (change)="onCompanyChange(companyId.value)"
        #companyId="ngModel"
      >
        <option value="-1">Select Company</option>
        <option *ngFor="let company of companyOptions" [ngValue]="company.id">{{
          company.name
        }}</option>
      </select>
      <div class="form-alert" *ngIf="companyId.touched && !companyId.valid">
        <div *ngIf="companyId.errors.selectionRequired">
          Company is required.
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input
        required
        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
        type="email"
        id="email"
        name="email"
        [ngModel]="user?.email"
        #email="ngModel"
        class="form-control"
      />
      <div class="form-alert" *ngIf="email.touched && !email.valid">
        <div *ngIf="email.errors.required">
          Email is required.
        </div>
        <div *ngIf="email.errors.pattern">
          Please enter a valid email.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="firstName">First Name</label>
      <input
        required
        type="text"
        id="firstName"
        name="firstName"
        [ngModel]="user?.firstName"
        #firstName="ngModel"
        class="form-control"
      />
      <div class="form-alert" *ngIf="firstName.touched && !firstName.valid">
        <div *ngIf="firstName.errors.required">
          First name is required.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input
        required
        type="text"
        id="lastName"
        name="lastName"
        [ngModel]="user?.lastName"
        #lastName="ngModel"
        class="form-control"
      />
      <div class="form-alert" *ngIf="lastName.touched && !lastName.valid">
        <div *ngIf="lastName.errors.required">
          Last name is required.
        </div>
      </div>
    </div>

    <div class="modal-button-group form-group">
      <button
        *ngIf="modalAction === 'Create'"
        class="btn btn-primary"
        (click)="add(form.value)"
        [disabled]="!form.valid"
      >
        Create User
      </button>
      <button
        *ngIf="modalAction === 'Edit'"
        class="btn btn-primary"
        (click)="update(form.value)"
        [disabled]="!form.valid"
      >
        Save
      </button>
      <button
        id="deleteBtn"
        *ngIf="modalAction === 'Edit'"
        class="btn btn-danger float-right"
        (click)="confirmDelete(deleteTemplate, $event)"
      >
        Delete User
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete: {{ user.email }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to delete user "{{ user.email }}".</p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>
    <form #deleteForm="ngForm">
      <div class="form-group">
        <input type="number" hidden [ngModel]="user.id" name="id" id="id" />
        <button class="btn btn-primary" (click)="delete(deleteForm.value.id)">
          Confirm Delete
        </button>
        <button class="btn btn-default float-right" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
