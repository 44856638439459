import { CompanyService } from "./../shared/services/company.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ICompany } from "../shared/interfaces/company.interface";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { CompanyModalComponent } from "../modals/company-modal/company-modal.component";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.css"],
})
export class CompaniesComponent implements OnInit {
  constructor(
    private _companyService: CompanyService,
    private _modalService: NgbModal
  ) {}

  public companies: Array<ICompany>;
  public matchingCompanies: Array<ICompany>;
  public companiesShowing: Array<ICompany>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 15;
  public maxSize: number = 15;
  public totalItems: number;

  //Filter Vars
  public filters = {
    id: "",
    name: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._companyService.getAll().subscribe(
      (companies: Array<ICompany>) => {
        this.companies = companies;
        this.sortCompanies();
        this.matchingCompanies = this.companies;
        this.companiesShowing = this.companies.slice(0, this.itemsPerPage);
        this.totalItems = companies.length;
      },
      (error) => {
        console.log("There was an error getting companies.");
      }
    );
  }

  public clearToastAlert() {
    this.alert = null;
  }

  private sortCompanies() {
    this.companies.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  public pageChanged(event: any): void {
    this.companiesShowing = this.matchingCompanies.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(CompanyModalComponent);
    modalRef.componentInstance.createCompany.subscribe((event) => {
      this.onCreateCompany(event);
    });
  }

  public startUpdate(company) {
    const modalRef = this._modalService.open(CompanyModalComponent);
    modalRef.componentInstance.company = company;
    modalRef.componentInstance.deleteCompany.subscribe((event) => {
      this.onDeleteCompany(event);
    });
    modalRef.componentInstance.updateCompany.subscribe((event) => {
      this.onUpdateCompany(event);
    });
  }

  public onCreateCompany(e) {
    if (e.type === "success") {
      this.clearFilters();
      this.companies.push(e.company);
      this.sortCompanies();
      this.alert = e.alert;
      this.companiesShowing = this.companies.slice(0, this.itemsPerPage);
      this.totalItems = this.companies.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteCompany(e) {
    if (e.type === "success") {
      this.clearFilters();
      let index = this.companies.findIndex(
        (company) => company.id == e.company.id
      );
      this.companies.splice(index, 1);
      this.sortCompanies();
      this.companiesShowing = this.companies.slice(0, this.itemsPerPage);
      this.totalItems = this.companies.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateCompany(e) {
    if (e.type === "error") {
      this.alert = e.alert;
    } else {
      let updatedCompanyIndex = this.companies.findIndex(
        (company) => company.id == e.company.id
      );
      this.companies[updatedCompanyIndex] = e.company;
      const pageWeWereOn = this.currentPage;
      this.changeFilter();
      this.pageChanged(pageWeWereOn);
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingCompanies = this.companies.filter(
      (company) =>
        company.id.toUpperCase().includes(this.filters.id.toUpperCase()) &&
        company.name.toUpperCase().includes(this.filters.name.toUpperCase())
    );

    this.companiesShowing = this.matchingCompanies.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingCompanies.length;
  }

  public clearFilters() {
    this.filters.id = "";
    this.filters.name = "";
    this.matchingCompanies = this.companies;
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingCompanies.length > 0) {
      let headers = "Id,Name,Discount,Notes,CreatedAt,UpdatedAt";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingCompanies.length; i++) {
        csv += '"' + this.matchingCompanies[i].id + '",';
        csv += '"' + this.matchingCompanies[i].name + '",';
        csv += '"' + this.matchingCompanies[i].discount + '",';
        csv += '"' + this.matchingCompanies[i].notes + '",';
        csv += '"' + this.matchingCompanies[i].createdAt + '",';
        csv += '"' + this.matchingCompanies[i].updatedAt + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "companies.csv";

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
