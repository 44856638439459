import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";

@Injectable()
export class UserService extends DataService {
  constructor(http: HttpClient) {
    super("users", http);
  }

  public getLoggedInUsersCompany() {
    return this.getHttp()
      .get(this.getApiUrl() + "/my-company")
      .pipe(catchError(super.handleError));
  }
}
