export class NumberUtils {
  static pad(n, width, z?) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  static getBoxesOrdered(order) {
    let boxSkidTotalBoxes = 0;
    order.OrderBoxSkids.forEach((boxSkid) => {
      boxSkid.OrderBoxSkidProducts.forEach((boxSkidProduct) => {
        boxSkidTotalBoxes += boxSkidProduct.boxOrderedQty;
      });
    });
    return boxSkidTotalBoxes + order.boxesOrdered;
  }
}
