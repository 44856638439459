import { ProductService } from "../shared/services/product.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { ProductModalComponent } from "../modals/product-modal/product-modal.component";
import { IProduct } from "../shared/interfaces/product.interface";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  constructor(
    private _productService: ProductService,
    private _modalService: NgbModal
  ) {}

  public apiUrl: string;
  public products: Array<IProduct>;
  public matchingProducts: Array<IProduct>;
  public productsShowing: Array<IProduct>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 10;
  public totalItems: number;

  //Filter Vars
  public filters = {
    category: "",
    name: "",
    description: "",
    upc: "",
    itemNo: "",
    display: "",
    boxable: "",
    rackable: "",
    adminOnly: "",
    notes: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._productService.getAll().subscribe(
      (products: Array<IProduct>) => {
        this.products = products;
        this.matchingProducts = this.products;
        this.productsShowing = this.products.slice(0, this.itemsPerPage);
        this.totalItems = products.length;
      },
      (error) => {
        console.log("There was an error getting products.");
      }
    );
  }

  public clearToastAlert() {
    this.alert = null;
  }

  public pageChanged(event: any): void {
    this.productsShowing = this.matchingProducts.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(ProductModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.createProduct.subscribe((event) => {
      this.onCreateProduct(event);
    });
  }

  public startUpdate(product) {
    const modalRef = this._modalService.open(ProductModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.deleteProduct.subscribe((event) => {
      this.onDeleteProduct(event);
    });
    modalRef.componentInstance.updateProduct.subscribe((event) => {
      this.onUpdateProduct(event);
    });
  }

  public onCreateProduct(e) {
    console.log("onCraeteProduct", e);
    if (e.type === "success") {
      this.products.push(e.product);
      this.alert = e.alert;
      this.productsShowing = this.products.slice(0, this.itemsPerPage);
      this.totalItems = this.products.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteProduct(e) {
    if (e.type === "success") {
      let index = this.products.findIndex(
        (product) => product.id == e.product.id
      );
      this.products.splice(index, 1);
      this.productsShowing = this.products.slice(0, this.itemsPerPage);
      this.totalItems = this.products.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateProduct(e) {
    if (e.type === "success") {
      let updatedProductIndex = this.products.findIndex(
        (product) => product.id === e.product.id
      );

      this.products[updatedProductIndex] = e.product;

      let updatedProductShowingIndex = this.productsShowing.findIndex(
        (product) => product.id === e.product.id
      );
      this.productsShowing[updatedProductShowingIndex] = this.products[
        updatedProductIndex
      ];

      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingProducts = this.products.filter(
      (product) =>
        this.applyProductCategoryFilter(product) &&
        this.applyProductNameFilter(product) &&
        this.applyProductDescriptionFilter(product) &&
        this.applyProductUPCFilter(product) &&
        this.applyItemNoFilter(product) &&
        (this.filters.display
          ? (product.display ? "Y" : "N") === this.filters.display.toUpperCase()
          : true) &&
        (this.filters.boxable
          ? (product.boxable ? "Y" : "N") === this.filters.boxable.toUpperCase()
          : true) &&
        (this.filters.rackable
          ? (product.rackable ? "Y" : "N") ===
            this.filters.rackable.toUpperCase()
          : true) &&
        (this.filters.adminOnly
          ? (product.adminOnly ? "Y" : "N") ===
            this.filters.adminOnly.toUpperCase()
          : true)
    );

    this.productsShowing = this.matchingProducts.slice(0, this.itemsPerPage);
    this.totalItems = this.matchingProducts.length;
  }

  private applyProductCategoryFilter(product) {
    if (this.filters.category) {
      //We are filtering on category

      if (product.Category) {
        //Product has category apply the filter
        return product.Category.name
          .toUpperCase()
          .includes(this.filters.category.toUpperCase());
      } else {
        //Product does not have category return false
        return false;
      }
    } else {
      return true;
    }
  }

  private applyProductNameFilter(product) {
    if (this.filters.name) {
      if (product.name) {
        return product.name
          .toUpperCase()
          .includes(this.filters.name.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyItemNoFilter(product) {
    if (this.filters.itemNo) {
      if (product.itemNo) {
        return product.itemNo
          .toUpperCase()
          .includes(this.filters.itemNo.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyProductDescriptionFilter(product) {
    if (this.filters.description) {
      if (product.description) {
        return product.description
          .toUpperCase()
          .includes(this.filters.description.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyProductUPCFilter(product) {
    if (this.filters.upc) {
      if (product.upc) {
        return product.upc
          .toUpperCase()
          .includes(this.filters.upc.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public clearFilters() {
    this.filters.category = "";
    this.filters.name = "";
    this.filters.description = "";
    this.filters.upc = "";
    this.filters.itemNo = "";
    this.filters.display = "";
    this.filters.boxable = "";
    this.filters.rackable = "";
    this.filters.notes = "";
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingProducts.length > 0) {
      let headers =
        "id,upc,itemNo,name,description,cost each,qty/box, qty/halfshelf, box size, dispaly, boxable, rackable";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingProducts.length; i++) {
        csv +=
          '"' +
          (!this.matchingProducts[i].id ? "" : this.matchingProducts[i].id) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].upc ? "" : this.matchingProducts[i].upc) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].itemNo
            ? ""
            : this.matchingProducts[i].itemNo.replace(/"/g, '""')) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].name
            ? ""
            : this.matchingProducts[i].name.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingProducts[i].description
            ? ""
            : this.matchingProducts[i].description.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingProducts[i].eachCost
            ? ""
            : this.matchingProducts[i].eachCost) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].qtyPerBox
            ? ""
            : this.matchingProducts[i].qtyPerBox) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].qtyPerHalfShelf
            ? ""
            : this.matchingProducts[i].qtyPerHalfShelf) +
          '",';
        csv +=
          '"' +
          (!this.matchingProducts[i].Box
            ? ""
            : this.matchingProducts[i].Box.size) +
          '",';
        csv += '"' + (this.matchingProducts[i].display ? "Y" : "N") + '",';
        csv += '"' + (this.matchingProducts[i].boxable ? "Y" : "N") + '",';
        csv += '"' + (this.matchingProducts[i].rackable ? "Y" : "N") + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "products.csv";

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
