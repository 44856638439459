<ngb-alert
  class="fixed-alert"
  *ngIf="alert"
  type="{{ alert.type }}"
  (close)="clearToastAlert()"
>
  <strong>{{ alert.title }}</strong> {{ alert.msg }}
</ngb-alert>
<div class="filters-container">
  <div class="form-group">
    <label for="searchBar" class="sr-only">Search</label>
    <input
      id="searchBar"
      class="form-control"
      type="text"
      placeholder="Search"
      (keyup)="onSearchTermChange($event.target.value)"
    />
  </div>

  <div class="category-options">
    <h5>Categories</h5>
    <div
      *ngFor="let category of categoryOptions"
      class="tag"
      [class.selected]="category.selected"
      (click)="onCategoryChange(category)"
    >
      <span class="content">{{ category.name }}</span>
    </div>
  </div>
</div>
<div class="products-container">
  <div
    *ngIf="
      products &&
      matchingProducts?.length === 0 &&
      racks &&
      matchingRacks?.length === 0 &&
      halfShelves &&
      matchingHalfShelves?.length === 0 &&
      boxSkids &&
      matchingBoxSkids?.length === 0
    "
    id="no-products-container"
  >
    <p>No Items Match Your Search Critera</p>
  </div>
  <app-rack-card
    (addRackToCart)="onAddRackToCart($event)"
    (updateRackInCart)="onUpdateRackInCart($event)"
    (removeRackFromCart)="onRemoveRackFromCart($event)"
    *ngFor="let rack of matchingRacks"
    [rack]="rack"
    [fileServerUrl]="fileServerUrl"
    [racksOrdered]="rack.racksOrdered ? rack.racksOrdered : null"
    [comments]="rack.comments ? rack.comments : null"
  ></app-rack-card>
  <app-halfshelf-card
    (addHalfShelfToCart)="onAddHalfShelfToCart($event)"
    (updateHalfShelfInCart)="onUpdateHalfShelfInCart($event)"
    (removeHalfShelfFromCart)="onRemoveHalfShelfFromCart($event)"
    *ngFor="let halfShelf of matchingHalfShelves"
    [halfShelf]="halfShelf"
    [fileServerUrl]="fileServerUrl"
    [halfShelvesOrdered]="
      halfShelf.halfShelvesOrdered ? halfShelf.halfShelvesOrdered : null
    "
    [comments]="halfShelf.comments ? halfShelf.comments : null"
  ></app-halfshelf-card>
  <app-boxskid-card
    (addBoxSkidToCart)="onAddBoxSkidToCart($event)"
    (updateBoxSkidInCart)="onUpdateBoxSkidInCart($event)"
    (removeBoxSkidFromCart)="onRemoveBoxSkidFromCart($event)"
    *ngFor="let boxSkid of matchingBoxSkids"
    [boxSkid]="boxSkid"
    [fileServerUrl]="fileServerUrl"
    [boxSkidsOrdered]="boxSkid.boxSkidsOrdered ? boxSkid.boxSkidsOrdered : null"
    [comments]="boxSkid.comments ? boxSkid.comments : null"
  ></app-boxskid-card>
  <app-product-card
    (addItemToCart)="onAddItemToCart($event)"
    (updateItemInCart)="onUpdateItemInCart($event)"
    (removeItemFromCart)="onRemoveItemFromCart($event)"
    *ngFor="let product of matchingProducts"
    [product]="product"
    [fileServerUrl]="fileServerUrl"
    [boxesOrdered]="product.boxesOrdered ? product.boxesOrdered : null"
    [halfShelvesOrdered]="
      product.halfShelvesOrdered ? product.halfShelvesOrdered : null
    "
    [comments]="product.comments ? product.comments : null"
  ></app-product-card>
</div>

<app-order-summary
  *ngIf="cart"
  [cart]="cart"
  [changeCount]="orderSummaryChangeCounter"
></app-order-summary>
