import { PlaceOrderModalComponent } from "./../modals/place-order-modal/place-order-modal.component";
import { CartService } from "../shared/services/cart.service";
import { Component, OnInit } from "@angular/core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { NotFoundError } from "../shared/errors/not-found-error";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ICart } from "../shared/interfaces/cart.interface";
import { SHELVES_PER_RACK } from "../shared/data/constants";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.css"],
})
export class CartComponent implements OnInit {
  constructor(
    private _cartService: CartService,
    private _modalService: NgbModal
  ) {}

  public cart: ICart;
  public removeFromCartIcon = faTimesCircle;
  public cartIcon = faShoppingCart;
  public changeCount = 0;
  public isOrderValid = false;
  public halfShelvesPerRack = SHELVES_PER_RACK * 2;

  private modalOption: NgbModalOptions = {};

  ngOnInit() {
    this._cartService.getUsersCart().subscribe(
      (res: ICart) => {
        this.cart = res;
        if (this.cart) {
          this._cartService.setCartComputedFields(this.cart);
        }
      },
      (error) => {
        console.log("There was an error getting the users cart.");
      }
    );
  }

  public removeItem(product) {
    this._cartService.removeItemFromCart(product.id).subscribe(
      (res: any) => {
        let alert = {
          type: "success",
          title: "Item Removed From Cart.",
          msg: "",
        };
        this.processRemoveItem(product.id);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let alert = {
            type: "success",
            title: "Item Removed From Cart.",
            msg: "",
          };
          this.processRemoveItem(product.id);
        } else {
          let removeItemResponse = {
            type: "error",
            productId: product.id,
            alert: {
              type: "danger",
              title: "Item Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          // this.alert = e.alert;
        }
      }
    );
  }

  private processRemoveItem(productId) {
    // Get index of product we just removed from cart
    let cartProductIndex = this.cart.Products.findIndex(
      (product) => product.id == productId
    );

    if (cartProductIndex >= 0) {
      //Remove from Cart
      this.cart.Products.splice(cartProductIndex, 1);
    }

    //Recalculate the totals
    this._cartService.setCartComputedFields(this.cart);

    //Update Change Count for Requirements to Pickup Change
    this.changeCount++;
  }

  public removeRack(rack) {
    this._cartService.removeRackFromCart(rack.id).subscribe(
      (res: any) => {
        let alert = {
          type: "success",
          title: "Rack Removed From Cart.",
          msg: "",
        };
        this.processRemoveRack(rack.id);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let alert = {
            type: "success",
            title: "Rack Removed From Cart.",
            msg: "",
          };
          this.processRemoveRack(rack.id);
        } else {
          let removeRackResponse = {
            type: "error",
            rackId: rack.id,
            alert: {
              type: "danger",
              title: "Rack Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          //this.alert = e.alert;
        }
      }
    );
  }

  private processRemoveRack(rackId) {
    // Get index of rack we just removed from cart
    let cartRackIndex = this.cart.Racks.findIndex((rack) => rack.id == rackId);

    if (cartRackIndex >= 0) {
      //Remove from Cart
      this.cart.Racks.splice(cartRackIndex, 1);
    }

    //Recalculate the totals
    this._cartService.setCartComputedFields(this.cart);

    //Update Change Count for Requirements to Pickup Change
    this.changeCount++;
  }

  public removeHalfShelf(halfShelf) {
    this._cartService.removeHalfShelfFromCart(halfShelf.id).subscribe(
      (res: any) => {
        let alert = {
          type: "success",
          title: "Half Shelf Removed From Cart.",
          msg: "",
        };
        this.processRemoveHalfShelf(halfShelf.id);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let alert = {
            type: "success",
            title: "Half Shelf Removed From Cart.",
            msg: "",
          };
          this.processRemoveHalfShelf(halfShelf.id);
        } else {
          let removeHalfShelfResponse = {
            type: "error",
            halfShelfId: halfShelf.id,
            alert: {
              type: "danger",
              title: "Half Shelf Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          //this.alert = e.alert;
        }
      }
    );
  }

  private processRemoveHalfShelf(halfShelfId) {
    // Get index of half shelf we just removed from cart
    let cartHalfShelfIndex = this.cart.HalfShelves.findIndex(
      (hs) => hs.id === halfShelfId
    );

    if (cartHalfShelfIndex >= 0) {
      //Remove from Cart
      this.cart.HalfShelves.splice(cartHalfShelfIndex, 1);
    }

    //Recalculate the totals
    this._cartService.setCartComputedFields(this.cart);

    //Update Change Count for Requirements to Pickup Change
    this.changeCount++;
  }

  public removeBoxSkid(boxSkid) {
    this._cartService.removeBoxSkidFromCart(boxSkid.id).subscribe(
      (res: any) => {
        let alert = {
          type: "success",
          title: "Box Skid Removed From Cart.",
          msg: "",
        };
        this.processRemoveBoxSkid(boxSkid.id);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let alert = {
            type: "success",
            title: "Box Skid Removed From Cart.",
            msg: "",
          };
          this.processRemoveBoxSkid(boxSkid.id);
        } else {
          let removeBoxSkidResponse = {
            type: "error",
            boxSkidId: boxSkid.id,
            alert: {
              type: "danger",
              title: "Box Skid Not Removed From Cart.",
              msg: error.originalError.errors[0].description,
            },
          };
          // this.alert = e.alert;
        }
      }
    );
  }

  private processRemoveBoxSkid(boxSkidId) {
    // Get index of half shelf we just removed from cart
    let cartBoxSkidIndex = this.cart.BoxSkids.findIndex(
      (boxSkid) => boxSkid.id === boxSkidId
    );

    if (cartBoxSkidIndex >= 0) {
      //Remove from Cart
      this.cart.BoxSkids.splice(cartBoxSkidIndex, 1);
    }

    //Recalculate the totals
    this._cartService.setCartComputedFields(this.cart);

    //Update Change Count for Requirements to Pickup Change
    this.changeCount++;
  }

  public startOrder() {
    this.modalOption.size = "lg";
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;

    const modalRef = this._modalService.open(
      PlaceOrderModalComponent,
      this.modalOption
    );
    modalRef.componentInstance.cart = this.cart;
  }

  public onOrderValidityChange(e) {
    this.isOrderValid = e.valid;
  }
}
