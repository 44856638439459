<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="row">
      <input
        name="orderId"
        id="orderId"
        [ngModel]="order.orderId"
        type="text"
        hidden
      />
      <div class="col-12 form-group">
        <label for="note">Note</label>
        <textarea
          rows="5"
          id="note"
          name="note"
          [ngModel]="order.note"
          #note="ngModel"
          class="form-control"
        ></textarea>
      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitChanges(form.value)"
        [disabled]="!form.valid"
      >
        Update
      </button>
    </div>
  </form>
</div>
