<div class="container">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    Prebuilt Racks
    <fa-icon
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <table class="table table-striped">
    <tr>
      <th>Name</th>
      <th>Description</th>
      <th>UPC</th>
      <th>Item No.</th>
      <th>Cost (entire rack)</th>
      <th>Display</th>
      <th>Admin Only</th>
    </tr>
    <tr id="tableControls">
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.name"
          placeholder="Filter by Name"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.description"
          placeholder="Filter by Desc"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.upc"
          placeholder="Filter by UPC"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.itemNo"
          placeholder="Filter by ItemNo."
        />
      </td>
      <td></td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.display"
          placeholder="Filter by Dispaly"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.adminOnly"
          placeholder="Filter by AdminOnly"
        />
      </td>
    </tr>
    <tr
      class="data-row"
      [id]="rack.id"
      (click)="startUpdate(rack)"
      *ngFor="let rack of racksShowing"
    >
      <td>{{ rack.name }}</td>
      <td>{{ rack.description }}</td>
      <td>{{ rack.upc }}</td>
      <td>{{ rack.itemNo }}</td>
      <td>{{ rack.eachCost | currency: "USD":"symbol":"1.2-2" }}</td>
      <td>{{ rack.display ? "Y" : "N" }}</td>
      <td>{{ rack.adminOnly ? "Y" : "N" }}</td>
    </tr>
  </table>
  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
  <button (click)="startAdd()" class="btn btn-primary">Add Rack</button>
</div>
