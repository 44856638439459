<div class="container">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    Customers<fa-icon
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <table class="table table-striped table-sm">
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Discount %</th>
      <th>Notes</th>
    </tr>
    <tr id="tableControls">
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.id"
          placeholder="Filter by Id"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.name"
          placeholder="Filter by Name"
        />
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr
      class="data-row"
      [id]="company.id"
      (click)="startUpdate(company)"
      *ngFor="let company of companiesShowing"
    >
      <td>{{ company.id }}</td>
      <td>{{ company.name }}</td>
      <td>{{ company.discount / 100 | percent: "1.2-2" }}</td>
      <td>{{ company.notes | summary: 50 }}</td>
    </tr>
  </table>

  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
  <button (click)="startAdd()" class="btn btn-primary">Add Customer</button>
</div>
