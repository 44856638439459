import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'shortId'
})
export class ShortIdPipe implements PipeTransform{
    transform(value:string): any{
        if (!value)
            return null;
        
        return value.length > 8 ? value.substr(0,8) : value;
    }
}