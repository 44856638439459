<div *ngIf="order" class="row">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>
  <div class="col-12">
    <h3>Invoice {{ order.id | shortId }}</h3>
  </div>
  <div class="col-6 order-info-container">
    <span
      ><strong>Date Placed:</strong>
      {{ order.placedDate | date : "shortDate" }}</span
    >
    <span *ngIf="order.shipDate"
      ><strong>Requested Ship Date:</strong>
      {{ order.shipDate | date : "shortDate" }}</span
    >
    <span
      ><strong>PO #:</strong> {{ order.poNumber }}
      <fa-icon
        class="edit-icon do-not-print"
        [icon]="editIcon"
        (click)="startUpdatePONumber()"
      ></fa-icon
    ></span>
    <span
      ><strong>Note:</strong> {{ order.note }}
      <fa-icon
        class="edit-icon do-not-print"
        [icon]="editIcon"
        (click)="startUpdateNote()"
      ></fa-icon
    ></span>
  </div>

  <div class="col-3 bill-to-container">
    <span><strong>Bill To </strong></span>
    <span>{{ order.companyName }}</span>
    <span *ngIf="order.billingStreetAddress1">{{
      order.billingStreetAddress1
    }}</span>
    <span *ngIf="order.billingStreetAddress2">{{
      order.billingStreetAddress2
    }}</span>
    <span *ngIf="order.billingPOBox">PO Box {{ order.billingPOBox }}</span>
    <span *ngIf="order.billingCity"
      >{{ order.billingCity }}, {{ order.billingState }}
      {{ order.billingZip }}</span
    >
    <hr
      *ngIf="
        order.billingConactName ||
        order.billingContactEmail ||
        order.billingContactPhone
      "
    />
    <span>{{ order.billingContactName }}</span>
    <span>{{ order.billingContactEmail }}</span>
    <span>{{ order.billingContactPhone }}</span>
  </div>
  <div class="col-3 ship-to-container">
    <span><strong>Ship To </strong></span>
    <span>{{ order.shippingCompanyName }}</span>
    <span *ngIf="order.shippingStreetAddress1">{{
      order.shippingStreetAddress1
    }}</span>
    <span *ngIf="order.shippingStreetAddress2">{{
      order.shippingStreetAddress2
    }}</span>
    <span *ngIf="order.shippingCity"
      >{{ order.shippingCity }}, {{ order.shippingState }}
      {{ order.shippingZip }}</span
    >
    <hr
      *ngIf="
        order.shippingConactName ||
        order.shippingContactEmail ||
        order.shippingContactPhone
      "
    />
    <span>{{ order.shippingContactName }}</span>
    <span>{{ order.shippingContactEmail }}</span>
    <span>{{ order.shippingContactPhone }}</span>
  </div>
  <div class="col-12">
    <table id="my-table" class="table">
      <thead class="thead-light">
        <tr>
          <th>Line</th>
          <th>Name</th>
          <th>Item #</th>
          <th>Pieces</th>
          <th>Unit</th>
          <th>Unit Price</th>
          <th class="table-align-right">Extended Price</th>
        </tr>
      </thead>

      <ng-container *ngFor="let rack of order.OrderRacks; index as i">
        <!-- Ordered Rack -->
        <tr [class.is-striped]="rack.line % 2 === 0">
          <td>{{ rack.line }}</td>
          <td>
            {{ rack.name }}
          </td>
          <td>
            {{ rack.itemNo }}
          </td>
          <td>{{ rack.pieceTotal }}</td>
          <td>{{ rack.orderedQty }} rack</td>
          <td>
            {{ rack.eachCost | currency : "USD" : "symbol" : "1.2-2" }}/rack
          </td>
          <td class="table-align-right">
            {{ rack.orderedTotal | currency : "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="rack.comments">
          <td [class.no-top-border]="rack.comments"></td>
          <td
            class="comments"
            [class.no-top-border]="rack.comments"
            colspan="6"
          >
            {{ rack.comments }}
          </td>
        </tr>
        <ng-container
          *ngFor="let lineItem of rack.OrderRackProducts; index as j"
        >
          <tr style="color: lightslategray">
            <td class="no-top-border">
              &nbsp;&nbsp;&nbsp;{{ lineItem.line + "." + lineItem.subline }}
            </td>
            <td colspan="2">
              {{ lineItem.productName }}
            </td>
            <td>{{ lineItem.orderedQty }}</td>
            <td>1 ea.</td>
            <td>
              {{
                lineItem.eachCost | currency : "USD" : "symbol" : "1.2-2"
              }}/ea.
            </td>
            <td class="table-align-right">
              {{
                lineItem.orderedTotal | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
        </ng-container>
      </ng-container>

      <ng-container
        *ngFor="let halfShelf of order.OrderHalfShelves; index as i"
      >
        <!-- Ordered Half Shelf -->
        <tr [class.is-striped]="halfShelf.line % 2 === 0">
          <td>{{ halfShelf.line }}</td>
          <td>
            {{ halfShelf.name }}
          </td>
          <td>
            {{ halfShelf.itemNo }}
          </td>
          <td>{{ halfShelf.pieceTotal }}</td>
          <td>{{ halfShelf.orderedQty }} half Shelf</td>
          <td>
            {{
              halfShelf.eachCost | currency : "USD" : "symbol" : "1.2-2"
            }}/half shelf
          </td>
          <td class="table-align-right">
            {{ halfShelf.orderedTotal | currency : "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="halfShelf.comments">
          <td [class.no-top-border]="halfShelf.comments"></td>
          <td
            class="comments"
            [class.no-top-border]="halfShelf.comments"
            colspan="6"
          >
            {{ halfShelf.comments }}
          </td>
        </tr>
        <ng-container
          *ngFor="let lineItem of halfShelf.OrderHalfShelfProducts; index as j"
        >
          <tr style="color: lightslategray">
            <td class="no-top-border">
              &nbsp;&nbsp;&nbsp;{{ lineItem.line + "." + lineItem.subline }}
            </td>
            <td colspan="2">
              {{ lineItem.productName }}
            </td>
            <td>{{ lineItem.orderedQty }}</td>
            <td>1 ea.</td>
            <td>
              {{
                lineItem.eachCost | currency : "USD" : "symbol" : "1.2-2"
              }}/ea.
            </td>
            <td class="table-align-right">
              {{
                lineItem.orderedTotal | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let boxSkid of order.OrderBoxSkids; index as i">
        <!-- Ordered Box Skid -->
        <tr [class.is-striped]="boxSkid.line % 2 === 0">
          <td>{{ boxSkid.line }}</td>
          <td>
            {{ boxSkid.name }}
          </td>
          <td>
            {{ boxSkid.itemNo }}
          </td>
          <td>{{ boxSkid.pieceTotal }}</td>
          <td>{{ boxSkid.orderedQty }} skid</td>
          <td>
            {{ boxSkid.eachCost | currency : "USD" : "symbol" : "1.2-2" }}/skid
          </td>
          <td class="table-align-right">
            {{ boxSkid.orderedTotal | currency : "USD" : "symbol" : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="boxSkid.comments">
          <td [class.no-top-border]="boxSkid.comments"></td>
          <td
            class="comments"
            [class.no-top-border]="boxSkid.comments"
            colspan="6"
          >
            {{ boxSkid.comments }}
          </td>
        </tr>
        <ng-container
          *ngFor="let lineItem of boxSkid.OrderBoxSkidProducts; index as j"
        >
          <tr style="color: lightslategray">
            <td class="no-top-border">
              &nbsp;&nbsp;&nbsp;{{ lineItem.line + "." + lineItem.subline }}
            </td>
            <td colspan="2">
              {{ lineItem.productName }}
            </td>
            <td>{{ lineItem.pieceTotal }}</td>
            <td>{{ lineItem.boxOrderedQty }} box</td>
            <td>
              {{
                lineItem.boxEachCost | currency : "USD" : "symbol" : "1.2-2"
              }}/box
            </td>
            <td class="table-align-right">
              {{
                lineItem.orderedTotal | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let lineItem of order.OrderProducts; let i = index">
        <!-- Ordered Box and half shelf -->
        <ng-container *ngIf="lineItem.boxQty > 0 && lineItem.halfShelfQty > 0">
          <tr [class.is-striped]="lineItem.line % 2 === 0">
            <td rowspan="2">
              {{ lineItem.line }}
            </td>
            <td rowspan="2">
              {{ lineItem.productName }}
              <span class="product-description"
                ><br />{{ lineItem.productDescription }}
              </span>
            </td>
            <td>{{ lineItem.itemNo }}-HS</td>
            <td>
              {{ lineItem.qtyPerHalfShelf * lineItem.halfShelfQty }}
            </td>
            <td>{{ lineItem.halfShelfQty }} half shelf</td>
            <td>
              {{
                lineItem.eachCost * lineItem.qtyPerHalfShelf
                  | currency : "USD" : "symbol" : "1.2-2"
              }}/half shelf
            </td>
            <td class="table-align-right">
              {{
                lineItem.halfShelfQty *
                  lineItem.eachCost *
                  lineItem.qtyPerHalfShelf
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
          <tr [class.is-striped]="lineItem.line % 2 === 0">
            <td>{{ lineItem.itemNo }}-B</td>
            <td>
              {{ lineItem.qtyPerBox * lineItem.boxQty }}
            </td>
            <td>{{ lineItem.boxQty }} box</td>
            <td>
              {{
                lineItem.eachCost * lineItem.qtyPerBox
                  | currency : "USD" : "symbol" : "1.2-2"
              }}/box
            </td>
            <td class="table-align-right">
              {{
                lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
          <tr *ngIf="lineItem.comments" [class.is-striped]="i % 2">
            <td [class.no-top-border]="lineItem.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="lineItem.comments"
              colspan="6"
            >
              {{ lineItem.comments }}
            </td>
            <td
              [class.no-top-border]="lineItem.comments"
              *ngIf="isAdmin() && !order.isShipped"
            ></td>
          </tr>
        </ng-container>

        <!-- Ordered only half shelf -->
        <ng-container *ngIf="lineItem.halfShelfQty > 0 && lineItem.boxQty == 0">
          <tr [class.is-striped]="lineItem.line % 2 === 0">
            <td>
              {{ lineItem.line }}
            </td>
            <td>
              {{ lineItem.productName }}
              <span class="product-description"
                ><br />{{ lineItem.productDescription }}
              </span>
            </td>
            <td>{{ lineItem.itemNo }}-HS</td>
            <td>
              {{ lineItem.qtyPerHalfShelf * lineItem.halfShelfQty }}
            </td>
            <td>{{ lineItem.halfShelfQty }} half shelf</td>
            <td>
              {{
                lineItem.eachCost * lineItem.qtyPerHalfShelf
                  | currency : "USD" : "symbol" : "1.2-2"
              }}/half shelf
            </td>
            <td class="table-align-right">
              {{
                lineItem.halfShelfQty *
                  lineItem.eachCost *
                  lineItem.qtyPerHalfShelf
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
          <tr *ngIf="lineItem.comments" [class.is-striped]="i % 2">
            <td [class.no-top-border]="lineItem.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="lineItem.comments"
              colspan="6"
            >
              {{ lineItem.comments }}
            </td>
            <td
              [class.no-top-border]="lineItem.comments"
              *ngIf="isAdmin() && !order.isShipped"
            ></td>
          </tr>
        </ng-container>

        <!-- Ordered only box -->
        <ng-container *ngIf="lineItem.boxQty > 0 && lineItem.halfShelfQty == 0">
          <tr [class.is-striped]="lineItem.line % 2 === 0">
            <td>
              {{ lineItem.line }}
            </td>
            <td>
              {{ lineItem.productName }}
              <span class="product-description"
                ><br />{{ lineItem.productDescription }}
              </span>
            </td>
            <td>{{ lineItem.itemNo }}-B</td>
            <td>
              {{ lineItem.qtyPerBox * lineItem.boxQty }}
            </td>
            <td>{{ lineItem.boxQty }} box</td>
            <td>
              {{
                lineItem.eachCost * lineItem.qtyPerBox
                  | currency : "USD" : "symbol" : "1.2-2"
              }}/box
            </td>
            <td class="table-align-right">
              {{
                lineItem.boxQty * lineItem.eachCost * lineItem.qtyPerBox
                  | currency : "USD" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
          <tr *ngIf="lineItem.comments" [class.is-striped]="i % 2">
            <td [class.no-top-border]="lineItem.comments"></td>
            <td
              class="comments"
              [class.no-top-border]="lineItem.comments"
              colspan="6"
            >
              {{ lineItem.comments }}
            </td>
            <td
              [class.no-top-border]="lineItem.comments"
              *ngIf="isAdmin() && !order.isShipped"
            ></td>
          </tr>
        </ng-container>
      </ng-container>
      <tr>
        <th class="table-align-right unit-totals-cell" colspan="5">
          <span
            *ngIf="
              order.halfShelvesOrdered > 0 ||
              order.prebuiltRacksOrdered > 0 ||
              order.prebuiltHalfShelvesOrdered > 0
            "
            >{{
              (order.halfShelvesOrdered + order.prebuiltHalfShelvesOrdered) /
                this.halfShelvesPerRack +
                order.prebuiltRacksOrdered
            }}
            {{
              (order.halfShelvesOrdered + order.prebuiltHalfShelvesOrdered) /
                this.halfShelvesPerRack +
                order.prebuiltRacksOrdered >
              1
                ? "racks;"
                : "rack;"
            }}
          </span>
          <span *ngIf="totalBoxesOrdered > 0"
            >{{ totalBoxesOrdered }}
            {{ totalBoxesOrdered > 1 ? "boxes" : "box" }}</span
          >
        </th>
        <th class="table-align-right">Product Total</th>
        <th class="table-align-right">
          {{ order.productTotal | currency : "USD" : "symbol" : "1.2-2" }}
        </th>
      </tr>
      <tr class="shipping-row">
        <th class="table-align-right" colspan="6">
          Shipping
          <span *ngIf="order.shippingRate != null"
            >({{ order.shippingRate / 100 | percent : "1.2-2" }})</span
          >
        </th>
        <th *ngIf="order.shippingRate == null" class="table-align-right">
          TBD
        </th>
        <th *ngIf="order.shippingRate != null" class="table-align-right">
          {{ order.shippingTotal | currency : "USD" : "symbol" : "1.2-2" }}
        </th>
      </tr>
      <tr *ngIf="order.liftGateCharge > 0" class="liftgatecharge-row">
        <th class="table-align-right" colspan="6">Liftgate Charge</th>
        <th class="table-align-right">
          {{ order.liftGateCharge | currency : "USD" : "symbol" : "1.2-2" }}
        </th>
      </tr>
      <tr *ngIf="order.customerDiscount > 0" class="discount-row">
        <th class="table-align-right" colspan="6">
          Customer Discount
          <span>({{ order.customerDiscount / 100 | percent : "1.2-2" }})</span>
        </th>
        <th class="table-align-right">
          ({{
            order.customerDiscountAmt | currency : "USD" : "symbol" : "1.2-2"
          }})
        </th>
      </tr>
      <tr *ngIf="order.orderDiscount > 0" class="discount-row">
        <th class="table-align-right" colspan="6">
          Order Discount
          <span>({{ order.orderDiscount / 100 | percent : "1.2-2" }})</span>
        </th>
        <th class="table-align-right">
          ({{ order.orderDiscountAmt | currency : "USD" : "symbol" : "1.2-2" }})
        </th>
      </tr>
      <tr *ngIf="order.credit > 0">
        <th class="table-align-right" colspan="6">Credit</th>
        <th class="table-align-right">
          ({{ order.credit | currency : "USD" : "symbol" : "1.2-2" }})
        </th>
      </tr>
      <tr class="order-total-row">
        <th class="table-align-right" colspan="6">Order Total</th>
        <th *ngIf="order.shippingRate == null" class="table-align-right">
          TBD
        </th>
        <th *ngIf="order.shippingRate != null" class="table-align-right">
          {{
            order.productTotal +
              order.shippingTotal +
              order.liftGateCharge -
              order.customerDiscountAmt -
              order.orderDiscountAmt -
              order.credit | currency : "USD" : "symbol" : "1.2-2"
          }}
        </th>
      </tr>
    </table>
  </div>
  <button class="btn btn-default print-btn do-not-print" (click)="startPrint()">
    Print
  </button>
  <button class="btn btn-default do-not-print" (click)="startSendInvoice()">
    Email
  </button>
  <button
    *ngIf="isAdmin()"
    class="btn btn-danger delete-btn do-not-print"
    (click)="confirmDelete(deleteTemplate, $event)"
  >
    Delete
  </button>
</div>

<!-- Confirm Delete Modal  -->
<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to delete this order?</p>
      <p class="alert alert-danger">
        <strong>Note:</strong> This action cannot be undone.
      </p>
    </div>
    <form #deleteForm="ngForm">
      <div class="form-group">
        <input type="number" hidden [ngModel]="order.id" name="id" id="id" />
        <button class="btn btn-primary" (click)="delete(deleteForm.value.id)">
          Confirm Delete
        </button>
        <button class="btn btn-default float-right" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
