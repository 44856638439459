<div class="container">
  <ngb-alert *ngIf="alert" type="{{ alert.type }}" (close)="clearToastAlert()">
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    Addresses<fa-icon
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <div class="table-responsive-xl">
    <table class="table table-striped table-sm">
      <tr>
        <th>Type</th>
        <th>Company</th>
        <th>Ship-to Name</th>
        <th>Street</th>
        <th>PO Box</th>
        <th>City</th>
        <th>State</th>
        <th>Zip</th>
        <th>Shipping Rate</th>
      </tr>
      <tr id="tableControls">
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.type"
            placeholder="Filter by Type"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.company"
            placeholder="Filter by Company"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.companyName"
            placeholder="Filter by Ship-to"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.street"
            placeholder="Filter by Street"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.poBox"
            placeholder="Filter by PO Box"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.city"
            placeholder="Filter by State"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.state"
            placeholder="Filter by City"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            (keyup)="changeFilter()"
            [(ngModel)]="filters.zip"
            placeholder="Filter by Zip"
          />
        </td>
        <td></td>
      </tr>
      <tr
        class="data-row"
        [id]="address.id"
        (click)="startUpdate(address)"
        *ngFor="let address of addressesShowing"
      >
        <td>{{ address.type.toUpperCase() }}</td>
        <td>{{ address.Company?.name }}</td>
        <td>{{ address.companyName }}</td>
        <td>{{ address.street1 }} {{ address.street2 }}</td>
        <td>{{ address.poBox }}</td>
        <td>{{ address.city }}</td>
        <td>{{ address.state }}</td>
        <td>{{ address.zip }}</td>
        <td>{{ address.shippingRate }}</td>
      </tr>
    </table>
  </div>
  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
</div>
