import { Component, TemplateRef, Input } from "@angular/core";
import { Router } from "@angular/router";
import { OrderService } from "../shared/services/order.service";
import { AuthService } from "../auth/services/auth.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundError } from "../shared/errors/not-found-error";
import { SendInvoiceModalComponent } from "../modals/send-invoice-modal/send-invoice-modal.component";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { ILineItem } from "../shared/interfaces/line-item.interface";
import { PrintPDFService } from "../shared/services/print-pdf.service";
import { EditPoNumberModalComponent } from "../modals/edit-po-number-modal/edit-po-number-modal.component";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { SHELVES_PER_RACK } from "../shared/data/constants";
import { EditOrderNoteModalComponent } from "../modals/edit-order-note-modal/edit-order-note-modal.component";
import { PrintOrderModalComponent } from "../modals/print-order-modal/print-order-modal.component";
import { NumberUtils } from "../utils/number-utils";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"],
})
export class InvoiceComponent {
  constructor(
    private _orderService: OrderService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _router: Router,
    private _printPDFService: PrintPDFService
  ) {}

  @Input() order;
  public alert: any;
  public activeModal: NgbActiveModal;
  public editIcon = faEdit;
  public halfShelvesPerRack = SHELVES_PER_RACK * 2;
  public totalBoxesOrdered = 0;

  ngOnChanges() {
    if (this.order)
      this.totalBoxesOrdered = NumberUtils.getBoxesOrdered(this.order);
  }

  public startPrint() {
    const modalRef = this._modalService.open(PrintOrderModalComponent);
    modalRef.componentInstance.print.subscribe((event) => {
      this.onPrint(event);
    });
  }

  public onPrint(type: "Order Confirmation" | "Invoice" | "Packing Slip") {
    this._printPDFService.print(type, this.order);
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public startSendInvoice() {
    const modalRef = this._modalService.open(SendInvoiceModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.orderId = this.order.id;
  }

  public closeModal() {
    this.activeModal.dismiss();
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._orderService.delete(id).subscribe(
      (res: any) => {
        this.alert = {
          type: "success",
          title: "Order deleted.",
          msg: res.message,
        };
        this._router.navigate(["orders"]);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          this.alert = {
            type: "success",
            title: "Order deleted.",
            msg: "Order " + this.order.id + " has been deleted.",
          };
          this._router.navigate(["orders"]);
        } else {
          this.alert = {
            type: "danger",
            title: "Order was not deleted.",
            msg: error.originalError.errors[0].description,
          };
        }
      }
    );
  }

  public startUpdatePONumber() {
    this.clearToastAlert();
    let dto = {
      orderId: this.order.id,
      poNumber: this.order.poNumber,
    };

    const modalRef = this._modalService.open(EditPoNumberModalComponent);
    modalRef.componentInstance.order = dto;
    modalRef.componentInstance.updatePONumber.subscribe((event) => {
      this.onUpdatePONumber(event);
    });
  }

  public onUpdatePONumber(e) {
    if (e.type === "success") {
      this.order.poNumber = e.poNumber;
      this.alert = {
        type: "success",
        title: "Updated",
        msg: "PO number has been updated.",
      };
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public startUpdateNote() {
    this.clearToastAlert();
    let dto = {
      orderId: this.order.id,
      note: this.order.note,
    };

    const modalRef = this._modalService.open(EditOrderNoteModalComponent);
    modalRef.componentInstance.order = dto;
    modalRef.componentInstance.updateOrderNote.subscribe((event) => {
      this.onUpdateNote(event);
    });
  }

  public onUpdateNote(e) {
    if (e.type === "success") {
      this.order.note = e.note;
      this.alert = {
        type: "success",
        title: "Updated",
        msg: "Order Note has been updated.",
      };
    } else {
      this.alert = {
        type: "danger",
        title: "Not Updated",
        msg: e.error.msg,
      };
    }
  }

  public clearToastAlert() {
    this.alert = null;
  }
}
