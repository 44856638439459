import { RoleService } from "../../shared/services/role.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../shared/services/user.service";
import { NotFoundError } from "../../shared/errors/not-found-error";
import { IUser } from "src/app/shared/interfaces/user.interface";
import { CompanyService } from "src/app/shared/services/company.service";

@Component({
  selector: "app-user-modal",
  templateUrl: "./user-modal.component.html",
  styleUrls: ["./user-modal.component.css"]
})
export class UserModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _roleService: RoleService,
    private _userService: UserService,
    private _companyService: CompanyService
  ) {}

  @Input() user: IUser;
  @Output() createUser = new EventEmitter<any>();
  @Output() deleteUser = new EventEmitter<any>();
  @Output() updateUser = new EventEmitter<any>();

  public roleOptions;
  public companyOptions;
  public modalAction: ModalActions;
  public dto: any;

  public formOptions = {
    selectedRole: null,
    selectedCompany: { id: "-1" }
  };

  ngOnInit() {
    //Determine Purpose of Launching this Modal
    if (this.user) {
      this.modalAction = ModalActions.Edit;
      this.formOptions.selectedRole = this.user.Roles[0];
      if (this.user.Company)
        this.formOptions.selectedCompany = this.user.Company;
    } else {
      this.modalAction = ModalActions.Create;
      this.user = <IUser>{};
    }

    this._roleService.getAll().subscribe(
      roles => {
        this.roleOptions = roles;
      },
      error => {
        console.log("There was an error getting role options.");
      }
    );

    this._companyService.getAll().subscribe(
      res => {
        this.companyOptions = res;
      },
      error => {
        console.log("There was an error getting companies");
      }
    );
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New User";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit User";
    } else {
      return "User Form";
    }
  }

  public isFirstRole(role) {
    // Even though we have a many-to-many relationship on the
    // backend, we are limiting our business case for users to only have
    // a single role for now.
    if (this.user.Roles) {
      if (this.user.Roles.length > 0) {
        return role.name === this.user.Roles[0].name;
      }
    }
  }

  public closeModal() {
    this.activeModal.close();
  }

  public onRoleChange(role) {
    //Currently, we only have business requirement to assign one role to a user.
    this.formOptions.selectedRole = role;
  }

  public onCompanyChange(companyId) {
    //Currently, we only have business requirement to assign one copmany to a user.
    if (companyId == "-1") {
      this.formOptions.selectedCompany = { id: "-1" };
    } else {
      this.formOptions.selectedCompany = this.companyOptions.find(
        company => company.id == companyId
      );
    }
  }

  public add(data) {
    this.dto = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      Roles: [this.formOptions.selectedRole]
    };

    if (!this.formOptions.selectedRole) {
      let userCreateResponse = {
        type: "error",
        user: this.dto,
        alert: {
          type: "danger",
          title: "User Not Created",
          msg: "User has to have a role."
        }
      };
      this.activeModal.close();
      this.createUser.emit(userCreateResponse);
      return;
    }

    if (this.formOptions.selectedRole.name == "Customer") {
      this.dto.companyId = this.formOptions.selectedCompany.id;
    }

    this._userService.create(this.dto).subscribe(
      (res: any) => {
        let userCreateResponse = {
          type: "success",
          user: res.user,
          alert: {
            type: res.status,
            title: "User Added",
            msg: res.message
          }
        };
        this.activeModal.close();
        this.createUser.emit(userCreateResponse);
      },
      error => {
        let userCreateResponse = {
          type: "error",
          user: this.dto,
          alert: {
            type: "danger",
            title: "User Not Created",
            msg: error.originalError.error.errors[0].description
          }
        };
        this.activeModal.close();
        this.createUser.emit(userCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._userService.delete(id).subscribe(
      (res: any) => {
        let deleteUserResponse = {
          type: "success",
          user: this.user,
          alert: {
            type: "success",
            title: "User deleted.",
            msg: res.message
          }
        };
        this.deleteUser.emit(deleteUserResponse);
      },
      error => {
        if (error instanceof NotFoundError) {
          let deleteUserResponse = {
            type: "success",
            user: this.user,
            alert: {
              type: "success",
              title: "User deleted.",
              msg: "User " + this.user.email + " has been deleted."
            }
          };
          this.deleteUser.emit(deleteUserResponse);
        } else {
          let deleteUserResponse = {
            type: "error",
            user: this.user,
            alert: {
              type: "danger",
              title: "User was not deleted.",
              msg: error.originalError.errors[0].description
            }
          };
          this.deleteUser.emit(deleteUserResponse);
        }
      }
    );
  }

  public update(data) {
    this.dto = {
      id: this.user.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      Roles: [this.formOptions.selectedRole]
    };

    if (this.formOptions.selectedRole.name == "Customer") {
      this.dto.companyId = this.formOptions.selectedCompany.id;
    }

    this._userService.update(this.dto).subscribe(
      (res: any) => {
        let userUpdateResponse = {
          type: "success",
          user: res,
          alert: {
            type: "success",
            title: "User Updated",
            msg: "User " + res.email + " was updated."
          }
        };
        this.activeModal.close();
        this.updateUser.emit(userUpdateResponse);
      },
      error => {
        let userUpdateResponse = {
          type: "error",
          user: this.dto,
          alert: {
            type: "danger",
            title: "User Not Updated",
            msg: error.originalError.error.errors[0].description
          }
        };
        this.activeModal.close();
        this.updateUser.emit(userUpdateResponse);
      }
    );
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create"
}
