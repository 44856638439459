import { Component, OnChanges, Input, ViewEncapsulation } from "@angular/core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  MIN_NUM_BOXES,
  MIN_ORDER_AMT,
  SHELVES_PER_RACK,
} from "../shared/data/constants";
import { ICart } from "../shared/interfaces/cart.interface";
import { CartService } from "./../shared/services/cart.service";

@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./order-summary.component.css"],
})
export class OrderSummaryComponent implements OnChanges {
  constructor(private _cartService: CartService) {}

  ngOnChanges() {
    console.log(this.cart);
    this._cartService.setCartComputedFields(this.cart);
  }

  @Input() cart: ICart;
  @Input() changeCount;
  public infoIcon = faInfoCircle;
  public checkIcon = faCheck;
  public xIcon = faTimes;

  // Help Info
  // --> Racks
  public rackHelpInfoTitle = "Rack Requirement";
  public rackHelpInfoMsg = `If ordering half shelves, all racks must ship full. Each rack contians ${SHELVES_PER_RACK} shelves (${
    SHELVES_PER_RACK * 2
  } - half shelves)`;

  // --> Boxes
  public boxHelpInfoTitle = "Box Requirement";
  public boxHelpInfoMsg = `If ordering boxes, there is an order minimum of ${MIN_NUM_BOXES} boxes`;

  // --> Total
  public totalHelpInfoTitle = "Order Value Requirement";
  public totalHelpInfoMsg = `Minimum order value is $${MIN_ORDER_AMT.toLocaleString()}`;

  public orderSummary = {
    racks: 0,
    boxes: 0,
    rackProductTotal: 0,
    boxProductTotal: 0,
  };
}
