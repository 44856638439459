<div class="container">
  <ngb-alert
    class="fixed-alert"
    *ngIf="alert"
    type="{{ alert.type }}"
    (close)="clearToastAlert()"
  >
    <strong>{{ alert.title }}</strong> {{ alert.msg }}
  </ngb-alert>

  <h3>
    Prebuilt Skids of Boxes
    <fa-icon
      id="download-icon"
      class="float-right"
      [icon]="downloadIcon"
      (click)="exportToCSV()"
    ></fa-icon>
  </h3>
  <table class="table table-striped">
    <tr>
      <th>Name</th>
      <th>Description</th>
      <th>UPC</th>
      <th>Item No.</th>
      <th>Cost (entire skid)</th>
      <th>Display</th>
      <th>Admin Only</th>
    </tr>
    <tr id="tableControls">
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.name"
          placeholder="Filter by Name"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.description"
          placeholder="Filter by Desc"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.upc"
          placeholder="Filter by UPC"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.itemNo"
          placeholder="Filter by ItemNo"
        />
      </td>
      <td></td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.display"
          placeholder="Filter by Dispaly"
        />
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          (keyup)="changeFilter()"
          [(ngModel)]="filters.adminOnly"
          placeholder="Filter by Admin Only"
        />
      </td>
    </tr>
    <tr
      class="data-row"
      [id]="boxSkid.id"
      (click)="startUpdate(boxSkid)"
      *ngFor="let boxSkid of boxSkidsShowing"
    >
      <td>{{ boxSkid.name }}</td>
      <td>{{ boxSkid.description }}</td>
      <td>{{ boxSkid.upc }}</td>
      <td>{{ boxSkid.itemNo }}</td>
      <td>
        {{ boxSkid.eachCost | currency : "USD" : "symbol" : "1.2-2" }}
      </td>
      <td>{{ boxSkid.display ? "Y" : "N" }}</td>
      <td>{{ boxSkid.adminOnly ? "Y" : "N" }}</td>
    </tr>
  </table>
  <div>
    <ngb-pagination
      size="sm"
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      (pageChange)="pageChanged($event)"
      [pageSize]="itemsPerPage"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
  <button (click)="startAdd()" class="btn btn-primary">
    Add Skid of Boxes
  </button>
</div>
