import { CompanyService } from "./../../shared/services/company.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ICompany } from "src/app/shared/interfaces/company.interface";
import { NotFoundError } from "src/app/shared/errors/not-found-error";

@Component({
  selector: "app-company-modal",
  templateUrl: "./company-modal.component.html",
  styleUrls: ["./company-modal.component.css"],
})
export class CompanyModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _companyService: CompanyService
  ) {}

  @Input() company: ICompany;
  @Output() createCompany = new EventEmitter<any>();
  @Output() deleteCompany = new EventEmitter<any>();
  @Output() updateCompany = new EventEmitter<any>();

  public modalAction: ModalActions;
  public dto: any;

  ngOnInit() {
    //Determine Purpose of Launching this Modal
    if (this.company) {
      this.modalAction = ModalActions.Edit;
    } else {
      this.modalAction = ModalActions.Create;
      this.company = <ICompany>{};
    }
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New Customer";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit Customer";
    } else {
      return "Customer Form";
    }
  }

  public closeModal() {
    this.activeModal.close();
  }

  public add(data) {
    this.dto = {
      id: data.id,
      name: data.name,
      discount: data.discount,
      notes: data.notes,
    };

    this._companyService.create(this.dto).subscribe(
      (res: any) => {
        let companyCreateResponse = {
          type: "success",
          company: res.company,
          alert: {
            type: res.status,
            title: "Company Added",
            msg: res.message,
          },
        };
        this.activeModal.close();
        this.createCompany.emit(companyCreateResponse);
      },
      (error) => {
        let companyCreateResponse = {
          type: "error",
          company: this.dto,
          alert: {
            type: "danger",
            title: "Company Not Created",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.createCompany.emit(companyCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._companyService.delete(id).subscribe(
      (res: any) => {
        let deleteCompanyResponse = {
          type: "success",
          company: this.company,
          alert: {
            type: "success",
            title: "Company deleted.",
            msg: res.message,
          },
        };
        this.deleteCompany.emit(deleteCompanyResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let deleteCompanyResponse = {
            type: "success",
            company: this.company,
            alert: {
              type: "success",
              title: "Company deleted.",
              msg: "Company " + this.company.name + " has been deleted.",
            },
          };
          this.deleteCompany.emit(deleteCompanyResponse);
        } else {
          let deleteCompanyResponse = {
            type: "error",
            company: this.company,
            alert: {
              type: "danger",
              title: "Company was not deleted.",
              msg: error.originalError.errors[0].description,
            },
          };
          this.deleteCompany.emit(deleteCompanyResponse);
        }
      }
    );
  }

  public update(data) {
    this.dto = {
      id: this.company.id,
      name: data.name,
      discount: data.discount,
      notes: data.notes,
    };

    this._companyService.update(this.dto).subscribe(
      (res: any) => {
        let companyUpdateResponse = {
          type: "success",
          company: res,
          alert: {
            type: "success",
            title: "Company Updated",
            msg: "Company " + res.name + " was updated.",
          },
        };
        this.activeModal.close();
        this.updateCompany.emit(companyUpdateResponse);
      },
      (error) => {
        let companyUpdateResponse = {
          type: "error",
          company: this.dto,
          alert: {
            type: "danger",
            title: "Company Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateCompany.emit(companyUpdateResponse);
      }
    );
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create",
}
