<div class="modal-header">
  <h4 class="modal-title pull-left">Add Liftgate</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="col-12 form-group">
      <input
        type="text"
        hidden
        class="form-control"
        id="orderId"
        name="orderId"
        [ngModel]="orderId"
      />
    </div>
    <div class="col-12 form-group">
      <p>Are you sure you want to add a liftgate charge to this order?</p>
    </div>
    <div class="modal-button-group form-group">
      <button class="btn btn-primary" (click)="addLiftGateToOrder(form.value)">
        Confirm
      </button>
      <button class="btn btn-default float-right" (click)="closeModal()">
        Cancel
      </button>
    </div>
  </form>
</div>
