import { CompanyService } from "./../../shared/services/company.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/auth/services/auth.service";
import { OrderService } from "src/app/shared/services/order.service";
import { Router } from "@angular/router";
import { IOrderDTO } from "src/app/shared/interfaces/order-dto.interface";
import {
  IAddress,
  AddressTypes
} from "src/app/shared/interfaces/address.interface";
import { STATES } from "src/app/shared/data/states";

@Component({
  selector: "app-edit-order-address-modal",
  templateUrl: "./edit-order-address-modal.component.html",
  styleUrls: ["./edit-order-address-modal.component.css"]
})
export class EditOrderAddressModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _orderService: OrderService,
    private _companyService: CompanyService,
    private _router: Router
  ) {}

  @Input() address: any;
  @Input() info: any;
  @Output() updateAddress = new EventEmitter<any>();

  public addressesReady: boolean;
  public isLoading = false;
  public action: string;

  // Drop Down Options
  public addressOptions = [];
  public stateOptions: Array<string> = [];

  public activeStep;
  public editOrderAddressSteps = ["selectAction", "enterInfo"];

  ngOnInit() {
    for (let key in STATES) {
      this.stateOptions.push(key);
    }

    //Set the starting point of the form for Admins
    this.activeStep = "selectAction";

    //Update the available billing and shipping address options
    this.addressesReady = false;

    this._companyService.getAddressesByCompanyId(this.info.companyId).subscribe(
      (res: Array<IAddress>) => {
        this.addressOptions = res.filter(
          address => address.type == this.info.addressType
        );
        this.addressesReady = true;
      },
      error => {
        console.log(
          "There was an error getting the addresses for company on the order."
        );
      }
    );
  }

  public getModalHeader() {
    return 'Edit Order\'s Address';
  }

  public closeModal() {
    this.activeModal.close();
  }

  public isAdmin() {
    return this._authService.isAdmin();
  }

  public moveToNextStep(selectedAction) {
    this.activeStep = "enterInfo";
    this.action = selectedAction;
  }

  public moveToPriorStep(f) {
    this.action = null;
    this.activeStep = "selectAction";
  }

  public submitChanges(f) {
    let dto = {
      data: f,
      addressType: this.info.addressType,
      action: this.action,
      orderId: this.info.orderId,
      companyId: this.info.companyId
    };

    this._orderService.updateOrderAddress(dto).subscribe(
      (res: any) => {
        this.updateAddress.emit(res);
        this.activeModal.close();
      },
      error => {
        console.log(error);
      }
    );
  }
}
