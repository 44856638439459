import { CanActivateViaAdminOrCustomerAuthGuard } from "./can-activate-via-admin-or-customer-auth.guard";
import { CanActivateViaCustomerAuthGuard } from "./can-activate-via-customer-auth.guard";
import { CanActivateViaAdminAuthGuard } from "./can-activate-via-admin-auth.guard";
import { RouterModule } from "@angular/router";
import { ResetRequestComponent } from "./components/reset-request/reset-request.component";
import { AuthService } from "./services/auth.service";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { LoginComponent } from "./components/login/login.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { DisabledComponent } from "./components/disabled/disabled.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { RequestAccountModalComponent } from "./components/request-account-modal/request-account-modal.component";

export const prodOptions = {
  production: true,
  whitelist: ["api.ppfsucculents.com:8080"],
  version: "1.0.0",
};

export const devOptions = {
  production: false,
  whitelist: ["api.ppfsdev.com:8080"],
  version: "1.0.0",
};

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem("id_token");
    },
    whitelistedDomains: environment.production
      ? prodOptions.whitelist
      : devOptions.whitelist,
  };
}

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
  ],
  declarations: [
    LoginComponent,
    UnauthorizedComponent,
    DisabledComponent,
    ResetPasswordComponent,
    ResetRequestComponent,
    RequestAccountModalComponent,
  ],
  providers: [
    AuthService,
    CanActivateViaAdminAuthGuard,
    CanActivateViaCustomerAuthGuard,
    CanActivateViaAdminOrCustomerAuthGuard,
  ],
  exports: [
    LoginComponent,
    UnauthorizedComponent,
    DisabledComponent,
    ResetPasswordComponent,
    ResetRequestComponent,
  ],
  entryComponents: [RequestAccountModalComponent],
})
export class AuthModule {}
