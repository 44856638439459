
import { ValidatorFn, AbstractControl, NG_VALIDATORS, Validator, FormGroup, ValidationErrors } from "@angular/forms";
import { Directive } from "@angular/core";

/** Either Street Address 1 or PO Box is provided for New Billing Addresses */
export const streetOrPOBoxRequiredValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let street1 = control.get('street1');
    let poBox = control.get('poBox');

    let isStreetBlank = street1 ? (street1.value ? false : true ) : false;
    let isPOBoxBlank = poBox ? (poBox.value ? false : true ) : false;

    return (isStreetBlank && isPOBoxBlank) ? {'streetOrPOBoxRequired': true} : null;
};

@Directive({
    selector: '[streetOrPOBoxRequired]',
    providers: [{ provide: NG_VALIDATORS, useExisting: StreetOrPOBoxRequiredValidatorDirective, multi: true }]
})
export class StreetOrPOBoxRequiredValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } | null {
        return streetOrPOBoxRequiredValidator(control);
    }
}
