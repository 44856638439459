<div class="modal-header">
  <h4 class="modal-title pull-left">{{getModalHeader()}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">

      <div class="row">
          <div class="col-12">
              <h5>When would you like this order to be shipped?</h5>
          </div>
          <div class="col-4 form-group">
            <ngb-datepicker #d id="shipDate" name="shipDate" 
              [markDisabled]="isDisabledDate" 
              [(ngModel)]="dto.shipDate"></ngb-datepicker>
          </div>
        </div>

    <div class="modal-button-group form-group">
        <button *ngIf="modalAction === 'Edit'" class="btn btn-primary" (click)="update(form.value)" [disabled]="!form.valid">Update</button>
        <button class="btn btn-default float-right" (click)="closeModal()">Cancel</button>
      </div>
  </form>
</div>