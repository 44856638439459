<div class="modal-header">
  <h4 class="modal-title pull-left">{{ getModalHeader() }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <!-- Select Action -->
    <ng-container *ngIf="activeStep == 'selectAction'">
      <div class="row">
        <div class="col-12">
          <h4>Do you want to?</h4>
        </div>
        <div *ngIf="address.id" class="col-4">
          <button class="btn btn-primary" (click)="moveToNextStep('modify')">
            Modify Existing
          </button>
        </div>
        <div *ngIf="addressOptions.length > 0" class="col-4">
          <button class="btn btn-primary" (click)="moveToNextStep('pick')">
            Pick From List
          </button>
        </div>
        <div class="col-4">
          <button class="btn btn-primary" (click)="moveToNextStep('create')">
            Create New
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Enter Info -->
    <ng-container *ngIf="activeStep == 'enterInfo'">
      <!-- Use an Existing Address -->
      <div class="row" *ngIf="addressOptions.length > 0 && action == 'pick'">
        <div class="col-12">
          <h5>Shipping Address</h5>
        </div>
        <div class="col-12 form-group">
          <label for="addressId" class="sr-only">Select Address</label>
          <select
            class="form-control"
            selectionRequired
            name="addressId"
            id="addressId"
            [ngModel]="-1"
          >
            <option value="-1">Select Address</option>
            <option
              *ngFor="let address of addressOptions"
              [ngValue]="address.id"
              >{{ address.street1 }} {{ address.street2 }} {{ address.poBox }},
              {{ address.city }}, {{ address.state }} {{ address.zip }}</option
            >
          </select>
        </div>
      </div>

      <!-- Create a New Address -->
      <div
        class="row"
        *ngIf="action == 'create' || action == 'modify'"
        ngModelGroup="addressGroup"
        #addressGroup="ngModelGroup"
      >
        <div class="col-12">
          <h5 *ngIf="action == 'create'">Create Address</h5>
          <h5 *ngIf="action == 'modify'">Modify Address</h5>
        </div>
        <input
          *ngIf="action == 'modify'"
          name="addressId"
          id="addressId"
          [ngModel]="address.id"
          type="text"
          hidden
        />
        <div *ngIf="info.addressType === 'shipping'" class="col-12 form-group">
          <label for="companyName">Ship-to Company Name</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="Enter ship-to Company Name"
            id="companyName"
            name="companyName"
            [ngModel]="action == 'create' ? '' : address.companyName"
            #companyName="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="companyName.touched && !companyName.valid"
          >
            <div *ngIf="companyName.errors.required">
              Company Name Required.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="street1">Enter Street Adress</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="Street Address"
            id="street1"
            name="street1"
            [ngModel]="action == 'create' ? '' : address.street1"
            #street1="ngModel"
          />
          <div class="form-alert" *ngIf="street1.touched && !street1.valid">
            <div *ngIf="street1.errors.required">
              Street Address Required.
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <label for="street2" class="sr-only">Enter Street 2</label>
          <input
            type="text"
            class="form-control"
            placeholder="Steet Address 2"
            id="street2"
            name="street2"
            [ngModel]="action == 'create' ? '' : address.street2"
          />
        </div>
        <div *ngIf="info.addressType == 'billing'" class="col-12 form-group">
          <label for="poBox" class="sr-only">Enter PO Box</label>
          <input
            type="text"
            class="form-control"
            placeholder="PO Box"
            id="poBox"
            name="poBox"
            [ngModel]="action == 'create' ? '' : address.poBox"
          />
        </div>
        <div class="col-12 form-group">
          <label for="city">City</label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="City"
            id="city"
            name="city"
            [ngModel]="action == 'create' ? '' : address.city"
            #city="ngModel"
          />
          <div class="form-alert" *ngIf="city.touched && !city.valid">
            <div *ngIf="city.errors.required">
              City is required.
            </div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="state">State</label>
          <select
            class="form-control"
            selectionRequired
            name="state"
            id="state"
            [ngModel]="action == 'create' ? '-1' : address.state"
            #state="ngModel"
          >
            <option value="-1">Select State</option>
            <option *ngFor="let state of stateOptions" [ngValue]="state">{{
              state
            }}</option>
          </select>
          <div class="form-alert" *ngIf="state.touched && !state.valid">
            <div *ngIf="state.errors.selectionRequired">
              State is required.
            </div>
          </div>
        </div>
        <div class="col-6 form-group">
          <label for="zip">Zip Code</label>
          <input
            required
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            type="text"
            class="form-control"
            placeholder="Zip Code"
            id="zip"
            name="zip"
            [ngModel]="action == 'create' ? '' : address.zip"
            #zip="ngModel"
          />
          <div class="form-alert" *ngIf="zip.touched && !zip.valid">
            <div *ngIf="zip.errors.required">
              Zip Code is required.
            </div>
            <div *ngIf="zip.errors.pattern">
              Please enter a valid zip code in the 12345 or the 12345-6789
              format.
            </div>
          </div>
        </div>
        <div *ngIf="info.addressType == 'shipping'" class="col-6 form-group">
          <label for="shippingRate">Shipping Rate (%)</label>
          <input
            required
            isBetween="0:100"
            type="number"
            class="form-control"
            placeholder="Shipping Rate %"
            id="shippingRate"
            name="shippingRate"
            [ngModel]="action == 'create' ? '' : address.shippingRate"
            #shippingRate="ngModel"
          />
          <div
            class="form-alert"
            *ngIf="shippingRate.touched && !shippingRate.valid"
          >
            <div *ngIf="shippingRate.errors.required">
              Shipping rate is required.
            </div>
            <div *ngIf="shippingRate.errors.isBetween">
              Shipping rate should be a number between
              {{ shippingRate.errors.isBetween.minAllowed }} and
              {{ shippingRate.errors.isBetween.maxAllowed }}.
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Buttons -->
    <div class="modal-footer modal-button-group form-group">
      <button
        *ngIf="activeStep == 'enterInfo'"
        type="button"
        class="btn btn-primary"
        (click)="submitChanges(form.value)"
        [disabled]="!form.valid"
      >
        Update
      </button>

      <button
        *ngIf="activeStep == 'enterInfo'"
        type="button"
        class="btn btn-default"
        (click)="moveToPriorStep(form)"
      >
        Back
      </button>
    </div>
  </form>
</div>
