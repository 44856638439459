import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailService } from "src/app/shared/services/email.service";

@Component({
  selector: "app-send-confirmation-modal",
  templateUrl: "./send-confirmation-modal.component.html",
  styleUrls: ["./send-confirmation-modal.component.css"],
})
export class SendConfirmationModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _emailService: EmailService
  ) {}

  @Input() orderId: string;
  @Output() sendEmail = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return "Email Order Confirmation";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public sendConfirmation(data) {
    this._emailService.sendOrderConfirmation(data).subscribe(
      (res: any) => {
        let sendConfirmationResponse = {
          type: "success",
          to: res.to,
          alert: {
            type: res.status,
            title: "Confirmation Sent",
            msg: res.message,
          },
        };
        this.activeModal.close();
        this.sendEmail.emit(sendConfirmationResponse);
      },
      (error) => {
        let sendConfirmationResponse = {
          type: "error",
          to: data.email,
          alert: {
            type: "danger",
            title: "Confirmation Not Sent",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.sendEmail.emit(sendConfirmationResponse);
      }
    );
  }
}
