import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HalfShelfService } from "./../shared/services/halfshelf.service";
import { IHalfShelf } from "./../shared/interfaces/halfshelf.interface";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { HalfShelfModalComponent } from "../modals/halfshelf-modal/halfshelf-modal.component";

@Component({
  selector: "app-halfshelves",
  templateUrl: "./halfshelves.component.html",
  styleUrls: ["./halfshelves.component.css"],
})
export class HalfShelvesComponent implements OnInit {
  constructor(
    private _halfShelfService: HalfShelfService,
    private _modalService: NgbModal
  ) {}

  public halfShelves: Array<IHalfShelf>;
  public matchingHalfShelves: Array<IHalfShelf>;
  public halfShelvesShowing: Array<IHalfShelf>;
  public alert: any;

  //Pagination Vars
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 10;
  public totalItems: number;

  //Filter Vars
  public filters = {
    name: "",
    description: "",
    upc: "",
    itemNo: "",
    display: "",
    adminOnly: "",
    notes: "",
  };

  //Icons
  public downloadIcon = faFileDownload;

  ngOnInit() {
    this._halfShelfService.getAll().subscribe(
      (halfShelves: Array<IHalfShelf>) => {
        this.halfShelves = halfShelves;
        this.matchingHalfShelves = this.halfShelves;
        this.halfShelvesShowing = this.halfShelves.slice(0, this.itemsPerPage);
        this.totalItems = halfShelves.length;
      },
      (error) => {
        console.log("There was an error getting halfshelves.");
      }
    );
  }
  public clearToastAlert() {
    this.alert = null;
  }

  public pageChanged(event: any): void {
    this.halfShelvesShowing = this.matchingHalfShelves.slice(
      (event - 1) * this.itemsPerPage,
      event * this.itemsPerPage
    );
  }

  public startAdd() {
    const modalRef = this._modalService.open(HalfShelfModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.createHalfShelf.subscribe((event) => {
      this.onCreateHalfShelf(event);
    });
  }

  public startUpdate(halfShelf) {
    const modalRef = this._modalService.open(HalfShelfModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.halfShelf = halfShelf;
    modalRef.componentInstance.deleteHalfShelf.subscribe((event) => {
      this.onDeleteHalfShelf(event);
    });
    modalRef.componentInstance.updateHalfShelf.subscribe((event) => {
      this.onUpdateHalfShelf(event);
    });
  }

  public onCreateHalfShelf(e) {
    if (e.type === "success") {
      this.halfShelves.push(e.halfShelf);
      this.alert = e.alert;
      this.halfShelvesShowing = this.halfShelves.slice(0, this.itemsPerPage);
      this.totalItems = this.halfShelves.length;
    } else {
      this.alert = e.alert;
    }
  }

  public onDeleteHalfShelf(e) {
    if (e.type === "success") {
      let index = this.halfShelves.findIndex(
        (halfShelf) => halfShelf.id == e.halfShelf.id
      );
      this.halfShelves.splice(index, 1);
      this.halfShelvesShowing = this.halfShelves.slice(0, this.itemsPerPage);
      this.totalItems = this.halfShelves.length;
      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public onUpdateHalfShelf(e) {
    if (e.type === "success") {
      let updatedHalfShelfIndex = this.halfShelves.findIndex(
        (halfShelf) => halfShelf.id === e.halfShelf.id
      );

      this.halfShelves[updatedHalfShelfIndex] = e.halfShelf;

      let updatedHalfShelfShowingIndex = this.halfShelvesShowing.findIndex(
        (halfShelf) => halfShelf.id === e.halfShelf.id
      );
      this.halfShelvesShowing[updatedHalfShelfShowingIndex] =
        this.halfShelves[updatedHalfShelfIndex];

      this.alert = e.alert;
    } else {
      this.alert = e.alert;
    }
  }

  public changeFilter() {
    this.matchingHalfShelves = this.halfShelves.filter(
      (halfShelf) =>
        this.applyHalfShelfNameFilter(halfShelf) &&
        this.applyHalfShelfDescriptionFilter(halfShelf) &&
        this.applyHalfShelfUPCFilter(halfShelf) &&
        this.applyHalfShelfItemNoFilter(halfShelf) &&
        (this.filters.display
          ? (halfShelf.display ? "Y" : "N") ==
            this.filters.display.toUpperCase()
          : true) &&
        (this.filters.adminOnly
          ? (halfShelf.adminOnly ? "Y" : "N") ==
            this.filters.adminOnly.toUpperCase()
          : true)
    );

    this.halfShelvesShowing = this.matchingHalfShelves.slice(
      0,
      this.itemsPerPage
    );
    this.totalItems = this.matchingHalfShelves.length;
  }

  private applyHalfShelfNameFilter(halfShelf) {
    if (this.filters.name) {
      if (halfShelf.name) {
        return halfShelf.name
          .toUpperCase()
          .includes(this.filters.name.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyHalfShelfDescriptionFilter(halfShelf) {
    if (this.filters.description) {
      if (halfShelf.description) {
        return halfShelf.description
          .toUpperCase()
          .includes(this.filters.description.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyHalfShelfUPCFilter(halfShelf) {
    if (this.filters.upc) {
      if (halfShelf.upc) {
        return halfShelf.upc
          .toUpperCase()
          .includes(this.filters.upc.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private applyHalfShelfItemNoFilter(halfShelf) {
    if (this.filters.itemNo) {
      if (halfShelf.itemNo) {
        return halfShelf.itemNo
          .toUpperCase()
          .includes(this.filters.itemNo.toUpperCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public clearFilters() {
    this.filters.name = "";
    this.filters.description = "";
    this.filters.upc = "";
    this.filters.itemNo = "";
    this.filters.display = "";
    this.filters.adminOnly = "";
  }

  public exportToCSV() {
    let csv = "";

    if (this.matchingHalfShelves.length > 0) {
      let headers =
        "id,upc,itemNo,name,description,costEach,itemQty,dispaly,adminOnly";
      csv += headers + "\n";
      for (let i = 0; i < this.matchingHalfShelves.length; i++) {
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].id
            ? ""
            : this.matchingHalfShelves[i].id) +
          '",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].upc
            ? ""
            : this.matchingHalfShelves[i].upc) +
          '",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].itemNo
            ? ""
            : this.matchingHalfShelves[i].itemNo) +
          '",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].name
            ? ""
            : this.matchingHalfShelves[i].name.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].description
            ? ""
            : this.matchingHalfShelves[i].description.replace(/"/g, '""')) +
          ' ",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].eachCost
            ? ""
            : this.matchingHalfShelves[i].eachCost) +
          '",';
        csv +=
          '"' +
          (!this.matchingHalfShelves[i].itemQty
            ? ""
            : this.matchingHalfShelves[i].itemQty) +
          '",';
        csv += '"' + (this.matchingHalfShelves[i].display ? "Y" : "N") + '",';
        csv +=
          '"' + (this.matchingHalfShelves[i].adminOnly ? "Y" : "N") + '"\n';
      }
    }

    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let fileName = "halfshelves.csv";

    //@ts-ignore
    if (navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
