import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { IRack } from "src/app/shared/interfaces/rack.interface";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RackService } from "./../../shared/services/rack.service";
import {
  ImageService,
  ImageTypes,
} from "src/app/shared/services/image.service";
import { NotFoundError } from "src/app/shared/errors/not-found-error";
import { FormBuilder, FormArray, Validators, FormGroup } from "@angular/forms";
import { ProductService } from "./../../shared/services/product.service";
import { IProduct } from "src/app/shared/interfaces/product.interface";

@Component({
  selector: "app-rack-modal",
  templateUrl: "./rack-modal.component.html",
  styleUrls: ["./rack-modal.component.css"],
})
export class RackModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private _modalService: NgbModal,
    private _rackService: RackService,
    private _imageService: ImageService,
    private _fb: FormBuilder
  ) {}

  @Input() rack: IRack;
  @Output() createRack = new EventEmitter<any>();
  @Output() deleteRack = new EventEmitter<any>();
  @Output() updateRack = new EventEmitter<any>();

  public modalAction: ModalActions;
  public rackImagesToUpload: Array<File> = null;
  public imageChanged: boolean = false;
  public productOptions: Array<IProduct>;
  public isLoading: boolean;

  //Forms
  public rackForm = this._fb.group({
    name: ["", Validators.required],
    upc: ["", Validators.required],
    itemNo: ["", Validators.required],
    display: [true],
    adminOnly: [false],
    products: this._fb.array([]),
  });

  ngOnInit() {
    this.isLoading = false;
    //Determine Purpose of Launching this Modal
    if (this.rack) {
      this.modalAction = ModalActions.Edit;
      //Set Initial Values
      this.rackForm.get("name").setValue(this.rack.name);
      this.rackForm.get("upc").setValue(this.rack.upc);
      this.rackForm.get("itemNo").setValue(this.rack.itemNo);
      this.rackForm.get("display").setValue(this.rack.display);
      this.rackForm.get("adminOnly").setValue(this.rack.adminOnly);

      this.rack.Products.forEach((product) => {
        let form = this.productForm;
        form.get("productId").setValue(product.id);
        form.get("qty").setValue(product.RackProduct.qty);
        form.get("eachCost").setValue(product.RackProduct.eachCost);
        this.products.push(form);
      });

      //Get image
      if (this.rack.RackImages) {
        if (this.rack.RackImages.length > 0) {
          this._imageService
            .getImage(ImageTypes.rack, this.rack.RackImages[0].fileName)
            .subscribe(
              (url) => {
                this.rack.RackImages[0].imageUrl = url;
              },
              (error) => {
                console.log("error getting rack image.");
              }
            );
        }
      }
    } else {
      this.modalAction = ModalActions.Create;
      this.rack = <IRack>{};
      this.rack.display = true;
      this.addProduct();
    }

    //Get products we will need for our dropdown
    this.productService.getAll().subscribe(
      (products: Array<IProduct>) => {
        this.productOptions = products;
      },
      (error) => {
        console.log("There was an error getting products.");
      }
    );
  }

  get productForm(): FormGroup {
    return this._fb.group({
      productId: ["", Validators.required],
      qty: [1, Validators.required],
      eachCost: ["", [Validators.required, Validators.min(0)]],
    });
  }

  get products(): FormArray {
    return this.rackForm.get("products") as FormArray;
  }

  public getRackCost() {
    return this.products.value
      .map((product) => product.qty * product.eachCost)
      .reduce((result, cost) => result + cost);
  }

  public addProduct(): void {
    this.products.push(this.productForm);
  }

  public removeProduct(index: number): void {
    this.products.removeAt(index);
  }

  public changeProduct(e) {
    const rackProductsIndex = e.target.id.split("-")[1];
    const selectedProductId = e.target.value;
    const productOpt = this.productOptions.find(
      (p) => p.id === selectedProductId
    );

    this.products.controls[rackProductsIndex].patchValue({
      productId: selectedProductId,
      eachCost: productOpt.eachCost,
    });
  }

  public getModalHeader() {
    if (this.modalAction === ModalActions.Create) {
      return "Create New Rack";
    } else if (this.modalAction === ModalActions.Edit) {
      return "Edit Rack";
    } else {
      return "Rack Form";
    }
  }

  public updateRackImage(files: FileList) {
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      if (!this._imageService.isAcceptedFileType(files.item(i))) {
        hasError = true;
        break;
      }
    }

    if (hasError)
      return console.log(
        "The file type selected is not a valid file type. Only png, jpg or jpeg types allowed."
      );

    this.imageChanged = true;
    delete this.rack.RackImages;
    this.rackImagesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.rackImagesToUpload.push(files.item(i));
    }
  }

  public add() {
    this.isLoading = true;
    this._rackService.create(this.rackForm.value).subscribe(
      (createRes: any) => {
        if (this.imageChanged) {
          this._imageService
            .uploadImages(
              ImageTypes.rack,
              createRes.rack.id,
              this.rackImagesToUpload
            )
            .subscribe(
              (uploadImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                uploadImageRes.filesData.forEach((fileData) => {
                  createRes.rack.RackImages.push({
                    rackId: uploadImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let rackCreateResponse = {
                  type: "success",
                  rack: createRes.rack,
                  image: uploadImageRes.image,
                  alert: {
                    type: createRes.status,
                    title: "Rack Created",
                    msg: createRes.message,
                  },
                };
                this.activeModal.close();
                this.createRack.emit(rackCreateResponse);
              },
              (error) => {
                this.isLoading = false;

                let rackCreateResponse = {
                  type: "success",
                  rack: createRes.rack,
                  alert: {
                    type: createRes.status,
                    title: "Rack Created",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.createRack.emit(rackCreateResponse);
              }
            );
        } else {
          this.isLoading = false;
          let rackCreateResponse = {
            type: "success",
            rack: createRes.rack,
            alert: {
              type: createRes.status,
              title: "Rack Added",
              msg: createRes.message,
            },
          };
          this.activeModal.close();
          this.createRack.emit(rackCreateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let rackCreateResponse = {
          type: "error",
          rack: this.rack,
          alert: {
            type: "danger",
            title: "Rack Not Created",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.createRack.emit(rackCreateResponse);
      }
    );
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: string) {
    this.activeModal.close();

    this._rackService.delete(id).subscribe(
      (res: any) => {
        let deleteRackResponse = {
          type: "success",
          rack: this.rack,
          alert: {
            type: "success",
            title: "Rack deleted.",
            msg: res.message,
          },
        };
        this.deleteRack.emit(deleteRackResponse);
      },
      (error) => {
        if (error instanceof NotFoundError) {
          let deleteRackResponse = {
            type: "success",
            rack: this.rack,
            alert: {
              type: "success",
              title: "Rack deleted.",
              msg: "Rack " + this.rack.description + " has been deleted.",
            },
          };
          this.deleteRack.emit(deleteRackResponse);
        } else {
          let deleteRackResponse = {
            type: "error",
            rack: this.rack,
            alert: {
              type: "danger",
              title: "Rack was not deleted.",
              msg: error.originalError.error.errors[0].description,
            },
          };
          this.deleteRack.emit(deleteRackResponse);
        }
      }
    );
  }

  public update() {
    this.isLoading = true;
    this.rackForm.value.id = this.rack.id;

    this._rackService.update(this.rackForm.value).subscribe(
      (updateRackRes: any) => {
        if (this.imageChanged) {
          updateRackRes.rack.RackImages = [];
          this._imageService
            .uploadImages(
              ImageTypes.rack,
              this.rack.id,
              this.rackImagesToUpload,
              true
            )
            .subscribe(
              (updateImageRes: any) => {
                this.isLoading = false;

                //Set the images to the new ones.
                updateImageRes.filesData.forEach((fileData) => {
                  updateRackRes.rack.RackImages.push({
                    rackId: updateImageRes.parentId,
                    fileName: fileData.newName,
                  });
                });

                let rackUpdateResponse = {
                  type: "success",
                  rack: updateRackRes.rack,
                  alert: {
                    type: updateRackRes.status,
                    title: "Rack Updated",
                    msg: updateRackRes.message,
                  },
                };
                this.activeModal.close();
                this.updateRack.emit(rackUpdateResponse);
              },
              (error) => {
                this.isLoading = false;

                let rackUpdateResponse = {
                  type: "success",
                  rack: updateRackRes.rack,
                  alert: {
                    type: updateRackRes.status,
                    title: "Rack Updated",
                    msg: "But there was an error saving its image. Please try to load it again.",
                  },
                };
                this.activeModal.close();
                this.updateRack.emit(rackUpdateResponse);
              }
            );
        } else {
          this.isLoading = false;

          let rackUpdateResponse = {
            type: "success",
            rack: updateRackRes.rack,
            alert: {
              type: updateRackRes.status,
              title: "Rack Updated",
              msg: updateRackRes.message,
            },
          };
          this.activeModal.close();
          this.updateRack.emit(rackUpdateResponse);
        }
      },
      (error) => {
        this.isLoading = false;
        let rackUpdateResponse = {
          type: "error",
          rack: this.rack,
          alert: {
            type: "danger",
            title: "Rack Not Updated",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.updateRack.emit(rackUpdateResponse);
      }
    );
  }

  public closeModal() {
    this.activeModal.close();
  }
}

enum ModalActions {
  Edit = "Edit",
  Create = "Create",
}
