import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailService } from "src/app/shared/services/email.service";

@Component({
  selector: "app-send-invoice-modal",
  templateUrl: "./send-invoice-modal.component.html",
  styleUrls: ["./send-invoice-modal.component.css"],
})
export class SendInvoiceModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _emailService: EmailService
  ) {}

  @Input() orderId: string;
  @Output() sendEmail = new EventEmitter<any>();

  ngOnInit() {}

  public getModalHeader() {
    return "Email Invoice";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public sendInvoice(data) {
    this._emailService.sendInvoice(data).subscribe(
      (res: any) => {
        console.log(res);
        let sendInvoiceResponse = {
          type: "success",
          to: res.to,
          alert: {
            type: res.status,
            title: "Invoice Sent",
            msg: res.message,
          },
        };
        this.activeModal.close();
        this.sendEmail.emit(sendInvoiceResponse);
      },
      (error) => {
        let sendInvoiceResponse = {
          type: "error",
          to: data.email,
          alert: {
            type: "danger",
            title: "Invoice Not Sent",
            msg: error.originalError.error.errors[0].description,
          },
        };
        this.activeModal.close();
        this.sendEmail.emit(sendInvoiceResponse);
      }
    );
  }
}
