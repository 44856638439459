import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CartService } from "./../services/cart.service";
import { ICart } from "../interfaces/cart.interface";
import {
  MIN_NUM_BOXES,
  MIN_ORDER_AMT,
  SHELVES_PER_RACK,
} from "../data/constants";

@Component({
  selector: "app-order-requirements",
  templateUrl: "./order-requirements.component.html",
  styleUrls: ["./order-requirements.component.css"],
})
export class OrderRequirementsComponent implements OnChanges {
  @Input() cart: ICart;
  @Input() changeCount;
  @Output() orderValidityChange = new EventEmitter<any>();
  public checkIcon = faCheck;
  public xIcon = faTimes;

  public isValid;
  public minOrderAmt = MIN_ORDER_AMT;
  public minNumBoxes = MIN_NUM_BOXES;
  public halfShelvesPerRack = SHELVES_PER_RACK * 2;

  constructor(private _cartService: CartService) {}

  ngOnChanges() {
    this._cartService.setCartComputedFields(this.cart);
    let newIsValidValue = this.isValidOrder();

    if (this.isValid != newIsValidValue) {
      this.isValid = newIsValidValue;
      this.orderValidityChange.emit({ valid: this.isValid });
    }
  }

  public isValidOrder() {
    let isValid = true;
    //Min # Boxes Check
    if (
      this.cart.totalNumOfBoxesFromRegularAndPrebuilt > 0 &&
      this.cart.totalNumOfBoxesFromRegularAndPrebuilt < this.minNumBoxes
    ) {
      isValid = false;
    }

    //Min order amt check
    if (this.cart.productTotal < this.minOrderAmt) {
      isValid = false;
    }

    //Racks Full
    if (this.cart.rackQtyTotal % 1 > 0) {
      isValid = false;
    }

    return isValid;
  }
}
