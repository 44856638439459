import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddressService } from "src/app/shared/services/address.service";
import { IAddress } from "src/app/shared/interfaces/address.interface";
import { NotFoundError } from "src/app/shared/errors/not-found-error";
import { STATES } from "src/app/shared/data/states";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-address-modal",
  templateUrl: "./address-modal.component.html",
  styleUrls: ["./address-modal.component.css"]
})
export class AddressModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _addressService: AddressService
  ) {}

  @Input() address: IAddress;
  @Output() deleteAddress = new EventEmitter<any>();
  @Output() updateAddress = new EventEmitter<any>();

  public modalAction: ModalActions;
  public stateOptions: Array<string> = [];
  public infoIcon = faInfoCircle;
  public helpInfoTitle = "Changing Addresses";
  public helpInfoMsg = `Updating an address here will update the address options on future orders, but it will not impact existing orders. 
  If you need to update the address of an existing order, view it's order confirmation.`;

  ngOnInit() {
    // We only allow in this modal
    this.modalAction = ModalActions.Edit;

    for (let key in STATES) {
      this.stateOptions.push(key);
    }
  }

  public getModalHeader() {
    return "Edit Address";
  }

  public closeModal() {
    this.activeModal.close();
  }

  public confirmDelete(template: TemplateRef<any>, event: any) {
    this.activeModal.dismiss();
    this.activeModal = this._modalService.open(template);
  }

  public delete(id: number) {
    this.activeModal.close();

    this._addressService.delete(id).subscribe(
      (res: any) => {
        let deleteAddressResponse = {
          type: "success",
          address: this.address,
          alert: {
            type: "success",
            title: "Address deleted.",
            msg: res.message
          }
        };
        this.deleteAddress.emit(deleteAddressResponse);
      },
      error => {
        if (error instanceof NotFoundError) {
          let deleteAddressResponse = {
            type: "success",
            address: this.address,
            alert: {
              type: "success",
              title: "Address deleted.",
              msg: "Address has been deleted."
            }
          };
          this.deleteAddress.emit(deleteAddressResponse);
        } else {
          let deleteAddressResponse = {
            type: "error",
            address: this.address,
            alert: {
              type: "danger",
              title: "Address was not deleted.",
              msg: error.originalError.errors[0].description
            }
          };
          this.deleteAddress.emit(deleteAddressResponse);
        }
      }
    );
  }

  public update(data) {
    data.id = this.address.id;

    this._addressService.update(data).subscribe(
      (res: any) => {
        let addressUpdateResponse = {
          type: "success",
          address: res.address,
          alert: {
            type: res.status,
            title: "Address Updated",
            msg: res.message
          }
        };

        for (let key in data) {
          this.address[key] = data[key];
        }

        this.activeModal.close();
        this.updateAddress.emit(addressUpdateResponse);
      },
      error => {
        let addressUpdateResponse = {
          type: "error",
          address: this.address,
          alert: {
            type: "danger",
            title: "Address Not Updated",
            msg: error.originalError.error.errors[0].description
          }
        };
        this.activeModal.close();
        this.updateAddress.emit(addressUpdateResponse);
      }
    );
  }
}

enum ModalActions {
  Edit = "Edit"
}
